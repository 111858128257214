import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import EditPostForm from '../../components/EditPostForm/EditPostForm';
import NotFound from '../../components/NotFound/NotFound';
import withPostsState, { PostsProps } from '../../enhancers/withPostsState';
import BackScreen from '../../layouts/BackScreen/BackScreen';
import CenterForm from '../../layouts/CenterForm/CenterForm';

type Props = RouteComponentProps<{ id: string; groupId: string }> & PostsProps;

const EditPost: React.FC<Props> = props => {
  const getContent = () => {
    const { posts } = props.posts;
    if (!posts.posts || !posts.loaded) {
      return <CircularProgress />;
    }
    const post = posts.posts.find(post => post.id === props.match.params.id);
    if (!post) {
      return <NotFound />;
    }

    return <EditPostForm groupId={props.match.params.groupId} post={post} />;
  };
  return (
    <BackScreen title="Edit Post">
      <CenterForm>{getContent()}</CenterForm>
    </BackScreen>
  );
};

export default withPostsState(EditPost);
