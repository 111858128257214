import { connect } from 'react-redux';

import { IWindowState } from '../store/modules/window/reducer';
import { RootState } from '../types/store';

const mapStateToProps = (state: RootState) => ({
  window: state.window
});

interface IWithWindowState {
  window: IWindowState;
}
export type WindowProps = IWithWindowState;

const withWindowState = connect(
  mapStateToProps,
  null
);

export default withWindowState;
