import { InjectedFormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { AuthProps } from '../../enhancers/withAuthState';
import { RouteComponentProps } from 'react-router';

type FormValues = {
  email: string;
  password: string;
};

type FormProps = AuthProps & RouteComponentProps;

export type EmailLoginFormProps = InjectedFormikProps<FormProps, FormValues> &
  Partial<FormValues>;

const initialValues = {
  email: '',
  password: ''
};

export default withFormik<FormProps, FormValues>({
  handleSubmit: (values, { props, setSubmitting, setError, resetForm }) => {
    setSubmitting(true);
    try {
      props.emailLogin({ email: values.email, password: values.password });
    } catch (e) {
      console.log({ e });
    }
  },
  mapPropsToValues: () => ({ ...initialValues }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    password: Yup.string()
      .min(6)
      .required()
  })
});
