import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { isAfter, isBefore } from 'date-fns';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import noPolls from '../../assets/img/polls-blank.png';
import withPollsState, { PollsProps } from '../../enhancers/withPollsState';
import { IPoll, PollDateFilter } from '../../types/poll';
import EmptyState from '../EmptyState/EmptyState';
import Poll from './Poll';

interface IProps {
  groupId: string;
  scrollRef: React.RefObject<HTMLDivElement>;
  count: number;
  incrementCount: () => void;
  filterText?: string;
  filter: PollDateFilter;
}

const styles = (theme: Theme) =>
  createStyles({
    subheader: {
      fontSize: '1rem'
    }
  });

type Props = PollsProps & IProps & WithStyles<typeof styles>;

const PollsList: React.FC<Props> = props => {
  const { count, incrementCount } = props;
  const polls = props.polls.polls;
  if (!polls.loaded || !polls.polls) {
    return (
      <Centerer>
        <CircularProgress />
      </Centerer>
    );
  }
  type PollFilter = (poll: IPoll) => boolean;
  const filters: PollFilter[] = [];
  filters.push(poll => poll.groupId === props.groupId);
  if (props.filterText) {
    filters.push(poll =>
      poll.title.toLowerCase().includes(props.filterText!.toLowerCase())
    );
  }
  const now = new Date();
  const parsePollDate = (poll: IPoll) => {
    const start = new Date(poll.startDate * 1000);
    const end = new Date(poll.endDate * 1000);
    return { start, end };
  };

  const activeFilter = (poll: IPoll) => {
    const { start, end } = parsePollDate(poll);
    return isAfter(end, now) && isBefore(start, now);
  };

  const scheduledFilter = (poll: IPoll) => {
    const { start, end } = parsePollDate(poll);
    return isAfter(end, now) && isAfter(start, now);
  };

  const pastFilter = (poll: IPoll) => {
    const { start, end } = parsePollDate(poll);
    return isBefore(end, now) && isBefore(start, now);
  };

  switch (props.filter) {
    case 'active': {
      filters.push(activeFilter);
      break;
    }
    case 'scheduled': {
      filters.push(scheduledFilter);
      break;
    }
    case 'past': {
      filters.push(pastFilter);
      break;
    }
  }

  const filteredPolls = filters.reduce<IPoll[]>((acc, curr) => {
    return acc.filter(curr);
  }, polls.polls);

  const slicedPolls = filteredPolls.slice(0, count);
  return (
    <React.Fragment>
      {filteredPolls.length > 0 ? (
        <List
          subheader={
            <ListSubheader
              classes={{ root: props.classes.subheader }}
              style={{ position: 'static' }}
              component="div"
            >
              Polls
            </ListSubheader>
          }
        >
          <Container>
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={incrementCount}
              hasMore={true}
              getScrollParent={() => props.scrollRef.current || null}
              useWindow={false}
            >
              {slicedPolls.map((poll, i) => (
                <Poll
                  key={poll.id + '' + i}
                  poll={poll}
                  groupId={props.groupId}
                />
              ))}
            </InfiniteScroll>
          </Container>
        </List>
      ) : (
        <EmptyState
          image={noPolls}
          text={
            <React.Fragment>
              There are no new polls.
              <br />
              Add one below.
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  );
};

const Container = styled.div``;

const Centerer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withStyles(styles)(withPollsState(PollsList));
