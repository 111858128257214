import {
  AppBar,
  createStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import MaterialDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import DomainIcon from '@material-ui/icons/Domain';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import PublicIcon from '@material-ui/icons/Public';
import * as React from 'react';
import styled from 'styled-components';

import AuthGuard from '../../containers/AuthGuard/AuthGuard';
import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withBrandState, { BrandProps } from '../../enhancers/withBrandState';
import withDrawerState, { DrawerProps } from '../../enhancers/withDrawerState';
import { AuthTier } from '../../types/auth';
import { DrawerItem } from '../DrawerItem/DrawerItem';

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    list: {
      backgroundColor: palette.background.paper,
      maxWidth: 400
    },
    icon: {
      // color: palette.secondary.dark,
      opacity: 0.8
    }
  });

interface IProps extends WithStyles<typeof styles> {
  open: boolean;
}

type Props = IProps & DrawerProps & AuthProps & BrandProps;

const Drawer: React.SFC<Props> = props => {
  const getToggle = (open: boolean) => () => props.toggle(open);
  return (
    <MaterialDrawer open={props.open} onClose={getToggle(false)}>
      <div className={props.classes.list}>
        <Container color="secondary" position="static">
          <ListItem>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                !!props.auth.userData &&
                `${props.auth.userData.firstName} ${
                  props.auth.userData.lastName
                }`
              }
              secondary={
                !!props.auth.userData && props.auth.userData.isTier1 && 'Admin'
              }
            />
          </ListItem>
        </Container>

        <List component="nav">
          <AuthGuard
            allowedTiers={[AuthTier.TIER1]}
            onFail={{ action: 'hide' }}
          >
            <DrawerItem
              to="/app/admin/users"
              icon={<GroupIcon className={props.classes.icon} />}
            >
              Admins
            </DrawerItem>
          </AuthGuard>
          <AuthGuard
            allowedTiers={[AuthTier.TIER1]}
            onFail={{ action: 'hide' }}
          >
            <DrawerItem
              to={`/app/admin/groups/${
                props.brand.brand!.defaultGroupId
              }/posts`}
              icon={<PublicIcon className={props.classes.icon} />}
            >
              Party News
            </DrawerItem>
          </AuthGuard>
          <DrawerItem
            to="/app/admin/groups"
            icon={<DomainIcon className={props.classes.icon} />}
          >
            Groups
          </DrawerItem>
        </List>
      </div>
    </MaterialDrawer>
  );
};

const IconContainer = styled.div`
  opacity: 0.54;
  margin-right: 7px;
`;

const Container: any = styled(AppBar)`
  padding: 20px 0;
`;

export default withBrandState(
  withAuthState(withDrawerState(withStyles(styles)(Drawer)))
);
