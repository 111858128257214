import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Redirect } from 'react-router';

import withGroupsState from '../../enhancers/withGroupsState';
import withGroupUserInviteForm, { GroupUserInviteFormProps } from './withGroupUserInviteForm';

interface IProps {
  groupId: string;
}

type Props = GroupUserInviteFormProps & IProps;

const GroupUserInviteForm: React.FC<Props> = props => {
  if (props.status === 'submitting') {
    return <Redirect to={`/app/admin/groups/${props.groupId}/users/invites`} />;
  }
  return (
    <form onSubmit={props.handleSubmit}>
      <Typography variant="subtitle1">Member Details</Typography>
      <TextField
        label="First Name"
        value={props.values.name}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        name="name"
        fullWidth
        error={props.touched.name && !!props.errors.name}
        helperText={props.touched.name ? props.errors.name : ''}
        variant="outlined"
        margin="normal"
      />
      <TextField
        label="Email Address"
        type="email"
        value={props.values.email}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        name="email"
        fullWidth
        error={props.touched.email && !!props.errors.email}
        helperText={props.touched.email ? props.errors.email : ''}
        variant="outlined"
        margin="normal"
      />
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={props.values.isAdmin}
              onChange={props.handleChange('isAdmin')}
              value="accepted"
              color="default"
            />
          }
          label="Invite as organiser"
        />
      </div>
      <br />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={props.submitForm}
      >
        Invite
      </Button>
    </form>
  );
};

export default withGroupsState(withGroupUserInviteForm(GroupUserInviteForm));
