import { InjectedFormikProps, withFormik } from 'formik';
import { NotificationManager } from 'react-notifications';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';

import { BrandProps } from '../../enhancers/withBrandState';

type FormValues = {
  checkbox: boolean;
  email: string;
  firstname: string;
  lastname: string;
  number: string;
  test: boolean;
};

const functionsEndpoint = process.env.REACT_APP_FUNCTIONS_ENDPOINT!;

type FormProps = RouteComponentProps & BrandProps;

export type VolunteerPreFormProps = InjectedFormikProps<FormProps, FormValues> &
  Partial<FormValues>;

const initialValues: FormValues = {
  checkbox: false,
  email: '',
  firstname: '',
  lastname: '',
  number: '',
  test: false
};

export default withFormik<FormProps, FormValues>({
  handleSubmit: async (
    values,
    { props, setSubmitting, setError, resetForm }
  ) => {
    setSubmitting(true);
    try {
      const {
        checkbox,
        email,
        firstname: firstName,
        lastname: lastName,
        number: mobileNumber,
        test: newsletter,

      } = values;

      const result = await fetch(functionsEndpoint + '/volunteerSignUp', {
        method: 'POST',
        headers: {
          brand: props.brand.brand!.id,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          checkbox,
          email,
          firstName,
          lastName,
          mobileNumber: '+27' + mobileNumber,
          newsletter,
          brandId: props.brand.brand!.id
        })
      });

      switch (result.status) {
        case 200: {
          console.log('success');
          props.history.push('/app/success');
          break;
        }
        default: {
          throw new Error(result.statusText);
        }
      }
    } catch (e) {
      NotificationManager.error('Failed to submit form. Try again later');
      console.log({ e });
    }
    setSubmitting(false);
  },
  mapPropsToValues: () => ({ ...initialValues }),
  validationSchema: Yup.object().shape({
    checkbox: Yup.boolean()
      .required()
      .oneOf([true], 'You have to agree to our terms and conditions'),
    email: Yup.string().email().required('Email is required'),
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    number: Yup.string().required('Mobile Number is required')
  })
});
