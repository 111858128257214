import { InjectedFormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { AuthProps } from '../../enhancers/withAuthState';

type FormValues = {
  mobileNumber: string;
  verifiedCaptcha: boolean;
};

type FormProps = AuthProps;

export type LoginFormProps = InjectedFormikProps<FormProps, FormValues> &
  Partial<FormValues>;

const initialValues = {
  mobileNumber: '',
  verifiedCaptcha: false
};

export default withFormik<FormProps, FormValues>({
  handleSubmit: (values, { props, setSubmitting, setError, resetForm }) => {
    setSubmitting(true);
    try {
      props.login({ mobileNumber: values.mobileNumber });
    } catch (e) {
      console.log({ e });
    }
  },
  mapPropsToValues: () => ({ ...initialValues }),
  validationSchema: Yup.object().shape({
    mobileNumber: Yup.string()
      .matches(/[0-9]/i, 'Must be a number')
      .required(),
    verifiedCaptcha: Yup.boolean()
      .required()
      .oneOf([true], 'Please complete captcha')
  })
});
