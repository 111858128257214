import firebase from 'firebase/app';
import { Action } from 'redux';
import { all, put, takeLatest } from 'redux-saga/effects';

import { Collection } from '../../../constants/collections';
import { GroupRequestError } from '../../../types/groupRequests';
import * as actions from './actions';

function* handleCreateGroupRequests(
  action: Action & { payload: actions.ICreateGroupRequestPayload }
) {
  const db = firebase.firestore();
  const ref = db.collection(Collection.GROUP_REQUESTS);
  try {
    yield ref.add({
      ...action.payload.groupRequest
    });
    yield put(actions.setSubmitted({ submitted: true }));
  } catch (e) {
    yield put(
      actions.setGroupRequestError({ error: GroupRequestError.UNKOWN_ERROR })
    );
  }
}

function* watchCreateGroupRequests() {
  yield takeLatest(actions.createGroupRequest, handleCreateGroupRequests);
}

export default function*() {
  yield all([watchCreateGroupRequests()]);
}
