import { useState } from 'react';

const INITIAL_COUNT = 20;
const INCREMENT = 5;

export default (): [number, () => void, () => void] => {
  const [count, setCount] = useState<number>(INITIAL_COUNT);
  const incrementCount = () => {
    setCount(count + INCREMENT);
  };
  const resetCount = () => {
    setCount(INITIAL_COUNT);
  };
  return [count, incrementCount, resetCount];
};
