import * as React from 'react';

import ReplaceRedirect from '../../components/ReplaceRedirect/ReplaceRedirect';
import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import { AuthState, AuthTier } from '../../types/auth';

interface IDefaultProps {
  allowedStates: AuthState[];
  allowedTiers: Array<AuthTier | null>;
  onFail: { action: 'redirect'; to: string } | { action: 'hide' };
}

const defaultProps: IDefaultProps = {
  allowedStates: [AuthState.LOGGED_IN, AuthState.FETCHING_USER_DATA],
  allowedTiers: [AuthTier.TIER1, AuthTier.TIER2, null],
  onFail: { action: 'redirect', to: '/app/admin/login' }
};

type Props = AuthProps & Partial<Readonly<IDefaultProps>>;

class AuthGuard extends React.Component<Props> {
  static defaultProps = defaultProps;

  authStateValid = () => {
    const result = this.props.allowedStates!.includes(this.props.auth.state);
    return result;
  };

  authTierValid = () => {
    const tier = this.props.auth.tier;
    if (this.props.auth.state !== AuthState.LOGGED_IN) {
      return true;
    }
    const result = this.props.allowedTiers!.includes(tier);
    return result;
  };

  isValid = () => this.authTierValid() && this.authStateValid();

  render() {
    const isValid = this.isValid();
    if (!isValid) {
      const onFail = this.props.onFail!;
      console.log(onFail, this.props.allowedStates, this.props.auth.state);
      if (onFail.action === 'redirect') {
        return <ReplaceRedirect to={onFail.to} />;
      }
      if (onFail.action === 'hide') {
        return null;
      }
    }
    return this.props.children;
  }
}

export default withAuthState(AuthGuard);
