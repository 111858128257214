import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import styled from 'styled-components';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';
import withUiState, { UiProps } from '../../enhancers/withUiState';
import theme from '../../theme';
import { IUser, UserState } from '../../types/users';

interface IProps {
  item: IUser;
  groupId: string;
}

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      paddingBottom: 8,
      paddingTop: 3
    },
    listItemText: {
      fontSize: '0.875rem'
    }
  });

type Props = IProps &
  GroupsProps &
  AuthProps &
  UiProps &
  WithStyles<typeof styles>;

const GroupUserListItem: React.FC<Props> = props => {
  const isTier3 = props.item.tier3Ids.includes(props.groupId);
  const tier = isTier3 ? 'Organiser' : 'Volunteer';

  const getSecondary = () => {
    switch (props.item.state) {
      case UserState.DELETING: {
        return (
          <ListItemSecondaryAction>
            <CircularProgress />
          </ListItemSecondaryAction>
        );
      }
      default: {
        return (
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Delete"
              disabled={props.item.uid === props.auth.user!.uid}
              onClick={() =>
                props.confirmAction({
                  title: 'Are you sure you want to delete this user?',
                  color: 'secondary',
                  confirmText: 'Delete',
                  action: () =>
                    props.deleteGroupUser({
                      id: props.item.uid,
                      groupId: props.groupId
                    })
                })
              }
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        );
      }
    }
  };

  return (
    <Container>
      <ListItem
        classes={{
          root: props.classes.listItem
        }}
        disabled={props.item.state === UserState.DELETING}
      >
        <ListItemText
          classes={{
            primary: props.classes.listItemText,
            secondary: props.classes.listItemText
          }}
          primary={props.item.firstName + ' ' + props.item.lastName}
          {...(props.item.uid === props.auth.user!.uid
            ? { secondary: `${tier} (You)` }
            : { secondary: tier })}
        />
        {getSecondary()}
      </ListItem>
    </Container>
  );
};

const Container = styled.div`
  margin: ${p => theme.spacing.unit}px 0;
`;

export default withStyles(styles)(
  withUiState(withAuthState(withGroupsState(GroupUserListItem)))
);
