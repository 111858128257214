import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ArrowBack';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    title: {
      flexGrow: 1
    }
  });

interface IProps {
  title?: string;
  backTo?: string;
  tabs?: React.ReactNode;
}

type Props = IProps & WithStyles<typeof styles> & RouteComponentProps;

const BackScreen: React.FC<Props> = props => {
  const { classes } = props;
  return (
    <Container>
      <AppBar>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Menu"
            onClick={() => {
              if (props.backTo) {
                if (props.backTo.startsWith('lookatyouparty://')) {
                  return (window.location.href = props.backTo);
                } else {
                  return props.history.push(props.backTo);
                }
              }
              props.history.goBack();
            }}
          >
            <BackIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.title}>
            {props.title}
          </Typography>
        </Toolbar>
        {props.tabs}
      </AppBar>
      <Content tabs={!!props.tabs}>{props.children}</Content>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
`;

const Content = styled.div<{ tabs?: boolean }>`
  padding-top: ${p => 56 + (p.tabs ? 48 : 0)}px;
`;

export default withRouter(withStyles(styles)(BackScreen));
