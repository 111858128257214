import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { IPoll } from '../../../types/poll';
import { actions } from './';

interface IPolls {
  loaded: boolean;
  polls: IPoll[] | null;
  error: string;
}

export interface IPollsState {
  readonly polls: IPolls;
}

type PollsAction = ActionType<typeof actions>;

const initialState: IPollsState = {
  polls: {
    loaded: false,
    error: '',
    polls: null
  }
};

export default combineReducers<IPollsState, PollsAction>({
  polls: combineReducers<IPolls, PollsAction>({
    loaded: (state = initialState.polls.loaded, action) => {
      if (action.type === getType(actions.setPolls)) {
        return true;
      }
      return state;
    },
    error: (state = initialState.polls.error, action) => {
      return state;
    },
    polls: (state = initialState.polls.polls, action) => {
      switch (action.type) {
        case getType(actions.setPolls): {
          return action.payload.polls;
        }
        case getType(actions.addPoll): {
          if (state) {
            return [...state, action.payload.poll];
          }
          return [action.payload.poll];
        }
        case getType(actions.removePoll): {
          if (state) {
            return state.filter(poll => poll.id !== action.payload.id);
          }
          return state;
        }
        case getType(actions.setPoll): {
          if (state) {
            const index = state.findIndex(poll => {
              if (action.payload.poll.clientId) {
                return (
                  poll.clientId === action.payload.poll.clientId ||
                  poll.id === action.payload.poll.id
                );
              }
              return poll.id === action.payload.poll.id;
            });
            if (index > -1) {
              const newState = [...state];
              newState[index] = {
                ...action.payload.poll
              };
              return newState;
            }
          }
          return state;
        }
        case getType(actions.clearPolls): {
          return [];
        }
        default: {
          return state;
        }
      }
    }
  })
});
