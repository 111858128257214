import { createStandardAction } from 'typesafe-actions';

import { IPost, IPostCreationPayload, IPostUpdatePayload } from '../../../types/post';

const WATCH_POSTS = 'posts/WATCH_POSTS';
const STOP_WATCHING_POSTS = 'posts/STOP_WATCHING_POSTS';
const SET_POSTS = 'posts/SET_POSTS';
const SET_POST = 'posts/SET_POST';
const REMOVE_POST = 'posts/REMOVE_POST';
const ADD_POST = 'posts/ADD_POST';
const SET_POSTS_LOADED = 'posts/SET_LOADED';
const CLEAR_POSTS = 'posts/CLEAR_POSTS';
const CREATE_POST = 'posts/CREATE_POST';
const UPDATE_POST_CONTENT = 'posts/UPDATE_POST_CONTENT';
const SET_POST_ACTIVE_STATE = 'posts/SET_POST_ACTIVE_STATE';
const DELETE_POST = 'posts/DELETE_POST';

export const watchPosts = createStandardAction(WATCH_POSTS)();
export const stopWatchingPosts = createStandardAction(STOP_WATCHING_POSTS)();

export interface ISetPostsPayload {
  posts: IPost[];
}

export const setPosts = createStandardAction(SET_POSTS)<ISetPostsPayload>();

export interface ISetPostPayload {
  post: IPost;
}

export const setPost = createStandardAction(SET_POST)<ISetPostPayload>();

export interface IRemovePostPayload {
  id: string;
}

export const removePost = createStandardAction(REMOVE_POST)<
  IRemovePostPayload
>();

export interface IAddPostPayload {
  post: IPost;
}

export const addPost = createStandardAction(ADD_POST)<IAddPostPayload>();

export interface ISetPostsLoadedPayload {
  loaded: boolean;
}

export const setPostsLoaded = createStandardAction(SET_POSTS_LOADED)<
  ISetPostsLoadedPayload
>();

export interface ISubscribeToPostPayload {
  id: string;
}

export const clearPosts = createStandardAction(CLEAR_POSTS)();

export interface ICreatePostPayload {
  post: IPostCreationPayload;
  isApp?: boolean;
}

export const createPost = createStandardAction(CREATE_POST)<
  ICreatePostPayload
>();

export interface IUpdatePostContentPayload {
  post: IPostUpdatePayload;
}

export const updatePostContent = createStandardAction(UPDATE_POST_CONTENT)<
  IUpdatePostContentPayload
>();

export interface ISetPostActiveStatePayload {
  id: string;
  active: boolean;
}

export const setPostActiveState = createStandardAction(SET_POST_ACTIVE_STATE)<
  ISetPostActiveStatePayload
>();

export interface IDeletePostPayload {
  id: string;
}

export const deletePost = createStandardAction(DELETE_POST)<
  IDeletePostPayload
>();
