import Button from '@material-ui/core/Button';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import GroupSettingsForm from '../../components/GroupSettingsForm/GroupSettingsForm';
import NotFound from '../../components/NotFound/NotFound';
import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';
import withUiState, { UiProps } from '../../enhancers/withUiState';

const styles = (theme: Theme) =>
  createStyles({
    cta: {
      textTransform: 'initial',
      // width: '100%',
      fontWeight: 400,
      fontSize: 18,
      marginTop: theme.spacing.unit
    }
  });

type Props = RouteComponentProps<{ id: string }> &
  GroupsProps &
  WithStyles<typeof styles> &
  UiProps;

const GroupSettings: React.FC<Props> = props => {
  const { groups } = props.groups;
  const group = groups.groups!.find(
    theGroup => theGroup.id === props.match.params.id
  );
  if (!group) {
    return <NotFound />;
  }
  return (
    <Container>
      <GroupSettingsForm group={group} />
      <Actions>
        <Button
          classes={{
            root: props.classes.cta
          }}
          onClick={() => {
            props.confirmAction({
              title: 'Are you sure?',
              confirmText: 'Delete',
              color: 'secondary',
              action: () => {
                props.history.replace(`/app/admin/groups`);
                props.deleteGroup({ id: group.id });
              }
            });
          }}
          fullWidth
        >
          Delete Group
        </Button>
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 16px;
`;

const Actions = styled.div`
  display: flex;
`;

export default withStyles(styles)(withUiState(withGroupsState(GroupSettings)));
