export enum AuthState {
  LOADING,
  LOADED,
  LOGGED_OUT,
  LOGGING_IN,
  AWAITING_CODE_INPUT,
  VERIFYING_CODE,
  SETTING_UP,
  FETCHING_USER_DATA,
  LOGGED_IN,
  UNAUTHORISED,
  READY
}

export enum AuthError {
  COULD_NOT_SMS,
  CODE_VERIFICATION_FAILED,
  UNAUTHORISED,
  USER_EXISTS,
  INVALID_CREDENTIALS
}

export enum AuthTier {
  TIER1,
  TIER2,
  TIER3,
  TIER4
}

export const loggedInStates = [
  AuthState.LOGGED_IN,
  AuthState.LOADED,
  AuthState.LOGGING_IN,
  AuthState.SETTING_UP,
  AuthState.FETCHING_USER_DATA
];
