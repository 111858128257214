import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import withUsersState, { UsersProps } from '../../enhancers/withUsersState';
import { Invitee } from '../../types/users';
import InviteeListItem from './InviteeListItem';

interface IProps {
  filterText?: string;
  scrollRef: React.RefObject<HTMLDivElement>;
  count: number;
  incrementCount: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    subheader: {
      fontSize: '1rem'
    }
  });

type Props = UsersProps & IProps & WithStyles<typeof styles>;

const UsersList: React.FC<Props> = props => {
  const invitees = props.users.invitees;

  if (!invitees.loaded || !invitees.invitees) {
    return (
      <Centerer>
        <CircularProgress />
      </Centerer>
    );
  }

  type InviteesFilter = (invitee: Invitee) => boolean;
  const filters: InviteesFilter[] = [];

  if (props.filterText) {
    filters.push(invitee =>
      invitee.email.toLowerCase().includes(props.filterText!.toLowerCase())
    );
  }

  const filteredGroups = filters.reduce<Invitee[]>((acc, curr) => {
    return acc.filter(curr);
  }, invitees.invitees);

  const slicedGroups = filteredGroups.slice(0, props.count);

  return (
    <React.Fragment>
      {slicedGroups.length > 0 && (
        <List
          subheader={
            <ListSubheader
              classes={{ root: props.classes.subheader }}
              component="div"
              style={{ position: 'static' }}
            >
              Invites
            </ListSubheader>
          }
        >
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={props.incrementCount}
            hasMore={true || false}
            getScrollParent={() => props.scrollRef.current || null}
            useWindow={false}
          >
            {slicedGroups.map((invitee, i) => (
              <InviteeListItem key={invitee.id} item={invitee} />
            ))}
          </InfiniteScroll>
        </List>
      )}
    </React.Fragment>
  );
};

const Centerer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withStyles(styles)(withUsersState(UsersList));
