import { createStandardAction } from 'typesafe-actions';

import { AuthTier } from '../../../types/auth';
import { Invitee, ITier12InviteInput, IUser } from '../../../types/users';

const INVITE_TIER_12_USER = 'users/CREATE_TIER_12';
const ADD_INVITEE = 'users/ADD_INVITEE';
const SET_INVITEES = 'users/SET_INVITEES';
const WATCH_INVITEES = 'users/WATCH_INVITEES';
const SET_INVITEE = 'users/SET_INVITEE';
const REMOVE_INVITEE = 'users/REMOVE_INVITEE';
const DELETE_INVITEE = 'users/DELETE_INVITEE';

const WATCH_USERS = 'users/WATCH_USERS';
const SET_USERS = 'users/SET_USERS';
const SET_USER = 'users/SET_USER';
const DELETE_USER = 'users/DELETE_USER';
const REMOVE_USER = 'users/REMOVE_USER';
const ADD_USER = 'users/ADD_USER';

export interface IInviteTier12UserPayload {
  invite: ITier12InviteInput;
  tier: AuthTier;
}

export const inviteTier12User = createStandardAction(INVITE_TIER_12_USER)<
  IInviteTier12UserPayload
>();

export interface ISetInviteesPayload {
  invitees: Invitee[];
}

export const setInvitees = createStandardAction(SET_INVITEES)<
  ISetInviteesPayload
>();

export interface IAddInviteePayload {
  invitee: Invitee;
}

export const addInvitee = createStandardAction(ADD_INVITEE)<
  IAddInviteePayload
>();

export const watchInvitees = createStandardAction(WATCH_INVITEES)();

export interface ISetInviteePayload {
  invitee: Invitee;
}

export const setInvitee = createStandardAction(SET_INVITEE)<
  ISetInviteePayload
>();

export interface IRemoveInviteePayload {
  email: string;
}

export const removeInvitee = createStandardAction(REMOVE_INVITEE)<
  IRemoveInviteePayload
>();

export interface IDeleteInviteePayload {
  id: string;
}

export const deleteInvitee = createStandardAction(DELETE_INVITEE)<
  IDeleteInviteePayload
>();

export const watchUsers = createStandardAction(WATCH_USERS)();

export interface ISetUsersPayload {
  users: IUser[];
}

export const setUsers = createStandardAction(SET_USERS)<ISetUsersPayload>();

export interface ISetUserPayload {
  user: IUser;
}

export const setUser = createStandardAction(SET_USER)<ISetUserPayload>();

export interface IDeleteUserPayload {
  id: string;
}

export const deleteUser = createStandardAction(DELETE_USER)<
  IDeleteUserPayload
>();

export interface IRemoveUserPayload {
  id: string;
}

export const removeUser = createStandardAction(REMOVE_USER)<
  IDeleteUserPayload
>();

export interface IAddUserPayload {
  user: IUser;
}

export const addUser = createStandardAction(ADD_USER)<IAddUserPayload>();
