import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import styled from 'styled-components';

import NotFound from '../../components/NotFound/NotFound';
import AuthGuard from '../../containers/AuthGuard/AuthGuard';
import withUsersState, { UsersProps } from '../../enhancers/withUsersState';
import DefaultPage from '../../layouts/DefaultPage/DefaultPage';
import theme from '../../theme';
import { AuthTier, loggedInStates } from '../../types/auth';
import UsersInvitees from './UsersInvitees';
import UsersUsers from './UsersUsers';

type Props = UsersProps & RouteComponentProps<{ screen: string }>;

const Users: React.SFC<Props> = props => {
  const screens = ['users', 'invites'];
  const [screen, setScreen] = React.useState<number>(0);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const initScreen = props.match.params.screen;
    console.log({ initScreen });
    if (initScreen) {
      setScreen(screens.findIndex(theScreen => initScreen === theScreen));
    } else {
      setScreen(0);
    }
  }, []);

  React.useEffect(() => {
    if (screen > -1) {
      props.history.replace(`/app/admin/users/${screens[screen]}`);
    }
  }, [screen]);

  return (
    <AuthGuard allowedStates={loggedInStates} allowedTiers={[AuthTier.TIER1]}>
      <DefaultPage
        tabs={
          <Tabs
            variant="fullWidth"
            value={screen}
            onChange={(e, v) => setScreen(v)}
          >
            <Tab label="Users" />
            <Tab label="Invites" />
          </Tabs>
        }
      >
        <Content ref={contentRef}>
          <Switch>
            <Route
              exact
              path="/app/admin/users/users"
              component={(routeProps: RouteComponentProps<{ id: string }>) => (
                <UsersUsers {...routeProps} scrollRef={contentRef} />
              )}
            />
            <Route
              exact
              path="/app/admin/users/invites"
              component={(routeProps: RouteComponentProps<{ id: string }>) => (
                <UsersInvitees {...routeProps} scrollRef={contentRef} />
              )}
            />
            {typeof props.match.params.screen !== 'undefined' && (
              <Route component={NotFound} />
            )}
          </Switch>
        </Content>
      </DefaultPage>
    </AuthGuard>
  );
};

const Content = styled.div`
  overflow-y: auto;
  height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
`;

const FabContainer = styled.div`
  position: fixed;
  bottom: ${p => theme.spacing.unit * 2}px;
  right: ${p => theme.spacing.unit * 2}px;
`;

export default withUsersState(Users);
