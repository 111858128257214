import firebase from 'firebase/app';
import { NotificationManager } from 'react-notifications';
import { Action } from 'redux';
import { END, EventChannel, eventChannel } from 'redux-saga';
import { all, cancel, cancelled, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { Subtract } from 'utility-types';

import { actions } from '.';
import { Collection } from '../../../constants/collections';
import { AuthTier } from '../../../types/auth';
import { IPost, PostState } from '../../../types/post';
import { RootState } from '../../../types/store';
import { actions as authActions } from '../auth';
import { ICreatePostPayload, IDeletePostPayload, ISetPostActiveStatePayload, IUpdatePostContentPayload } from './actions';

const functionsEndpoint = process.env.REACT_APP_FUNCTIONS_ENDPOINT!;

function* handlePostDocChanges(
  change: firebase.firestore.DocumentChange | END | { type: 'START' }
) {
  if (change.type === 'START') {
    yield put(actions.setPostsLoaded({ loaded: true }));
    yield put(actions.setPosts({ posts: [] }));
    return;
  }
  if (change.type === '@@redux-saga/CHANNEL_END') {
    throw new Error('closed');
  }
  const store: RootState = yield select();
  const posts: IPost[] = store.posts.posts.posts || [];
  const post: IPost = {
    ...(change.doc.data() as Subtract<IPost, { id: string }>),
    id: change.doc.id!
  };

  const existingPost = posts.find(thePost => {
    if (post.clientId) {
      return thePost.clientId === post.clientId || thePost.id === post.id;
    }
    return thePost.id === post.id;
  });

  console.log({ change, existingPost });

  if (change.type === 'added') {
    if (existingPost) {
      yield put(actions.setPost({ post }));
    } else {
      yield put(actions.addPost({ post }));
    }
  }
  if (change.type === 'modified') {
    yield put(actions.setPost({ post }));
  }
  if (change.type === 'removed') {
    if (existingPost) {
      // TODO: User feedback
    }
    yield put(actions.removePost({ id: post.id }));
  }
}

function* handleWatchPosts() {
  let changesChannel!: EventChannel<
    firebase.firestore.DocumentChange | { type: 'START' }
  >;
  try {
    const db = firebase.firestore();

    let store: RootState = yield select();
    if (!store.auth.userData) {
      yield take(authActions.setUserData);
    }

    store = yield select();

    const userDetails = store.auth.userData!;
    const queryBase = db
      .collection(Collection.POSTS)
      .where('brandId', '==', userDetails.brandId);

    const postsChanges = () =>
      eventChannel<firebase.firestore.DocumentChange | { type: 'START' }>(
        emit => {
          let init = false;
          const handleSnapshot = (
            snapshot: firebase.firestore.QuerySnapshot
          ) => {
            if (!init) {
              init = true;
              emit({ type: 'START' });
            }
            snapshot.docChanges().map(emit);
          };

          if (store.auth.tier !== AuthTier.TIER1) {
            const tier3Listeners = userDetails.tier3Ids.map(id =>
              queryBase.where('groupId', '==', id).onSnapshot(handleSnapshot)
            );
            const tier4Listeners = userDetails.tier4Ids.map(id =>
              queryBase.where('groupId', '==', id).onSnapshot(handleSnapshot)
            );
            return () => {
              tier3Listeners.map(unsub => unsub());
              tier4Listeners.map(unsub => unsub());
            };
          } else {
            return queryBase.onSnapshot(handleSnapshot);
          }
        }
      );
    changesChannel = postsChanges();

    while (true) {
      const doc = yield take(changesChannel);
      yield handlePostDocChanges(doc);
    }
  } finally {
    if (yield cancelled()) {
      if (changesChannel) {
        yield changesChannel.close();
      }
    }
  }
}

function* watchPostsRequest() {
  while (yield take(actions.watchPosts)) {
    const postsWatcher = yield fork(handleWatchPosts);
    yield take(actions.stopWatchingPosts);
    yield put(actions.clearPosts());
    yield cancel(postsWatcher);
  }
}

function* handleCreatePost(action: Action & { payload: ICreatePostPayload }) {
  const state: RootState = yield select();
  const authorId = state.auth.user!.uid;
  const brand = state.brand.brand!;
  const date = +new Date();
  const tempId = '' + date + state.auth.user!.uid;
  const newPost: IPost = {
    ...action.payload.post,
    clientId: tempId,
    id: tempId,
    active: true,
    createdAt: Math.floor(date / 1000),
    updatedAt: Math.floor(date / 1000),
    brandId: brand.id,
    authorId,
    isDefaultGroup: brand.defaultGroupId === action.payload.post.groupId
  };
  yield put(
    actions.addPost({ post: { ...newPost, state: PostState.CREATING } })
  );
  try {
    const token = yield state.auth.user!.getIdToken();
    const brandId = state.brand.brand!.id;

    const result: Response = yield fetch(functionsEndpoint + '/createPost', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        brand: brandId,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...newPost
      })
    });

    switch (result.status) {
      case 200: {
        if (action.payload.isApp) {
          window.location.href = `lookatyouparty://group/${
            action.payload.post.groupId
          }`;
        } else {
          NotificationManager.success(
            `Your post titled '${newPost.title}' has been published`,
            'Post successfully published'
          );
        }
        break;
      }
      default: {
        throw new Error('Request Failed');
      }
    }
  } catch (e) {
    console.log({ e });
    yield put(actions.removePost({ id: tempId }));
    // user feedback
  }
}

function* watchCreatePost() {
  yield takeEvery(actions.createPost, handleCreatePost);
}

function* handleUpdatePost(post: IPost) {
  const state: RootState = yield select();
  const posts = state.posts.posts.posts!;
  const oldPost = posts.find(thePost => thePost.id === post.id)!;

  const newPost: IPost = {
    ...post,
    state: PostState.UPDATING
  };

  yield put(actions.setPost({ post: newPost }));
  try {
    const token = yield state.auth.user!.getIdToken();
    const brandId = state.brand.brand!.id;

    const result: Response = yield fetch(functionsEndpoint + '/updatePost', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        brand: brandId,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...post
      })
    });

    switch (result.status) {
      case 200: {
        break;
      }
      default: {
        throw new Error('Request Failed');
      }
    }
  } catch (e) {
    console.log({ e });
    yield put(actions.setPost({ post: oldPost }));
    throw new Error('Failed to update post');
  }
}

function* handleUpdatePostContent(
  action: Action & { payload: IUpdatePostContentPayload }
) {
  const state: RootState = yield select();
  const { id, title, content, image, video } = action.payload.post;
  const posts = state.posts.posts.posts!;
  const post = posts.find(thePost => thePost.id === id);
  if (post) {
    const newPost: IPost = {
      ...post,
      title,
      content,
      image,
      video,
      updatedAt: Math.floor(+new Date() / 1000)
    };
    try {
      yield handleUpdatePost(newPost);
      NotificationManager.success('Successfully updated post');
    } catch (e) {
      NotificationManager.error('Failed to update post');
    }
  }
}

function* watchUpdatePostContent() {
  yield takeEvery(actions.updatePostContent, handleUpdatePostContent);
}

function* handleSetPostActiveState(
  action: Action & { payload: ISetPostActiveStatePayload }
) {
  const state: RootState = yield select();
  const { id, active } = action.payload;
  const posts = state.posts.posts.posts!;
  const post = posts.find(thePost => thePost.id === id);
  if (post) {
    const newPost: IPost = {
      ...post,
      active
    };
    try {
      yield handleUpdatePost(newPost);
      NotificationManager.success(
        `Successfully ${active ? 'enabled' : 'disabled'} post`
      );
    } catch (e) {
      console.log({ e });
      NotificationManager.error(
        `Failed to ${active ? 'enable' : 'disable'} post`
      );
    }
  }
}

function* watchSetPostActiveState() {
  yield takeEvery(actions.setPostActiveState, handleSetPostActiveState);
}

function* handleDeletePost(action: Action & { payload: IDeletePostPayload }) {
  const state: RootState = yield select();
  const { id } = action.payload;
  const posts = state.posts.posts.posts!;
  const post = posts.find(thePost => thePost.id === id);
  if (post) {
    const newPost: IPost = {
      ...post,
      state: PostState.DELETING
    };
    yield put(actions.setPost({ post: newPost }));
    try {
      const token = yield state.auth.user!.getIdToken();
      const brandId = state.brand.brand!.id;

      const result: Response = yield fetch(functionsEndpoint + '/deletePost', {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
          brand: brandId,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id,
          groupId: post.groupId
        })
      });

      switch (result.status) {
        case 200: {
          break;
        }
        default: {
          throw new Error('Request Failed');
        }
      }
      NotificationManager.success('Successfully deleted post');
    } catch (e) {
      console.log({ e });
      NotificationManager.error('Failed to delete post');
      yield put(actions.setPost({ post }));
    }
  }
}

function* watchDeletePosts() {
  yield takeEvery(actions.deletePost, handleDeletePost);
}

export default function*() {
  yield all([
    watchPostsRequest(),
    watchCreatePost(),
    watchUpdatePostContent(),
    watchSetPostActiveState(),
    watchDeletePosts()
  ]);
}
