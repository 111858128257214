import AppBar from '@material-ui/core/AppBar';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  title: string;
  progress?: number;
}

const styles = (theme: Theme) =>
  createStyles({
    white: {
      color: 'white'
    },
    title: {
      fontWeight: 400,
      fontSize: 18
    },
    linearColorPrimary: {
      backgroundColor: 'rgba(0, 133, 235, .23)'
    },
    linearBarColorPrimary: {
      backgroundColor: '#8AC926'
    }
  });

type Props = IProps & WithStyles<typeof styles>;

const HeaderScreen: React.FC<Props> = props => {
  return (
    <Container>
      <AppBar color="primary" position="static">
        <AppBarInner>
          <Typography
            variant="h6"
            classes={{
              root: classnames(props.classes.white, props.classes.title)
            }}
          >
            {props.title}
          </Typography>
        </AppBarInner>
        {typeof props.progress === 'number' && (
          <ProgressContainer>
            <LinearProgress
              value={props.progress}
              variant="determinate"
              classes={{
                colorPrimary: props.classes.linearColorPrimary,
                barColorPrimary: props.classes.linearBarColorPrimary
              }}
            />
          </ProgressContainer>
        )}
      </AppBar>
      <Content>{props.children}</Content>
    </Container>
  );
};

const ProgressContainer = styled.div`
  background: white;
`;

const Content = styled.div`
  margin-top: 26px;
  padding: 0 36px;
`;

const AppBarInner = styled.div`
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  flex: 1;
`;

export default withStyles(styles)(HeaderScreen);
