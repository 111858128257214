import firebase, { User } from 'firebase/app';
import { NotificationManager } from 'react-notifications';
import { Action } from 'redux';
import { EventChannel, eventChannel } from 'redux-saga';
import { all, call, cancel, cancelled, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { actions } from '.';
import { Collection } from '../../../constants/collections';
import { AuthError, AuthState, AuthTier } from '../../../types/auth';
import { IBrandMeta } from '../../../types/brand';
import { RootState } from '../../../types/store';
import { IUser } from '../../../types/users';
import * as brandActions from '../brand/actions';
import * as groupsActions from '../groups/actions';
import * as joinRequestsActions from '../joinRequests/actions';
import * as postsActions from '../posts/actions';
import { actions as routingActions } from '../routing';
import * as usersActions from '../users/actions';
import {
  IAcceptInvitationPayload,
  IDeclineInvitePayload,
  IEmailLoginPayload,
  ILoggedInAcceptInvitePayload,
  ILoginPayload,
  ISetAuthStatePayload,
  ITokenLoginPayload,
} from './actions';

const functionsEndpoint = process.env.REACT_APP_FUNCTIONS_ENDPOINT!;

type AuthChannelResult = { user: firebase.User | null };

const createAuthChannel = () =>
  eventChannel<AuthChannelResult>(emitter => {
    const listener = (result: firebase.User | null) =>
      emitter({ user: result });
    return firebase.auth().onAuthStateChanged(listener);
  });

function* verifyRecaptcha() {
  const state: RootState = yield select();
  state.auth.recaptcha!.render();
}

function* watchVerifyRecaptcha() {
  yield takeEvery(actions.verifyRecaptcha, verifyRecaptcha);
}

function* login(action: Action & { payload: ILoginPayload }) {
  const state: RootState = yield select();
  if (state.auth.recaptcha) {
    yield put(actions.setAuthState({ state: AuthState.LOGGING_IN }));
    try {
      const confirmationResult: firebase.auth.ConfirmationResult = yield firebase
        .auth()
        .signInWithPhoneNumber(
          action.payload.mobileNumber,
          state.auth.recaptcha
        );
      yield put(actions.setAuthState({ state: AuthState.AWAITING_CODE_INPUT }));
      while (true) {
        yield put(actions.setAuthError({ error: null }));
        const verifyCodePayload = yield take(actions.verifyCode);
        try {
          yield confirmationResult.confirm(verifyCodePayload.payload.code);
          break;
        } catch (e) {
          console.log({ e });
          yield put(
            actions.setAuthError({ error: AuthError.CODE_VERIFICATION_FAILED })
          );
        }
      }
      yield take(actions.setUser);
      yield put(actions.setAuthState({ state: AuthState.LOADED }));
    } catch (e) {
      console.log({ e });
      return yield put(
        actions.setAuthError({ error: AuthError.COULD_NOT_SMS })
      );
    }
  }
}

function* getUserTier(id: string) {
  let store: RootState = yield select();
  if (!store.brand.brand) {
    yield take(brandActions.setBrand);
  }
  store = yield select();
  const brandId = store.brand.brand!.id;
  const db = firebase.firestore();
  const result: firebase.firestore.QuerySnapshot = yield db
    .collection('brandsMeta')
    .where('brandId', '==', brandId)
    .get();
  if (result.empty) {
    return undefined;
  }
  const brandMeta: IBrandMeta = result.docs[0].data() as IBrandMeta;

  console.log({ brandMeta, id });

  if (brandMeta.adminIds.includes(id)) {
    console.log('tier 1');
    return AuthTier.TIER1;
  }

  return AuthTier.TIER2;
}

function* watchLogin() {
  yield takeLatest(actions.login, login);
}

function* handleInitialAuthCheck(payload: AuthChannelResult) {
  yield put(actions.setUser({ user: payload.user }));
  yield put(actions.setAuthState({ state: AuthState.LOADED }));
}

function* getCustomToken() {
  let state: RootState = yield select();
  if (!state.brand.brand) {
    yield take(brandActions.setBrand);
  }
  state = yield select();
  const token = yield state.auth.user!.getIdToken();

  const response: Response = yield fetch(functionsEndpoint + '/getToken', {
    method: 'POST',
    headers: {
      brand: state.brand.brand!.id,
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    }
  });

  switch (response.status) {
    case 200: {
      return yield response.text();
    }
    case 500:
    default: {
      throw new Error('Could not get token');
    }
  }
}

function* getUserData(id: string) {
  const db = firebase.firestore();
  const result: firebase.firestore.QuerySnapshot = yield db
    .collection(Collection.USERS)
    .where('uid', '==', id)
    .get();
  if (result.empty) {
    throw new Error('User not found');
  }
  return {
    id: result.docs[0].id,
    uid: id,
    ...result.docs[0].data()
  };
}

function* handleWatchUserData() {
  const store: RootState = yield select();
  const db = firebase.firestore();

  const dataChannel = eventChannel<IUser>(emit => {
    return db
      .collection(Collection.USERS)
      .doc(store.auth.userData!.id!)
      .onSnapshot(snapshot => {
        emit(snapshot.data() as IUser);
      });
  });
  try {
    while (true) {
      const data = yield take(dataChannel);
      yield put(actions.setUserData({ data }));
    }
  } finally {
    if (yield cancelled()) {
      if (dataChannel) {
        yield dataChannel.close();
      }
    }
  }
}

function* watchUserData() {
  while (yield take(actions.watchUserData)) {
    const joinRequestsWatcher = yield fork(handleWatchUserData);
    yield take(actions.stopWatchingUserData);
    yield cancel(joinRequestsWatcher);
  }
}

function* handleAuthState(action: Action & { payload: ISetAuthStatePayload }) {
  let state: RootState = yield select();
  if (!state.brand.brand) {
    yield take(brandActions.setBrand);
  }
  state = yield select();
  const user = state.auth.user;
  switch (action.payload.state) {
    case AuthState.LOADED: {
      if (!user) {
        yield put(actions.setAuthState({ state: AuthState.LOGGED_OUT }));
      } else {
        try {
          const token = yield getCustomToken();
          console.log({ token });
        } catch (e) {
          console.log(e);
        }

        yield put(
          actions.setAuthState({ state: AuthState.FETCHING_USER_DATA })
        );
        const tier: AuthTier | null = yield getUserTier(user.uid);
        try {
          const data: IUser = yield getUserData(user.uid);
          if (data.brandId !== state.brand.brand!.id) {
            throw new Error('');
          }
          console.log({ tier });
          if (tier !== null) {
            yield put(actions.setUserData({ tier, data }));
            yield put(actions.watchUserData());
            yield put(usersActions.watchUsers());
            yield put(usersActions.watchInvitees());
            yield put(postsActions.watchPosts());
            yield put(joinRequestsActions.watchJoinRequests());
            yield put(groupsActions.watchGroups());
            yield put(actions.setAuthState({ state: AuthState.LOGGED_IN }));
          } else {
            // Unauthorized
            yield put(actions.setAuthState({ state: AuthState.UNAUTHORISED }));
            yield put(actions.setAuthError({ error: AuthError.UNAUTHORISED }));
          }
        } catch (e) {
          yield put(actions.setAuthState({ state: AuthState.UNAUTHORISED }));
          yield put(actions.setAuthError({ error: AuthError.UNAUTHORISED }));
        }
      }
    }
  }
}

function* watchAuthStates() {
  yield takeEvery(actions.setAuthState, handleAuthState);
}

function* handleAuthChange(payload: AuthChannelResult) {
  yield put(actions.setUser({ user: payload.user }));
}

function* watchAuthChanges() {
  const authChannel: EventChannel<AuthChannelResult> = yield call(
    createAuthChannel
  );
  const result: AuthChannelResult = yield take(authChannel);
  yield handleInitialAuthCheck(result);
  yield takeEvery(authChannel, handleAuthChange);
}

function* watchLogoutRequests() {
  yield takeLatest(actions.requestLogout, function*() {
    yield firebase.auth().signOut();
    yield put(actions.logout());
    yield put(actions.stopWatchingUserData());
    yield put(actions.setAuthState({ state: AuthState.LOGGED_OUT }));
    yield put(brandActions.fetchBrand());
    window.location.reload();
  });
}

function* acceptInvitation(
  action: Action & { payload: IAcceptInvitationPayload }
) {
  let state: RootState = yield select();
  if (state.auth.recaptcha) {
    const confirmationResult: firebase.auth.ConfirmationResult = yield firebase
      .auth()
      .signInWithPhoneNumber(action.payload.mobileNumber, state.auth.recaptcha);
    yield put(actions.setAuthState({ state: AuthState.AWAITING_CODE_INPUT }));

    let credential!: firebase.auth.UserCredential;

    while (true) {
      yield put(actions.setAuthError({ error: null }));
      console.log('waiting');
      const verifyCodePayload = yield take(actions.verifyCode);
      console.log('got it');
      try {
        credential = yield confirmationResult.confirm(
          verifyCodePayload.payload.code
        );
        break;
      } catch (e) {
        console.log({ e });
        yield put(
          actions.setAuthError({ error: AuthError.CODE_VERIFICATION_FAILED })
        );
      }
    }
    yield put(actions.setAuthState({ state: AuthState.SETTING_UP }));

    if (credential.user) {
      try {
        if (!credential.user.email) {
          const emailCredential = firebase.auth.EmailAuthProvider.credential(
            action.payload.email,
            action.payload.password
          );
          yield credential.user.linkWithCredential(emailCredential);
        }
      } catch (e) {
        console.log({ e });
      }
      const { inviteId, firstName, lastName } = action.payload;
      const uid = credential.user.uid;
      const response: Response = yield fetch(
        functionsEndpoint + '/acceptInvite',
        {
          method: 'POST',
          headers: {
            brand: state.brand.brand!.id,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            inviteId,
            firstName,
            lastName,
            uid
          })
        }
      );
      switch (response.status) {
        case 200: {
          yield put(actions.setAuthState({ state: AuthState.LOADED }));
          yield put(routingActions.setRedirect({ to: '/app/admin' }));
          return;
        }
        case 409: {
          yield put(actions.setAuthError({ error: AuthError.USER_EXISTS }));
        }
        default: {
          yield put(
            routingActions.setRedirect({ to: `/app/invite/${inviteId}/failed` })
          );
          yield put(actions.setAuthState({ state: AuthState.LOGGED_OUT }));
        }
      }
    }
  }
}

function* watchAcceptInvitation() {
  yield takeLatest(actions.acceptInvitation, acceptInvitation);
}

function* handleTokenLogin(action: Action & { payload: ITokenLoginPayload }) {
  try {
    yield firebase.auth().signInWithCustomToken(action.payload.token);
    yield put(actions.setAuthState({ state: AuthState.LOADED }));
    yield take(actions.setUserData);
    yield put(
      routingActions.setRedirect({
        to: action.payload.redirectTo || '/app/request'
      })
    );
  } catch (e) {
    console.log({ e });
    yield put(routingActions.setRedirect({ to: '/app/token/error' }));
  }
}

function* watchTokenLogin() {
  yield takeLatest(actions.tokenLogin, handleTokenLogin);
}

function* handleEmailLogin(action: Action & { payload: IEmailLoginPayload }) {
  try {
    yield firebase
      .auth()
      .signInWithEmailAndPassword(
        action.payload.email,
        action.payload.password
      );
  } catch (e) {
    console.log({ e });
    yield put(actions.setAuthError({ error: AuthError.INVALID_CREDENTIALS }));
  }
  yield take(actions.setUser);
  yield put(actions.setAuthState({ state: AuthState.LOADED }));
}

function* watchEmail() {
  yield takeLatest(actions.emailLogin, handleEmailLogin);
}

function* handleLoggedInAcceptInvite(
  action: Action & { payload: ILoggedInAcceptInvitePayload }
) {
  let store: RootState = yield select();
  if (!store.brand.brand) {
    yield take(brandActions.setBrand);
  }
  store = yield select();
  const token = yield store.auth.user!.getIdToken();
  const response: Response = yield fetch(functionsEndpoint + '/acceptInvite', {
    method: 'POST',
    headers: {
      brand: store.brand.brand!.id,
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      inviteId: action.payload.id
    })
  });
  switch (response.status) {
    case 200: {
      NotificationManager.success('Successfully accepted invitation');
      return yield put(routingActions.setRedirect({ to: '/app/admin' }));
    }
    case 500:
    default: {
      return yield put(routingActions.setRedirect({ to: '/app/admin/failed' }));
    }
  }
}

function* watchLoggedInAcceptInvite() {
  yield takeEvery(actions.loggedInAcceptInvite, handleLoggedInAcceptInvite);
}

function* handleDeclineInvite(
  action: Action & { payload: IDeclineInvitePayload }
) {
  let store: RootState = yield select();
  if (!store.brand.brand) {
    yield take(brandActions.setBrand);
  }
  store = yield select();
  const token = yield store.auth.user!.getIdToken();
  const response: Response = yield fetch(functionsEndpoint + '/declineInvite', {
    method: 'POST',
    headers: {
      brand: store.brand.brand!.id,
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      inviteId: action.payload.id
    })
  });
  switch (response.status) {
    case 200: {
      NotificationManager.success('Successfully declined invitation');
      return yield put(routingActions.setRedirect({ to: '/app/admin' }));
    }
    case 500:
    default: {
      return yield put(routingActions.setRedirect({ to: '/app/admin/failed' }));
    }
  }
}

function* watchDeclineInvite() {
  yield takeEvery(actions.declineInvite, handleDeclineInvite);
}

export default function*() {
  yield all([
    watchVerifyRecaptcha(),
    watchLogin(),
    watchAuthStates(),
    watchAuthChanges(),
    watchLogoutRequests(),
    watchAcceptInvitation(),
    watchTokenLogin(),
    watchEmail(),
    watchLoggedInAcceptInvite(),
    watchDeclineInvite(),
    watchUserData()
  ]);
}
