import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { Redirect } from 'react-router';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withUsersState from '../../enhancers/withUsersState';
import withUserInviteForm, { UserInviteFormProps } from './withUserInviteForm';

type Props = UserInviteFormProps & AuthProps;

const UserInviteForm: React.FC<Props> = props => {
  if (props.status === 'submitting') {
    return <Redirect to="/app/admin/users/invites" />;
  }
  return (
    <form onSubmit={props.handleSubmit}>
      <TextField
        label="Email Address"
        type="email"
        value={props.values.email}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        name="email"
        fullWidth
        error={props.touched.email && !!props.errors.email}
        helperText={props.touched.email ? props.errors.email : ''}
        variant="outlined"
        margin="normal"
      />
      <br />
      <br />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={props.submitForm}
      >
        Invite
      </Button>
    </form>
  );
};

export default withAuthState(
  withUsersState(withUserInviteForm(UserInviteForm))
);
