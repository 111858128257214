import * as React from 'react';
import styled from 'styled-components';

const VolunteerPreFooter = () => (
  <Container>
    <p>
      Powered by Look @ You <br /> ©2019
    </p>
  </Container>
);

const Container = styled.div`
  width: 100%;
  p {
    text-align: center;
    color: #fff !important;
    font-size: 15px;
    padding-top: 30px;
    @media screen and (min-width: 1400px) {
      padding-top: 107px;
    }
  }
`;

export default VolunteerPreFooter;
