import * as React from 'react';

import NotFound from '../../components/NotFound/NotFound';
import withBrandState, { BrandProps } from '../../enhancers/withBrandState';
import { BrandError } from '../../types/brand';

type Props = BrandProps & { children: React.ReactNode };

const BrandChecker: React.FC<Props> = props => {
  if (
    props.brand.loaded &&
    typeof props.brand.error === 'number' &&
    [BrandError.NOT_FOUND, BrandError.NO_SUBDOMAIN].includes(props.brand.error)
  ) {
    return <NotFound />;
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default withBrandState(BrandChecker);
