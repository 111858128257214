export default () => {
  const subdomain = window.location.href.split('//')[1].split('.')[0];
  if (
    ['look-at-you-party-cms'].indexOf(subdomain) === -1 &&
    !subdomain.includes('localhost') &&
    !subdomain.includes('192')
  ) {
    return subdomain;
  }
  // return undefined;
  return 'anc';
};
