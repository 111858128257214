import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import noPostsImage from '../../assets/img/posts-blank.png';
import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withPostsState, { PostsProps } from '../../enhancers/withPostsState';
import { IPost } from '../../types/post';
import EmptyState from '../EmptyState/EmptyState';
import Post from './Post';

interface IProps {
  groupId: string;
  scrollRef: React.RefObject<HTMLDivElement>;
  count: number;
  incrementCount: () => void;
  filterText?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    subheader: {
      fontSize: '1rem'
    }
  });

type Props = PostsProps & IProps & AuthProps & WithStyles<typeof styles>;

const PostsList: React.FC<Props> = props => {
  const { count, incrementCount } = props;
  const posts = props.posts.posts;
  const { userData } = props.auth;
  if (!posts.loaded || !posts.posts || !userData) {
    return (
      <Centerer style={{ height: 'calc(100% - 200px)' }}>
        <CircularProgress />
      </Centerer>
    );
  }
  type PostFilter = (post: IPost) => boolean;
  const filters: PostFilter[] = [];

  if (!userData.isTier1 && !userData.tier3Ids.includes(props.groupId)) {
    filters.push(post => post.authorId === userData.uid!);
  }
  filters.push(post => post.groupId === props.groupId);

  if (props.filterText) {
    filters.push(post =>
      post.title.toLowerCase().includes(props.filterText!.toLowerCase())
    );
  }
  const filteredPosts = filters.reduce<IPost[]>((acc, curr) => {
    return acc.filter(curr);
  }, posts.posts);

  const slicedPosts = filteredPosts.slice(0, count);

  return (
    <React.Fragment>
      {filteredPosts.length > 0 ? (
        <List
          subheader={
            <ListSubheader
              classes={{ root: props.classes.subheader }}
              style={{ position: 'static' }}
              component="div"
            >
              Posts
            </ListSubheader>
          }
        >
          <Container>
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={incrementCount}
              hasMore={true}
              getScrollParent={() => props.scrollRef.current || null}
              useWindow={false}
            >
              {slicedPosts.map((post, i) => (
                <Post
                  key={post.id + '' + i}
                  post={post}
                  groupId={props.groupId}
                />
              ))}
            </InfiniteScroll>
          </Container>
        </List>
      ) : (
        <EmptyState
          image={noPostsImage}
          text={
            <React.Fragment>
              There are no posts to show.
              <br />
              Post one using the button below
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  );
};

const Container = styled.div`
  padding: 0 16px;
`;

const Centerer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withStyles(styles)(withAuthState(withPostsState(PostsList)));
