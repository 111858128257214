import { InjectedFormikProps, withFormik } from 'formik';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';

import { PostsProps } from '../../enhancers/withPostsState';
import { UiProps } from '../../enhancers/withUiState';
import { IPost } from '../../types/post';

type FormValues = {
  title: string;
  content: string;
  image: string;
  video: string;
};

interface IFormProps {
  groupId: string;
  isApp: boolean;
  post?: IPost;
}

type FormProps = IFormProps & PostsProps & RouteComponentProps & UiProps;

export type NewPostFormProps = InjectedFormikProps<FormProps, FormValues> &
  Partial<FormValues>;

const initialValues = {
  title: '',
  content: '',
  image: '',
  video: ''
};

export default withFormik<FormProps, FormValues>({
  handleSubmit: (values, { props, setSubmitting, setError, resetForm }) => {
    setSubmitting(true);
    const { title, content, image, video } = values;
    const { groupId } = props;
    try {
      if (props.post) {
        props.confirmAction({
          title: 'Promote this post',
          description:
            'Are you sure you want to promote this post to your main group?',
          action: () => {
            props.createPost({
              post: {
                title,
                content,
                image,
                video,
                groupId
              },
              isApp: props.isApp
            });
            if (!props.isApp) {
              props.history.replace(`/app/admin/groups/${groupId}/posts`);
            }
          }
        });
      } else {
        props.createPost({
          post: {
            title,
            content,
            image,
            video,
            groupId
          },
          isApp: props.isApp
        });
        if (!props.isApp) {
          props.history.replace(`/app/admin/groups/${groupId}/posts`);
        }
      }
    } catch (e) {
      console.log({ e });
    }
  },
  isInitialValid: props => {
    const propsRef = props as FormProps;
    return !!propsRef.post;
  },
  mapPropsToValues: ({ post }) =>
    post
      ? {
          title: post.title,
          content: post.content,
          image: post.image,
          video: post.video || ''
        }
      : { ...initialValues },
  validationSchema: Yup.object().shape({
    title: Yup.string().required(),
    content: Yup.string().required(),
    image: Yup.string().required(),
    video: Yup.string()
  })
});
