import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  deleteInvitee,
  deleteUser,
  IDeleteInviteePayload,
  IDeleteUserPayload,
  IInviteTier12UserPayload,
  inviteTier12User,
  watchInvitees,
  watchUsers,
} from '../store/modules/users/actions';
import { IUsersState } from '../store/modules/users/reducer';
import { RootState } from '../types/store';

const mapStateToProps = (state: RootState) => ({
  users: state.users
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      inviteTier12User,
      watchInvitees,
      deleteInvitee,
      watchUsers,
      deleteUser
    },
    dispatch
  );

interface IUsersDispatch {
  inviteTier12User: (payload: IInviteTier12UserPayload) => void;
  watchInvitees: () => void;
  watchUsers: () => void;
  deleteInvitee: (payload: IDeleteInviteePayload) => void;
  deleteUser: (payload: IDeleteUserPayload) => void;
}

interface IUsersComponentState {
  users: IUsersState;
}

export type UsersProps = IUsersDispatch & IUsersComponentState;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
