import { createStandardAction } from 'typesafe-actions';

const SET_REDIRECT = 'authAddons/SET_REDIRECT';
const CLEAR_REDIRECT = 'authAddons/CLEAR_REDIRECT';
const REDIRECT = 'authAddons/REDIRECT';

export const setRedirect = createStandardAction(SET_REDIRECT)<{ to: string }>();

export const clearRedirect = createStandardAction(CLEAR_REDIRECT)();

export const redirect = createStandardAction(REDIRECT)();
