import { END } from '@redux-saga/types';
import firebase from 'firebase/app';
import { Action } from 'redux';
import { EventChannel, eventChannel } from 'redux-saga';
import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import { Subtract } from 'utility-types';

import { Collection } from '../../../constants/collections';
import { AuthState, AuthTier } from '../../../types/auth';
import { IGroup, GroupState } from '../../../types/groups';
import { RootState } from '../../../types/store';
import {
  Invitee,
  InviteState,
  InviteStatus,
  IUser,
  UserState
} from '../../../types/users';
import { actions as authActions } from '../auth/';
import { actions as brandActions } from '../brand/';
import { actions as routingActions } from '../routing';
import { actions as pollsActions } from '../polls';
import { actions as usersActions } from '../users';
import { IDeleteUserPayload } from '../users/actions';
import * as actions from './actions';
import { IDeleteGroupUserPayload } from './actions';
import { NotificationManager } from 'react-notifications';

const functionsEndpoint = process.env.REACT_APP_FUNCTIONS_ENDPOINT!;

function* handleCreateGroup(
  action: Action & { payload: actions.ICreateGroupPayload }
) {
  const { details } = action.payload;
  const {
    payload: { description }
  }: { payload: actions.ISetGroupDescriptionPayload } = yield take(
    actions.setGroupDescription
  );

  let state: RootState = yield select();

  const token = yield state.auth.user!.getIdToken();

  const result: Response = yield fetch(functionsEndpoint + '/createGroup', {
    method: 'POST',
    headers: {
      brand: state.brand.brand!.id,
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...details,
      description
    })
  });

  switch (result.status) {
    case 200: {
      const id: string = yield result.text();
      yield put(routingActions.setRedirect({ to: `/app/request/${id}` }));
      break;
    }
    default: {
      console.log('error');
    }
  }
}

function* watchCreateGroup() {
  yield takeLatest(actions.createGroup, handleCreateGroup);
}

type GroupChannelEmit =
  | { data: firebase.firestore.QuerySnapshot; type: 'SNAPSHOT' }
  | { data: firebase.firestore.DocumentChange; type: 'CHANGE' };

function* handleGroupsDocChanges(emitted: GroupChannelEmit | END) {
  if (emitted.type === 'SNAPSHOT') {
    yield put(
      actions.setGroups({
        groups: emitted.data.docs.map(doc => ({
          id: doc.id,
          ...(doc.data() as Subtract<IGroup, { id: string }>)
        }))
      })
    );
    return yield put(actions.setGroupsLoaded({ loaded: true }));
  }
  if (emitted.type === '@@redux-saga/CHANNEL_END') {
    throw new Error('closed');
  }
  const store: RootState = yield select();
  const groups: IGroup[] = store.groups.groups.groups || [];
  const group: IGroup = {
    id: emitted.data.doc.id,
    ...(emitted.data.doc.data() as Subtract<IGroup, { id: string }>)
  };

  const existingGroup = groups.find(theGroup => {
    return theGroup.id === group.id;
  });

  if (emitted.data.type === 'added') {
    if (existingGroup) {
      yield put(actions.setGroup({ group }));
    } else {
      yield put(actions.addGroup({ group }));
    }
  }
  if (emitted.data.type === 'modified') {
    yield put(actions.setGroup({ group }));
  }
  if (emitted.data.type === 'removed') {
    if (existingGroup) {
      // TODO: User feedback
    }
    yield put(actions.removeGroup({ id: group.id }));
  }
}

function* watchGroups() {
  let changesChannel!: EventChannel<GroupChannelEmit>;
  try {
    let store: RootState = yield select();
    if (!store.brand.brand) {
      yield take(brandActions.setBrand);
    }
    store = yield select();
    while (true) {
      store = yield select();
      if (store.auth.state === AuthState.LOGGED_IN) {
        break;
      }
      yield take(authActions.setAuthState);
    }

    while (true) {
      store = yield select();
      if (typeof store.auth.tier === 'number') {
        break;
      }
      yield take(authActions.setUserData);
    }

    const user = store.auth.user!;
    const db = firebase.firestore();

    const query = db
      .collection(Collection.GROUPS)
      .where('brandId', '==', store.brand.brand!.id);

    if (store.auth.tier! === AuthTier.TIER1) {
      const groupsChanges = () =>
        eventChannel<GroupChannelEmit>(emit => {
          let init = false;
          const handleSnapshot = (
            snapshot: firebase.firestore.QuerySnapshot
          ) => {
            if (!init) {
              init = true;
              emit({ data: snapshot, type: 'SNAPSHOT' });
            } else {
              snapshot
                .docChanges()
                .map(change => emit({ data: change, type: 'CHANGE' }));
            }
          };
          const listener = query.onSnapshot(handleSnapshot);
          return listener;
        });

      changesChannel = groupsChanges();
    } else {
      const groupsChanges = () =>
        eventChannel<GroupChannelEmit>(emit => {
          let init = false;
          const handleSnapshot = (
            snapshot: firebase.firestore.QuerySnapshot
          ) => {
            if (!init) {
              init = true;
              emit({ data: snapshot, type: 'SNAPSHOT' });
            } else {
              snapshot
                .docChanges()
                .map(change => emit({ data: change, type: 'CHANGE' }));
            }
          };

          const tier3Listener = query
            .where('tier3Ids', 'array-contains', user.uid)
            .onSnapshot(handleSnapshot);

          const tier4Listener = query
            .where('tier4Ids', 'array-contains', user.uid)
            .onSnapshot(handleSnapshot);
          return () => {
            tier3Listener();
            tier4Listener();
          };
        });
      changesChannel = groupsChanges();
    }

    while (true) {
      yield put(actions.watchGroupInvitees());
      yield put(pollsActions.watchPolls());
      const doc = yield take(changesChannel);
      yield put(actions.stopWatchingGroupInvitees());
      yield put(pollsActions.stopWatchingPolls());
      yield handleGroupsDocChanges(doc);
    }
  } finally {
    console.log('cancelling...');
    if (yield cancelled()) {
      if (changesChannel) {
        console.log('closing channels...');
        yield changesChannel.close();
      }
    }
  }
}

function* watchGroupsRequest() {
  while (yield take(actions.watchGroups)) {
    const groupsWatcher = yield fork(watchGroups);
    yield take(actions.stopWatchingGroups);
    yield cancel(groupsWatcher);
  }
}

function* handleInviteeDocChanges(
  change: firebase.firestore.DocumentChange | END | { type: 'START' }
) {
  if (change.type === 'START') {
    yield put(actions.setGroupInviteesLoaded({ loaded: true }));
    yield put(actions.setGroupInvitees({ invitees: [] }));
    return;
  }
  if (change.type === '@@redux-saga/CHANNEL_END') {
    throw new Error('closed');
  }
  const store: RootState = yield select();
  const invitees: Invitee[] = store.groups.invitees.invitees || [];
  const invitee: Invitee = {
    ...(change.doc.data() as Subtract<Invitee, { id: string }>),
    id: change.doc.id
  };

  const existingInvitee = invitees.find(theInvitee => {
    return (
      theInvitee.email === invitee.email &&
      theInvitee.groupId === invitee.groupId
    );
  });

  console.log({ change, existingInvitee });

  if (change.type === 'added') {
    if (existingInvitee) {
      yield put(actions.setGroupInvitee({ invitee }));
    } else {
      yield put(actions.addGroupInvitee({ invitee }));
    }
  }
  if (change.type === 'modified') {
    yield put(actions.setGroupInvitee({ invitee }));
  }
  if (change.type === 'removed') {
    if (existingInvitee) {
      // TODO: User feedback
    }
    yield put(actions.removeGroupInvitee({ id: invitee.id }));
  }
}

function* handleInviteGroupUser(
  action: Action & { payload: actions.IInviteGroupUserPayload }
) {
  yield call(console.log, 'inviting');
  const store: RootState = yield select();
  const token = yield store.auth.user!.getIdToken();
  const brandId = store.brand.brand!.id;

  yield put(
    actions.addGroupInvitee({
      invitee: {
        id: +new Date() + '',
        email: action.payload.email,
        state: InviteState.CREATING,
        groupId: action.payload.groupId,
        brandId,
        status: InviteStatus.PENDING,
        tier: action.payload.isTier3 ? AuthTier.TIER3 : AuthTier.TIER4,
        createdAt: Math.floor(Date.now() / 1000)
      }
    })
  );
  try {
    yield fetch(functionsEndpoint + '/inviteGroupUser', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        brand: brandId,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        groupId: action.payload.groupId,
        email: action.payload.email,
        name: action.payload.name,
        isTier3: action.payload.isTier3
      })
    });
  } catch (e) {
    console.log({ e });
  }
}

function* watchInviteGroupUser() {
  yield takeEvery(actions.inviteGroupUser, handleInviteGroupUser);
}

function* handleWatchInvitees() {
  let changesChannel!: EventChannel<
    firebase.firestore.DocumentChange | { type: 'START' }
  >;
  try {
    let store: RootState = yield select();
    const groups: IGroup[] = store.groups.groups.groups || [];

    const db = firebase.firestore();

    const queryBase = db
      .collection(Collection.INVITES)
      .where('brandId', '==', store.brand.brand!.id);

    const inviteesChanges = () =>
      eventChannel<firebase.firestore.DocumentChange | { type: 'START' }>(
        emit => {
          let init = false;
          const handleSnapshot = (
            snapshot: firebase.firestore.QuerySnapshot
          ) => {
            if (!init) {
              init = true;
              emit({ type: 'START' });
            }
            snapshot.docChanges().map(emit);
          };
          const listeners = groups.reduce<Array<() => void>>((all, current) => {
            const tier3Listener = queryBase
              .where('groupId', '==', current.id)
              .where('tier', '==', AuthTier.TIER3)
              .onSnapshot(handleSnapshot);
            const tier4Listener = queryBase
              .where('groupId', '==', current.id)
              .where('tier', '==', AuthTier.TIER4)
              .onSnapshot(handleSnapshot);
            return [...all, tier3Listener, tier4Listener];
          }, []);
          return () => {
            listeners.map(unsubscribe => unsubscribe());
          };
        }
      );
    changesChannel = inviteesChanges();
    while (true) {
      const doc = yield take(changesChannel);
      yield handleInviteeDocChanges(doc);
    }
  } finally {
    if (yield cancelled()) {
      if (changesChannel) {
        yield changesChannel.close();
      }
    }
  }
}

function* watchGroupsInviteesRequest() {
  while (yield take(actions.watchGroupInvitees)) {
    const inviteesWatcher = yield fork(handleWatchInvitees);
    yield take(actions.stopWatchingGroupInvitees);
    yield cancel(inviteesWatcher);
  }
}

function* deleteInvitee(
  action: Action & { payload: actions.IDeleteGroupInviteePayload }
) {
  const store: RootState = yield select();
  const invitees: Invitee[] = store.groups.invitees.invitees!;

  const existingInvitee = invitees.find(
    invitee => invitee.id === action.payload.id
  );
  if (existingInvitee) {
    const newInvitee: Invitee = {
      ...existingInvitee,
      state: InviteState.DELETING
    };
    yield put(actions.setGroupInvitee({ invitee: newInvitee }));
    const token = yield store.auth.user!.getIdToken();

    console.log({ existingInvitee });

    const response: Response = yield fetch(
      functionsEndpoint + '/deleteGroupInvite',
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
          brand: store.brand.brand!.id,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          groupId: existingInvitee.groupId,
          inviteId: existingInvitee.id
        })
      }
    );
    if (response.status !== 200) {
      yield put(actions.setGroupInvitee({ invitee: existingInvitee }));
    }
  }
}

function* watchDeleteInvitee() {
  yield takeEvery(actions.deleteGroupInvitee, deleteInvitee);
}

function* handleDeleteGroupUser(
  action: Action & { payload: IDeleteGroupUserPayload }
) {
  const store: RootState = yield select();
  const users: IUser[] = store.users.users.users!;

  const existingUser = users.find(invitee => invitee.uid === action.payload.id);
  if (existingUser) {
    const newUser: IUser = {
      ...existingUser,
      state: UserState.DELETING
    };
    yield put(usersActions.setUser({ user: newUser }));
    const token = yield store.auth.user!.getIdToken();

    console.log({ existingInvitee: existingUser });

    const response: Response = yield fetch(
      functionsEndpoint + '/deleteGroupUser',
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
          brand: store.brand.brand!.id,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userUid: action.payload.id,
          groupId: action.payload.groupId
        })
      }
    );
    if (response.status !== 200) {
      yield put(usersActions.setUser({ user: existingUser }));
    }
  }
}

function* watchDeleteUser() {
  yield takeEvery(actions.deleteGroupUser, handleDeleteGroupUser);
}

function* handleUpdateGroup(group: IGroup) {
  const state: RootState = yield select();
  const groups = state.groups.groups.groups!;
  const oldGroup = groups.find(theGroup => theGroup.id === group.id)!;

  const newGroup: IGroup = {
    ...group,
    state: GroupState.UPDATING
  };

  yield put(actions.setGroup({ group: newGroup }));
  try {
    const token = yield state.auth.user!.getIdToken();
    const brandId = state.brand.brand!.id;

    const result: Response = yield fetch(functionsEndpoint + '/updateGroup', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        brand: brandId,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...group
      })
    });

    switch (result.status) {
      case 200: {
        break;
      }
      default: {
        throw new Error('Request Failed');
      }
    }
  } catch (e) {
    console.log({ e });
    yield put(actions.setGroup({ group: oldGroup }));
    throw new Error('Failed to update post');
  }
}

function* handleUpdateGroupDetails(
  action: Action & { payload: actions.IUpdateGroupDetailsPayload }
) {
  const state: RootState = yield select();
  const {
    id,
    name,
    description,
    publicJoin,
    public: isPublic,
    tier3PostOnly
  } = action.payload.group;
  const groups = state.groups.groups.groups!;
  const group = groups.find(theGroup => theGroup.id === id);
  if (group) {
    const newGroup: IGroup = {
      ...group,
      name: name || '',
      description: description || '',
      publicJoin: !!publicJoin,
      public: !!isPublic,
      tier3PostOnly: !!tier3PostOnly
    };
    try {
      yield handleUpdateGroup(newGroup);
      NotificationManager.success('Successfully updated group');
    } catch (e) {
      NotificationManager.error('Failed to update group');
    }
  }
}

function* handleSetGroupActiveState(
  action: Action & { payload: actions.ISetGroupActiveStatePayload }
) {
  const state: RootState = yield select();
  const { id, active } = action.payload;
  const groups = state.groups.groups.groups!;
  const group = groups.find(theGroup => theGroup.id === id);
  if (group) {
    const newGroup: IGroup = {
      ...group,
      active
    };
    try {
      yield handleUpdateGroup(newGroup);
      NotificationManager.success(
        `Successfully ${active ? 'enabled' : 'disabled'} group`
      );
    } catch (e) {
      NotificationManager.error(
        `Failed to ${active ? 'enable' : 'disable'} group`
      );
    }
  }
}

function* watchSetGroupActiveState() {
  yield takeEvery(actions.setGroupActiveState, handleSetGroupActiveState);
}

function* watchUpdateGroupDetails() {
  yield takeEvery(actions.updateGroupDetails, handleUpdateGroupDetails);
}

function* handleDeleteGroup(
  action: Action & { payload: actions.IDeleteGroupPayload }
) {
  const state: RootState = yield select();
  const { id } = action.payload;
  const groups = state.groups.groups.groups!;
  const group = groups.find(thePost => thePost.id === id);
  if (group) {
    const newGroup: IGroup = {
      ...group,
      state: GroupState.DELETING
    };
    yield put(actions.setGroup({ group: newGroup }));
    try {
      const token = yield state.auth.user!.getIdToken();
      const brandId = state.brand.brand!.id;

      const result: Response = yield fetch(functionsEndpoint + '/deleteGroup', {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
          brand: brandId,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id
        })
      });

      switch (result.status) {
        case 200: {
          break;
        }
        default: {
          throw new Error('Request Failed');
        }
      }
      NotificationManager.success('Successfully deleted group');
    } catch (e) {
      console.log({ e });
      NotificationManager.error('Failed to delete group');
      yield put(actions.setGroup({ group: group }));
    }
  }
}

function* watchDeleteGroup() {
  yield takeEvery(actions.deleteGroup, handleDeleteGroup);
}

export default function*() {
  yield all([
    watchCreateGroup(),
    watchGroupsRequest(),
    watchInviteGroupUser(),
    watchGroupsInviteesRequest(),
    watchDeleteInvitee(),
    watchDeleteUser(),
    watchUpdateGroupDetails(),
    watchSetGroupActiveState(),
    watchDeleteGroup()
  ]);
}
