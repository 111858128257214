import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  createPost,
  deletePost,
  ICreatePostPayload,
  IDeletePostPayload,
  ISetPostActiveStatePayload,
  IUpdatePostContentPayload,
  setPostActiveState,
  updatePostContent,
} from '../store/modules/posts/actions';
import { IPostsState } from '../store/modules/posts/reducer';
import { RootState } from '../types/store';

const mapStateToProps = (state: RootState) => ({
  posts: state.posts
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createPost,
      updatePostContent,
      setPostActiveState,
      deletePost
    },
    dispatch
  );

interface IPostsReduxState {
  posts: IPostsState;
}

interface IPostsDispatch {
  createPost: (payload: ICreatePostPayload) => void;
  updatePostContent: (payload: IUpdatePostContentPayload) => void;
  setPostActiveState: (payload: ISetPostActiveStatePayload) => void;
  deletePost: (payload: IDeletePostPayload) => void;
}

export type PostsProps = IPostsReduxState & IPostsDispatch;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
