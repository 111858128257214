import { createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';
import { IGroup } from '../../types/groups';
import GroupListItem from './GroupListItem';

interface IProps {
  filterText?: string;
  scrollRef: React.RefObject<HTMLDivElement>;
  count: number;
  incrementCount: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    subheader: {
      fontSize: '1rem'
    }
  });

type Props = GroupsProps & IProps & WithStyles<typeof styles>;

const GroupsList: React.FC<Props> = props => {
  const { count, incrementCount } = props;
  const groups = props.groups.groups;
  if (!groups.loaded || !groups.groups) {
    return (
      <Centerer style={{ height: 'calc(100% - 200px)' }}>
        <CircularProgress />
      </Centerer>
    );
  }

  type GroupFilter = (group: IGroup) => boolean;
  const filters: GroupFilter[] = [];

  filters.push(group => !group.isDefaultGroup);

  if (props.filterText) {
    filters.push(group =>
      group.name.toLowerCase().includes(props.filterText!.toLowerCase())
    );
  }

  const filteredGroups = filters.reduce<IGroup[]>((acc, curr) => {
    return acc.filter(curr);
  }, groups.groups);

  const slicedGroups = filteredGroups.slice(0, count);

  return (
    <React.Fragment>
      {slicedGroups.length > 0 ? (
        <List
          subheader={
            <ListSubheader
              style={{ position: 'static' }}
              classes={{ root: props.classes.subheader }}
            >
              Groups
            </ListSubheader>
          }
        >
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={incrementCount}
            hasMore={true || false}
            getScrollParent={() => props.scrollRef.current || null}
            useWindow={false}
          >
            {slicedGroups.map((group, i) => (
              <GroupListItem key={group.id + '' + i} item={group} />
            ))}
          </InfiniteScroll>
        </List>
      ) : (
        <Centerer>
          <Typography>No groups found</Typography>
        </Centerer>
      )}
    </React.Fragment>
  );
};

const Centerer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withStyles(styles)(withGroupsState(GroupsList));
