import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';
import withJoinRequestsState, { JoinRequestsProps } from '../../enhancers/withJoinRequestsState';
import withUsersState, { UsersProps } from '../../enhancers/withUsersState';
import { IJoinRequest } from '../../types/joinRequest';
import JoinRequestListItem from './JoinRequestListItem';

interface IProps {
  filterText?: string;
  scrollRef: React.RefObject<HTMLDivElement>;
  count: number;
  incrementCount: () => void;
  groupId: string;
}

const styles = (theme: Theme) =>
  createStyles({
    list: {
      padding: 16
    },
    subheader: {
      fontSize: '1rem'
    }
  });

type Props = GroupsProps &
  IProps &
  JoinRequestsProps &
  UsersProps &
  WithStyles<typeof styles>;

const JoinRequestsList: React.FC<Props> = props => {
  const { count, incrementCount } = props;
  const joinRequests = props.joinRequests.joinRequests;
  const { groups } = props.groups;
  const { users } = props.users;
  if (
    !joinRequests.loaded ||
    !joinRequests.joinRequests ||
    !groups.loaded ||
    !groups.groups ||
    !users.loaded ||
    !users.users
  ) {
    return (
      <Centerer>
        <CircularProgress />
      </Centerer>
    );
  }

  type JoinRequestFilter = (joinRequest: IJoinRequest) => boolean;
  const filters: JoinRequestFilter[] = [];
  filters.push(joinRequest => joinRequest.groupId === props.groupId);

  if (props.filterText) {
    filters.push(joinRequest => {
      const user = users.users!.find(
        theUser => theUser.uid === joinRequest.userUid
      );
      if (!user) {
        return false;
      }
      return (
        user.firstName
          .toLowerCase()
          .includes(props.filterText!.toLowerCase()) ||
        user.lastName.toLowerCase().includes(props.filterText!.toLowerCase())
      );
    });
  }

  const filteredJoinRequests = filters.reduce<IJoinRequest[]>((acc, curr) => {
    return acc.filter(curr);
  }, joinRequests.joinRequests);

  const slicedJoinRequests = filteredJoinRequests.slice(0, count);

  return (
    <React.Fragment>
      {slicedJoinRequests.length > 0 ? (
        <List
          classes={{ root: props.classes.list }}
          subheader={
            <ListSubheader
              classes={{ root: props.classes.subheader }}
              style={{ position: 'static' }}
              component="div"
            >
              Requests
            </ListSubheader>
          }
        >
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={incrementCount}
            hasMore={true || false}
            getScrollParent={() => props.scrollRef.current || null}
            useWindow={false}
          >
            {slicedJoinRequests.map((joinRequest, i) => (
              <JoinRequestListItem
                key={joinRequest.id + '' + i}
                item={joinRequest}
              />
            ))}
          </InfiniteScroll>
        </List>
      ) : (
        <Centerer>
          <Typography>No requests found</Typography>
        </Centerer>
      )}
    </React.Fragment>
  );
};

const Centerer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withStyles(styles)(
  withUsersState(withJoinRequestsState(withGroupsState(JoinRequestsList)))
);
