import { InjectedFormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { AuthProps } from '../../enhancers/withAuthState';

type FormValues = {
  code: string;
};

type FormProps = AuthProps;

export type VerifyCodeFormProps = InjectedFormikProps<FormProps, FormValues> &
  Partial<FormValues>;

const initialValues = {
  code: ''
};

export default withFormik<FormProps, FormValues>({
  handleSubmit: (values, { props, setSubmitting, setError, resetForm }) => {
    setSubmitting(true);
    try {
      props.verifyCode({ code: values.code });
    } catch (e) {
      console.log({ e });
    }
  },
  mapPropsToValues: () => ({ ...initialValues }),
  validationSchema: Yup.object().shape({
    code: Yup.string()
      // .matches(/[0-9]/i, 'Must be a number')
      // .min(9, 'Too smol')
      // .max(9, 'Too beeg')
      .required()
  })
});
