import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import * as React from 'react';
import Markdown from 'react-markdown';
import styled from 'styled-components';

import { IPost } from '../../types/post';

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative'
    },
    flex: {
      flex: 1
    },
    dateText: {
      fontWeight: 400,
      fontSize: 12,
      color: '#0085eb',
      padding: '5px 0'
    },
    title: {
      fontWeight: 700,
      color: '#707070',
      fontSize: 20
    }
  });

interface IProps {
  post: Partial<IPost>;
  open: boolean;
  setOpen: (open: boolean) => void;
}

type Props = IProps & WithStyles<typeof styles>;

const PostPreview: React.FC<Props> = props => {
  const { classes } = props;
  const date = new Date();
  const displayDate = format(date, 'dd-MM-YYYY', {
    awareOfUnicodeTokens: true
  });
  return (
    <Dialog fullScreen open={props.open} onClose={() => props.setOpen(false)}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => props.setOpen(false)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Preview
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        {props.post.image && <Image imgSrc={props.post.image} />}
        <Content>
          <Typography classes={{ root: classes.dateText }}>
            {displayDate}
          </Typography>
          <Typography classes={{ root: classes.title }}>
            {props.post.title}
          </Typography>
          <Line />
          <Markdown className="preview-markdown" source={props.post.content} />
          {props.post.video && (
            <VideoContainer>
              <video width="300" controls>
                <source src={props.post.video} />
              </video>
            </VideoContainer>
          )}
        </Content>
      </Container>
      <style
        dangerouslySetInnerHTML={{
          __html: `.preview-markdown pre {
  background: #eee;
} .preview-markdown p, .preview-markdown h1, .preview-markdown h2, .preview-markdown h3, .preview-markdown h4, .preview-markdown h5, .preview-markdown h6{
  opacity: .6;
  font-weight: 300;
  line-height: 1.3;
}`
        }}
      />
    </Dialog>
  );
};

const VideoContainer = styled.div`
  text-align: center;
`;

const Content = styled.div`
  padding: 20px 10px;
`;

const Container = styled.div``;

const Image = styled.div<{ imgSrc: string }>`
  height: 180px;
  width: 100%;
  background: url(${p => p.imgSrc}) no-repeat center;
  background-size: cover;
`;

const Line = styled.div`
  width: 100%;
  background: #707070;
  height: 1px;
  margin: 16px auto;
  opacity: 0.17;
`;

export default withStyles(styles)(PostPreview);
