import * as React from 'react';
import styled from 'styled-components';

import withBrandState, { BrandProps } from '../../enhancers/withBrandState';
import VolunteerPreFooter from './VolunteerPreFooter';
import VolunteerPreHeader from './VolunteerPreHeader';

interface IProps {
  children: React.ReactNode;
}

type Props = IProps & BrandProps;

const VolunteerPreWrapper: React.FC<Props> = props => {
  return (
    <Container>
      <VolunteerPreHeader />
      <Center>{props.children}</Center>
      <VolunteerPreFooter />
    </Container>
  );
};

const Container = styled.div`
  display: 'flex';
  align-items: center;
  min-height: 100vh;
  padding: 10px;
  background-color: #0085eb;
  h4 {
    font-size: 21px;
  }
  @media (max-width: 750px) {
    width: 100%;
    p {
      font-size: 15px;
    }
  }
  p {
    color: grey;
  }
  h4 {
    color: #0085eb;
  }
  @media screen and (min-width: 1400px) {
    height: 1060px;
    max-width: 1900px;
  }
  @media (max-width: 750px) {
    padding: 40px;
  }
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 60%;
  margin: 0px auto;
  /* margin-left: 20%; */
  background: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.06), 0 6px 12px rgba(0, 0, 0, 0.08);
  @media (max-width: 750px) {
    margin-left: 0;
    width: 100%;
  }
  @media screen and (min-width: 1400px) {
    width: 70%;
    margin-left: 15%;
    padding-top: 0px;
  }
`;

export default withBrandState(VolunteerPreWrapper);
