import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SearchField from '../../components/SearchField/SearchField';
import UsersList from '../../components/UsersList/UsersList';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import theme from '../../theme';

interface IProps {
  scrollRef: React.RefObject<HTMLDivElement>;
}

type Props = IProps;

const UsersUsers: React.FC<Props> = props => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [count, incrementCount, resetCount] = useInfiniteScroll();
  return (
    <React.Fragment>
      <SearchContainer>
        <SearchField
          value={searchTerm}
          onChange={value => {
            setSearchTerm(value);
            resetCount();
          }}
          placeholder="Search Users"
        />
      </SearchContainer>
      <UsersList
        count={count}
        incrementCount={incrementCount}
        filterText={searchTerm}
        scrollRef={props.scrollRef}
      />
      <FabContainer>
        <Fab
          component={props => (
            <Link {...props} to="/app/admin/users/invite">
              {props.children}
            </Link>
          )}
          color="secondary"
          aria-label="Add"
        >
          <AddIcon />
        </Fab>
      </FabContainer>
    </React.Fragment>
  );
};

const SearchContainer = styled.div`
  padding: 15px;
  background: rgba(0, 0, 0, 0.05);
`;

const FabContainer = styled.div`
  position: fixed;
  bottom: ${p => theme.spacing.unit * 2}px;
  right: ${p => theme.spacing.unit * 2}px;
`;

export default UsersUsers;
