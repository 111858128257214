import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  createPoll,
  deletePoll,
  ICreatePollPayload,
  IDeletePollPayload,
  ISetPollStatusPayload,
  IUpdatePollDatePayload,
  setPollStatus,
  updatePollDate,
} from '../store/modules/polls/actions';
import { IPollsState } from '../store/modules/polls/reducer';
import { RootState } from '../types/store';

const mapStateToProps = (state: RootState) => ({
  polls: state.polls
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deletePoll,
      setPollStatus,
      createPoll,
      updatePollDate
    },
    dispatch
  );

interface IPollsReduxState {
  polls: IPollsState;
}

interface IPollsDispatch {
  deletePoll: (payload: IDeletePollPayload) => void;
  setPollStatus: (payload: ISetPollStatusPayload) => void;
  createPoll: (payload: ICreatePollPayload) => void;
  updatePollDate: (payload: IUpdatePollDatePayload) => void;
}

export type PollsProps = IPollsReduxState & IPollsDispatch;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
