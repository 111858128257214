import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as React from 'react';

import withUiState, { UiProps } from '../../enhancers/withUiState';

type Props = UiProps;

const ConfirmationDialog: React.FC<Props> = props => {
  const handleClose = () => props.setDialogOpen({ open: false });
  const dialog = props.ui.dialog.dialog;
  if (!dialog) {
    return null;
  }
  return (
    <Dialog
      open={props.ui.dialog.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
      {dialog.description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialog.description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="default">
          {dialog.cancelText}
        </Button>
        <Button
          onClick={() => {
            dialog.action();
            handleClose();
          }}
          color={dialog.color}
          autoFocus
        >
          {dialog.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withUiState(ConfirmationDialog);
