import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PostsIcon from '@material-ui/icons/LibraryBooks';
import PeopleIcon from '@material-ui/icons/People';
import PollIcon from '@material-ui/icons/Poll';
import SettingsIcon from '@material-ui/icons/Settings';
import queryString from 'query-string';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import styled from 'styled-components';

import NotFound from '../../components/NotFound/NotFound';
import AuthGuard from '../../containers/AuthGuard/AuthGuard';
import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';
import withPostsState, { PostsProps } from '../../enhancers/withPostsState';
import BackScreen from '../../layouts/BackScreen/BackScreen';
import DefaultPage from '../../layouts/DefaultPage/DefaultPage';
import theme from '../../theme';
import { loggedInStates } from '../../types/auth';
import GroupInvites from './GroupInvites';
import GroupJoinRequests from './GroupJoinRequests';
import GroupPolls from './GroupPolls';
import GroupPosts from './GroupPosts';
import GroupSettings from './GroupSettings';
import GroupUsers from './GroupUsers';

const styles = (theme: Theme) =>
  createStyles({
    bottomBar: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%'
    }
  });

type Props = GroupsProps &
  RouteComponentProps<{ id: string; screen?: string; nestedScreen?: string }> &
  WithStyles<typeof styles> &
  PostsProps &
  AuthProps;

const Group: React.FC<Props> = props => {
  const { groups } = props.groups;

  if (!groups.loaded || !groups.groups) {
    return <CircularProgress />;
  }

  const group = groups.groups.find(group => group.id === props.match.params.id);

  if (!group) {
    return <NotFound />;
  }

  // Main Navigation
  const screens = ['posts', 'users', 'polls', 'settings'];
  const [screen, setScreen] = React.useState<number>(-1);
  React.useEffect(() => {
    const initScreen = props.match.params.screen;
    console.log({ initScreen });
    if (initScreen) {
      setScreen(screens.findIndex(theScreen => initScreen === theScreen));
    } else {
      setScreen(0);
    }
  }, []);

  React.useEffect(() => {
    console.log({ screen });
    if (screen > -1) {
      props.history.replace(
        `/app/admin/groups/${props.match.params.id}/${screens[screen]}`
      );
    }
  }, [screen]);

  // Users Navigation
  const usersScreens = ['users', 'invites', 'requests'];
  const pollsScreens = ['active', 'scheduled', 'past'];
  const [nestedScreen, setNestedScreen] = React.useState<number>(-1);

  const [navReady, setNavReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    const initScreen = props.match.params.nestedScreen;
    setNestedScreen(0);
    if (screen > -1 && !navReady) {
      console.log('readying');
      setNavReady(true);
      if (initScreen) {
        switch (screen) {
          case 1: {
            return setNestedScreen(
              usersScreens.findIndex(theScreen => initScreen === theScreen)
            );
          }
          case 2: {
            return setNestedScreen(
              pollsScreens.findIndex(theScreen => initScreen === theScreen)
            );
          }
        }
      } else {
        return setNestedScreen(0);
      }
    }
  }, [screen]);

  React.useEffect(() => {
    if (nestedScreen > -1) {
      if (screen === 1) {
        props.history.replace(
          `/app/admin/groups/${props.match.params.id}/users/${
            usersScreens[nestedScreen]
          }`
        );
      }

      if (screen === 2) {
        props.history.replace(
          `/app/admin/groups/${props.match.params.id}/polls/${
            pollsScreens[nestedScreen]
          }`
        );
      }
    }
  }, [screen, nestedScreen]);

  const [isApp, setIsApp] = React.useState<boolean>(false);
  React.useEffect(() => {
    const value = queryString.parse(window.location.search);
    setIsApp(!!value.app);
  }, []);

  const contentRef = React.useRef<HTMLDivElement>(null);

  console.log({ screenForRender: props.match.params.screen });

  const getTabs = () => {
    if (screen === 1) {
      return (
        <Tabs
          variant="fullWidth"
          value={nestedScreen}
          onChange={(e, v) => setNestedScreen(v)}
        >
          <Tab label="Users" />
          <Tab label="Invites" />
          <Tab label="Requests" />
        </Tabs>
      );
    }
    if (screen === 2) {
      return (
        <Tabs
          variant="fullWidth"
          value={nestedScreen}
          onChange={(e, v) => setNestedScreen(v)}
        >
          <Tab label="Active" />
          <Tab label="Scheduled" />
          <Tab label="Past" />
        </Tabs>
      );
    }
    return null;
  };

  let isTier3Or1 = false;
  let isTier1 = false;
  if (props.auth.userData) {
    const uid = props.auth.userData.uid;
    isTier1 = props.auth.userData.isTier1;
    isTier3Or1 = group.tier3Ids.includes(uid) || isTier1;
  }

  const Wrapper: React.FC = wrapperProps => {
    if (group.isDefaultGroup) {
      return (
        <DefaultPage title="Party News" tabs={getTabs()}>
          {wrapperProps.children}
        </DefaultPage>
      );
    }
    return (
      <BackScreen
        backTo={
          isApp
            ? `lookatyouparty://group/${props.match.params.id}`
            : '/app/admin/groups'
        }
        title={group.name}
        tabs={getTabs()}
      >
        {wrapperProps.children}
      </BackScreen>
    );
  };

  return (
    <AuthGuard allowedStates={loggedInStates}>
      <Wrapper>
        <Content ref={contentRef} extraPadding={screen === 1}>
          {isTier3Or1 ? (
            <Switch>
              <Route
                exact
                path="/app/admin/groups/:id/posts"
                component={(
                  routeProps: RouteComponentProps<{ id: string }>
                ) => <GroupPosts {...routeProps} scrollRef={contentRef} />}
              />
              {!group.isDefaultGroup && (
                <Route
                  path="/app/admin/groups/:id/users/"
                  exact
                  component={() => (
                    <Redirect
                      to={`/app/admin/groups/${
                        props.match.params.id
                      }/users/users`}
                    />
                  )}
                />
              )}
              {!group.isDefaultGroup && (
                <Route
                  exact
                  path="/app/admin/groups/:id/users/users"
                  component={(
                    routeProps: RouteComponentProps<{ id: string }>
                  ) => <GroupUsers {...routeProps} scrollRef={contentRef} />}
                />
              )}
              {!group.isDefaultGroup && (
                <Route
                  exact
                  path="/app/admin/groups/:id/users/invites"
                  component={(
                    routeProps: RouteComponentProps<{ id: string }>
                  ) => <GroupInvites {...routeProps} scrollRef={contentRef} />}
                />
              )}
              {!group.isDefaultGroup && (
                <Route
                  exact
                  path="/app/admin/groups/:id/users/requests"
                  component={(
                    routeProps: RouteComponentProps<{ id: string }>
                  ) => (
                    <GroupJoinRequests {...routeProps} scrollRef={contentRef} />
                  )}
                />
              )}
              <Route
                path="/app/admin/groups/:id/polls/"
                exact
                component={() => (
                  <Redirect
                    to={`/app/admin/groups/${
                      props.match.params.id
                    }/polls/active`}
                  />
                )}
              />
              <Route
                exact
                path="/app/admin/groups/:id/polls/active"
                component={(
                  routeProps: RouteComponentProps<{ id: string }>
                ) => (
                  <GroupPolls
                    {...routeProps}
                    scrollRef={contentRef}
                    filter="active"
                  />
                )}
              />
              <Route
                exact
                path="/app/admin/groups/:id/polls/scheduled"
                component={(
                  routeProps: RouteComponentProps<{ id: string }>
                ) => (
                  <GroupPolls
                    {...routeProps}
                    scrollRef={contentRef}
                    filter="scheduled"
                  />
                )}
              />
              <Route
                exact
                path="/app/admin/groups/:id/polls/past"
                component={(
                  routeProps: RouteComponentProps<{ id: string }>
                ) => (
                  <GroupPolls
                    {...routeProps}
                    scrollRef={contentRef}
                    filter="past"
                  />
                )}
              />
              {isTier1 && (
                <Route
                  exact
                  path="/app/admin/groups/:id/settings"
                  component={GroupSettings}
                />
              )}

              {typeof props.match.params.screen !== 'undefined' && (
                <Route component={NotFound} />
              )}
            </Switch>
          ) : (
            <Route
              exact
              path="/app/admin/groups/:id/posts"
              component={(routeProps: RouteComponentProps<{ id: string }>) => (
                <GroupPosts {...routeProps} scrollRef={contentRef} />
              )}
            />
          )}
        </Content>
        {isTier3Or1 && (
          <BottomNavigation
            value={screen > -1 ? screen : 0}
            onChange={(event, newValue) => {
              setScreen(newValue);
            }}
            showLabels
            classes={{
              root: props.classes.bottomBar
            }}
          >
            <BottomNavigationAction label="Posts" icon={<PostsIcon />} />
            {!group.isDefaultGroup && (
              <BottomNavigationAction label="Users" icon={<PeopleIcon />} />
            )}

            <BottomNavigationAction label="Polls" icon={<PollIcon />} />
            {isTier1 && !group.isDefaultGroup && (
              <BottomNavigationAction
                label="Settings"
                icon={<SettingsIcon />}
              />
            )}
          </BottomNavigation>
        )}
      </Wrapper>
    </AuthGuard>
  );
};

const Content = styled.div<{ extraPadding?: boolean }>`
  padding-bottom: 56px;
  overflow-y: auto;
  height: calc(100vh - ${p => 56 + (p.extraPadding ? 48 : 0)}px);
  display: flex;
  flex-direction: column;
`;

export default withStyles(styles)(
  withAuthState(withPostsState(withGroupsState(Group)))
);
