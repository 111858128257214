export interface IBrand {
  alias: string;
  banner: string;
  defaultGroupId: string;
  description: string;
  name: string;
  subdomain: string;
  thumbnail: string;
  id: string;
  formLogo: string;
  formFlag: string;
  formLogoMobile: string;
  primaryColor: string;
  accentColor: string;
}

export enum BrandError {
  NO_SUBDOMAIN,
  NOT_FOUND
}

export interface IBrandMeta {
  brandId: string;
  groupIds: string[];
  adminIds: string[];
}
