export enum PostState {
  CREATING,
  UPDATING,
  DELETING
}
export interface IPost {
  id: string;
  clientId?: string;
  brandId: string;
  title: string;
  content: string;
  image: string;
  video?: string;
  active: boolean;
  createdAt: number;
  groupId: string;
  isDefaultGroup?: boolean;
  updatedAt: number;
  state?: PostState;
  authorId: string;
}

export interface IPostCreationPayload {
  title: string;
  content: string;
  image: string;
  video?: string;
  groupId: string;
}

export interface IPostUpdatePayload {
  id: string;
  title: string;
  content: string;
  image: string;
  video?: string;
}
