import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { BrandError, IBrand } from '../../../types/brand';
import * as actions from './actions';

export interface IBrandState {
  readonly brand: IBrand | null;
  readonly error: BrandError | null;
  readonly loaded: boolean;
}

type BrandAction = ActionType<typeof actions>;

export const initialState: IBrandState = {
  brand: null,
  error: null,
  loaded: false
};

export default combineReducers<IBrandState, BrandAction>({
  brand: (state = initialState.brand, action) => {
    switch (action.type) {
      case getType(actions.setBrand): {
        return action.payload.brand;
      }
      default: {
        return state;
      }
    }
  },
  error: (state = initialState.error, action) => {
    switch (action.type) {
      case getType(actions.setBrandError): {
        return action.payload.error;
      }
      default: {
        return state;
      }
    }
  },
  loaded: (state = initialState.loaded, action) => {
    switch (action.type) {
      case getType(actions.setBrandError): {
        return true;
      }
      case getType(actions.setBrand): {
        return true;
      }
      default: {
        return state;
      }
    }
  }
});
