import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import format from 'date-fns/format';
import * as React from 'react';
import styled from 'styled-components';

import withJoinRequestsState, { JoinRequestsProps } from '../../enhancers/withJoinRequestsState';
import withUiState, { UiProps } from '../../enhancers/withUiState';
import withUsersState, { UsersProps } from '../../enhancers/withUsersState';
import { IJoinRequest } from '../../types/joinRequest';

interface IProps {
  item: IJoinRequest;
}

const styles = (theme: Theme) =>
  createStyles({
    details: {
      flexDirection: 'column'
    }
  });

type Props = IProps &
  UsersProps &
  WithStyles<typeof styles> &
  JoinRequestsProps &
  UiProps;

const JoinRequestListItem: React.FC<Props> = props => {
  const { users } = props.users;
  if (!users.users || !users.loaded) {
    return null;
  }
  const user = users.users.find(theUser => theUser.uid === props.item.userUid);
  if (!user) {
    return null;
  }
  const displayDate = format(
    new Date(props.item.createdAt * 1000),
    'dd-MM-YYYY',
    {
      awareOfUnicodeTokens: true
    }
  );
  return (
    <ExpansionPanel disabled={typeof props.item.state === 'number'}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {user.firstName} {user.lastName}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: props.classes.details }}>
        <Line style={{ marginTop: 0 }} />
        <Typography>
          <strong>Request Date:</strong> {displayDate}
        </Typography>
        <Line />
        <Actions>
          <ButtonCont>
            <Button
              onClick={() =>
                props.confirmAction({
                  action: () => props.acceptJoinRequest({ id: props.item.id })
                })
              }
              color="primary"
              variant="contained"
              fullWidth
              disabled={typeof props.item.state === 'number'}
            >
              Approve
            </Button>
          </ButtonCont>
          <ButtonCont>
            <Button
              disabled={typeof props.item.state === 'number'}
              color="secondary"
              fullWidth
              onClick={() =>
                props.confirmAction({
                  action: () => props.deleteJoinRequest({ id: props.item.id })
                })
              }
            >
              Reject
            </Button>
          </ButtonCont>
        </Actions>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const ButtonCont = styled.div`
  flex: 0 0 50%;
`;

const Actions = styled.div`
  display: flex;
  width: 100%;
`;

const Line = styled.div`
  width: 100%;
  background: #707070;
  height: 1px;
  margin: 16px auto;
  opacity: 0.17;
`;

export default withStyles(styles)(
  withUiState(withJoinRequestsState(withUsersState(JoinRequestListItem)))
);
