import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

interface IProps {
  to: string;
}

type Props = RouteComponentProps<any> & IProps;

class ReplaceRedirect extends React.Component<Props> {
  componentDidMount() {
    this.props.history.replace(this.props.to);
  }

  render() {
    return null;
  }
}

export default withRouter(ReplaceRedirect);
