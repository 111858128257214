import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import styled from 'styled-components';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withUiState, { UiProps } from '../../enhancers/withUiState';
import { Invitee } from '../../types/users';

interface IProps {
  invite: Invitee;
}

type Props = AuthProps & IProps & UiProps;

const LoggedInAcceptInvitationForm: React.FC<Props> = props => {
  const [loading, setLoading] = React.useState<boolean>(false);
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Container>
      <Button
        onClick={() => {
          props.confirmAction({
            action: () => {
              props.loggedInAcceptInvite({ id: props.invite.id });
              setLoading(true);
            },
            title: 'Accept this invitation?'
          });
        }}
      >
        Accept
      </Button>
      <Button
        onClick={() => {
          props.confirmAction({
            action: () => {
              props.declineInvite({ id: props.invite.id });
              setLoading(true);
            },
            title: 'Decline this invitation'
          });
        }}
      >
        Decline
      </Button>
    </Container>
  );
};

const Container = styled.div``;

export default withUiState(withAuthState(LoggedInAcceptInvitationForm));
