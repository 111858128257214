import { connect } from 'react-redux';

import { IBrandState } from '../store/modules/brand/reducer';
import { RootState } from '../types/store';

const mapStateToProps = (state: RootState) => ({
  brand: state.brand
});

export type BrandProps = { brand: IBrandState };

export default connect(
  mapStateToProps,
  null
);
