import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import withRoutingState, { RoutingProps } from '../../enhancers/withRoutingState';

type Props = RoutingProps & RouteComponentProps<any>;

class RedirectService extends React.Component<Props> {
  public componentDidMount() {
    this.redirect();
  }
  public componentDidUpdate() {
    this.redirect();
  }
  public redirect = () => {
    if (this.props.redirectTo) {
      this.props.history.push(this.props.redirectTo);
      this.props.clearRedirect();
    }
  };
  public render() {
    return <React.Fragment />;
  }
}

export default withRouter(withRoutingState(RedirectService));
