import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker } from 'material-ui-pickers';
import * as React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import withPollsState, { PollsProps } from '../../enhancers/withPollsState';
import { IPoll } from '../../types/poll';
import getDateInvalidator from '../../utils/getDateInvalidator';
import withNewPollForm, { MAX_POLL_OPTIONS, NewPollFormProps } from './withNewPollForm';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing.unit * 2
    },
    button: {
      marginTop: theme.spacing.unit * 2
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    cta: {
      textTransform: 'initial',
      width: '100%',
      fontWeight: 400,
      fontSize: 18,
      marginTop: 26
    }
  });

type Props = WithStyles<typeof styles> &
  NewPollFormProps &
  PollsProps & { groupId: string };

const NewPollForm: React.FC<Props> = props => {
  const [optionsCount, setOptionsCount] = React.useState<number>(2);

  const getOptionsElement = () => {
    const options: React.ReactNode[] = [];
    for (let i = 0; i < optionsCount; i++) {
      const getIsError = () => {
        if (typeof props.errors.options === 'string') {
          if (props.touched.options && i === 0) {
            return true;
          }
        }
        if (props.errors.options && typeof props.errors.options === 'object') {
          return (
            typeof props.touched.options !== 'undefined' &&
            props.touched.options[i] &&
            typeof props.errors.options !== 'undefined' &&
            !!props.errors.options[i]
          );
        }
        return false;
      };

      const getErrorText = () => {
        if (typeof props.errors.options === 'string') {
          if (props.touched.options && i === 0) {
            return props.errors.options;
          }
        }
        if (props.errors.options && typeof props.errors.options === 'object') {
          return typeof props.touched.options !== 'undefined' &&
            props.touched.options[i] &&
            typeof props.errors.options !== 'undefined'
            ? props.errors.options[i]
            : '';
        }
        return '';
      };

      options.push(
        <TextField
          key={i}
          label={`Option ${i + 1}`}
          value={props.values.options[i] || ''}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          name={`options[${i}]`}
          fullWidth
          variant="outlined"
          error={getIsError()}
          helperText={getErrorText()}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Delete"
                  disabled={optionsCount < 3 || i < optionsCount - 1}
                  onClick={() => {
                    setOptionsCount(optionsCount - 1);
                    const newOptions = [...props.values.options];
                    newOptions.pop();
                    props.setFieldValue('options', newOptions);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      );
    }

    return (
      <div>
        {options}
        <Button
          onClick={() => {
            setOptionsCount(optionsCount + 1);
            const newOptions = [...props.values.options, ''];
            props.setFieldValue('options', newOptions);
          }}
          fullWidth
          disabled={optionsCount >= MAX_POLL_OPTIONS}
        >
          Add option
        </Button>
      </div>
    );
  };

  console.log({ pollProps: props });

  let now = new Date();
  const { polls } = props.polls;

  let pollsArray: IPoll[] = [];

  if (polls.loaded && polls.polls) {
    pollsArray = [...polls.polls].filter(
      poll => poll.groupId === props.groupId
    );
  }

  const invalidator = getDateInvalidator(pollsArray, props.values.startDate);

  const theForm = (
    <form onSubmit={props.handleSubmit}>
      <DateSection>
        <DatePicker
          label="Start Date"
          value={props.values.startDate}
          minDate={new Date()}
          onChange={props.handleChange('startDate')}
          animateYearScrolling
          shouldDisableDate={invalidator('start')}
          name="startDate"
          onBlur={props.handleBlur('startDate')}
          error={props.touched.startDate && !!props.errors.startDate}
          helperText={props.touched.startDate ? props.errors.startDate : ''}
        />
        <DatePicker
          label="End Date"
          value={props.values.endDate}
          minDate={props.values.startDate ? props.values.startDate : now}
          onChange={props.handleChange('endDate')}
          animateYearScrolling
          shouldDisableDate={invalidator('end')}
          name="endDate"
          onBlur={props.handleBlur('endDate')}
          error={props.touched.endDate && !!props.errors.endDate}
          helperText={props.touched.endDate ? props.errors.endDate : ''}
        />
      </DateSection>
      <TextField
        label="Title"
        value={props.values.title}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        name="title"
        fullWidth
        variant="outlined"
        error={props.touched.title && !!props.errors.title}
        helperText={props.touched.title ? props.errors.title : ''}
        margin="normal"
      />
      <TextField
        multiline
        name="description"
        value={props.values.description}
        label="Description"
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        fullWidth
        rows={8}
        variant="outlined"
        error={props.touched.description && !!props.errors.description}
        helperText={props.touched.description ? props.errors.description : ''}
        margin="normal"
      />
      {getOptionsElement()}
      <Fab
        disabled={props.isSubmitting || !props.isValid}
        onClick={props.submitForm}
        variant="extended"
        color="primary"
        aria-label="Add"
        classes={{
          root: props.classes.cta
        }}
      >
        Publish
      </Fab>
    </form>
  );

  return theForm;
};

const DateSection = styled.div`
  display: flex;
`;

export default withRouter(
  withPollsState(withNewPollForm(withStyles(styles)(NewPollForm)))
);
