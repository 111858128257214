import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import removeMd from 'remove-markdown';
import styled from 'styled-components';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withBrandState, { BrandProps } from '../../enhancers/withBrandState';
import withPostsState, { PostsProps } from '../../enhancers/withPostsState';
import withUiState, { UiProps } from '../../enhancers/withUiState';
import { IPost } from '../../types/post';
import elipsify from '../../utils/elipsify';

interface IProps {
  post: IPost;
  groupId: string;
}

type Props = IProps &
  PostsProps &
  BrandProps &
  UiProps &
  RouteComponentProps &
  AuthProps;

const Post: React.FC<Props> = props => {
  const { post } = props;
  const togglePostActiveState = () => {
    props.setPostActiveState({ id: post.id, active: !post.active });
  };

  const { userData } = props.auth;
  const { brand } = props.brand;

  if (!userData || !brand) {
    return null;
  }

  const isTier3 = userData.tier3Ids.includes(props.groupId);
  const { isTier1 } = userData;

  const isDefaultGroup = post.groupId === brand.defaultGroupId;

  const [anchorEl, setAnchorEl] = React.useState<
    EventTarget & HTMLElement | null
  >(null);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const t = event.currentTarget;
    setAnchorEl(t);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <Card>
        <CardHeader
          title={post.title}
          subheader={elipsify(removeMd(post.content), 88)}
          action={
            <React.Fragment>
              <IconButton
                onClick={handleClick}
                disabled={typeof props.post.state === 'number'}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {isTier1 && !isDefaultGroup && (
                  <MenuItem
                    onClick={() => {
                      props.history.push(
                        `/app/admin/groups/${
                          props.brand.brand!.defaultGroupId
                        }/posts/new/${post.id}`
                      );
                      handleClose();
                    }}
                  >
                    Promote
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    props.history.push(
                      `/app/admin/groups/${props.groupId}/posts/${post.id}`
                    );
                    handleClose();
                  }}
                >
                  Edit
                </MenuItem>
                {(isTier1 || isTier3) && (
                  <MenuItem
                    onClick={() => {
                      props.confirmAction({
                        title: post.active ? 'Disable Post?' : 'Enable Post?',
                        confirmText: post.active ? 'Disable' : 'Enable',
                        description: post.active
                          ? 'Please note disabled posts will only be available here and not viewable by other group members.'
                          : 'Please note that by enabling this post, it will be viewable by group members.',
                        action: () => {
                          togglePostActiveState();
                          handleClose();
                        }
                      });
                    }}
                  >
                    {post.active ? 'Disable' : 'Enable'}
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    props.confirmAction({
                      title: 'Are you sure you want to delete this post?',
                      description: 'This action cannot be undone.',
                      confirmText: 'Delete',
                      color: 'secondary',
                      action: () => {
                        props.deletePost({ id: post.id });
                        handleClose();
                      }
                    });
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          }
        />
        <CardMedia
          style={{ height: 140 }}
          image={post.image}
          title={post.title}
        />
      </Card>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

export default withRouter(
  withAuthState(withUiState(withBrandState(withPostsState(Post))))
);
