import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import * as actions from './actions';

export interface IRedirectState {
  readonly to: string;
}

export type RedirectAction = ActionType<typeof actions>;

const initialState: IRedirectState = {
  to: ''
};

export default combineReducers<IRedirectState, RedirectAction>({
  to: (state = initialState.to, action) => {
    switch (action.type) {
      case getType(actions.setRedirect): {
        return action.payload.to;
      }
      case getType(actions.clearRedirect): {
        return '';
      }
      default: {
        return state;
      }
    }
  }
});
