import * as React from 'react';
import styled from 'styled-components';

import AppBar from '../../components/AppBar/AppBar';
import Drawer from '../../components/Drawer/Drawer';

type Props = {
  tabs?: React.ReactNode;
  title?: string;
};

const DefaultPage: React.SFC<Props> = props => (
  <Container>
    <AppBar title={props.title} tabs={props.tabs} />
    <Drawer />
    <Content tabs={!!props.tabs}>{props.children}</Content>
  </Container>
);

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<{ tabs?: boolean }>`
  padding-top: ${p => 56 + (p.tabs ? 48 : 0)}px;
`;
export default DefaultPage;
