import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  createGroupRequest,
  ICreateGroupRequestPayload,
  ISetGroupRequestErrorPayload,
  setGroupRequestError,
} from '../store/modules/groupRequests/actions';
import { IGroupRequestsState as IGroupRequestsReduxState } from '../store/modules/groupRequests/reducer';
import { RootState } from '../types/store';

const mapStateToProps = (state: RootState) => ({
  groupRequests: state.groupRequests
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createGroupRequest,
      setGroupRequestError
    },
    dispatch
  );

interface IGroupRequestsState {
  groupRequests: IGroupRequestsReduxState;
}

interface IGroupRequestsDispatch {
  createGroupRequest: (payload: ICreateGroupRequestPayload) => void;
  setGroupRequestError: (payload: ISetGroupRequestErrorPayload) => void;
}

export type GroupRequestsProps = IGroupRequestsState & IGroupRequestsDispatch;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
