import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  image: string;
  text: React.ReactNode;
}

const styles = (theme: Theme) =>
  createStyles({
    noPostsText: {
      color: '#707070',
      opacity: 0.3,
      margin: '30px 0',
      fontSize: 12,
      textAlign: 'center'
    }
  });

type Props = IProps & WithStyles<typeof styles>;

const EmptyState: React.FC<Props> = props => {
  return (
    <Centerer>
      <img src={props.image} style={{ width: 80 }} />
      <Typography classes={{ root: props.classes.noPostsText }}>
        {props.text}
      </Typography>
    </Centerer>
  );
};

const Centerer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

export default withStyles(styles)(EmptyState);
