export enum PollState {
  CREATING,
  UPDATING,
  DELETING
}

export enum PollStatus {
  OPEN,
  CLOSED
}

interface IVote {
  option: number;
  id: string;
}

export interface IPoll {
  id: string;
  clientId?: string;
  state?: PollState;
  title: string;
  brandId: string;
  groupId: string;
  description: string;
  options: string[];
  votes: IVote[];
  status: PollStatus;
  startDate: number;
  endDate: number;
  createdAt: number;
  updatedAt: number;
}

export interface IPollCreationPayload {
  title: string;
  groupId: string;
  description: string;
  options: string[];
  startDate: number;
  endDate: number;
}

export interface IPollUpdatePayload {
  id: string;
  title: string;
  description: string;
}

export type PollDateFilter = 'active' | 'scheduled' | 'past';
