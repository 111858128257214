import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ISetDialogOpenPayload, setDialog, setDialogOpen } from '../store/modules/ui/actions';
import { IUiState } from '../store/modules/ui/reducer';
import { RootState } from '../types/store';
import { IDialog } from '../types/ui';

const mapStateToProps = (state: RootState) => ({
  ui: state.ui
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      setDialogOpen
    },
    dispatch
  ),
  confirmAction: (payload: Partial<IDialog> | null) => {
    if (payload) {
      const defaults: IDialog = {
        title: 'Confirm your action',
        description: '',
        color: 'primary',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        action: () => {}
      };
      const dialog = {
        ...defaults,
        ...payload
      };
      dispatch(setDialog({ dialog }));
    } else {
      dispatch(setDialog({ dialog: null }));
    }
  }
});

interface IUiReduxState {
  ui: IUiState;
}

export interface IUiDispatchProps {
  confirmAction: (payload: Partial<IDialog>) => void;
  setDialogOpen: (payload: ISetDialogOpenPayload) => void;
}

export type UiProps = IUiReduxState & IUiDispatchProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
