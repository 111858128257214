import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

import theme from '../../theme';
import { IGroup } from '../../types/groups';

interface IProps {
  item: IGroup;
}

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      paddingBottom: 19
    },
    listItemText: {
      fontSize: '0.875rem'
    }
  });

type Props = IProps & RouteComponentProps & WithStyles<typeof styles>;

const GroupListItem: React.FC<Props> = props => {
  return (
    <Container>
      <ListItem
        button
        disabled={typeof props.item.state === 'number'}
        onClick={() => props.history.push(`/app/admin/groups/${props.item.id}`)}
        classes={{
          root: props.classes.listItem
        }}
      >
        <ListItemText
          primary={props.item.name}
          classes={{ primary: props.classes.listItemText }}
        />
      </ListItem>
      <Divider />
    </Container>
  );
};

const Container = styled.div`
  margin: ${p => theme.spacing.unit}px 0;
`;

export default withStyles(styles)(withRouter(GroupListItem));
