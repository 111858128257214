import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import elipsify from '../../utils/elipsify';
import { VictoryBar, VictoryChart, VictoryTheme, VictoryAxis } from 'victory';
import EditPostForm from '../../components/EditPostForm/EditPostForm';
import NotFound from '../../components/NotFound/NotFound';
import withPostsState, { PostsProps } from '../../enhancers/withPostsState';
import BackScreen from '../../layouts/BackScreen/BackScreen';
import CenterForm from '../../layouts/CenterForm/CenterForm';
import withPollsState, { PollsProps } from '../../enhancers/withPollsState';
import Typography from '@material-ui/core/Typography';
import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';

type Props = RouteComponentProps<{ id: string; groupId: string }> &
  PollsProps &
  GroupsProps;

const ViewPoll: React.FC<Props> = props => {
  const getContent = () => {
    const { polls } = props.polls;
    const { groups } = props.groups;
    if (!polls.polls || !polls.loaded || !groups.loaded) {
      return <CircularProgress />;
    }
    const poll = polls.polls.find(
      thePoll => thePoll.id === props.match.params.id
    );
    if (!poll) {
      return <NotFound />;
    }

    const data = poll.votes.reduce<Array<{ x: string; y: number }>>(
      (all, vote) => {
        const optionName = poll.options[vote.option];
        const existingIndex = all.findIndex(datum => datum.x === optionName);

        if (existingIndex === -1) {
          all.push({ x: optionName, y: 1 });
        } else {
          const existingData = all[existingIndex];
          all[existingIndex] = {
            ...existingData,
            y: ++existingData.y
          };
        }
        return all;
      },
      poll.options.map(option => ({ x: option, y: 0 }))
    );

    return (
      <div>
        <Typography gutterBottom variant="h5" component="h4">
          {poll.title}
        </Typography>
        <VictoryChart theme={VictoryTheme.material} domainPadding={{ x: 20 }}>
          <VictoryBar barWidth={40} data={data} labels={d => d.y} />
          <VictoryAxis style={{ grid: { stroke: t => 'none' } }} />
        </VictoryChart>
        <Typography variant="body2" color="textSecondary" component="p">
          {poll.description}
        </Typography>
      </div>
    );
  };
  return (
    <BackScreen title="View Poll">
      <CenterForm>{getContent()}</CenterForm>
    </BackScreen>
  );
};

export default withGroupsState(withPollsState(ViewPoll));
