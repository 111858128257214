import { isAfter, isBefore, isEqual } from 'date-fns';

import { IPoll } from '../types/poll';

export const parsePollDate = (poll: IPoll) => {
  const start = new Date(poll.startDate * 1000);
  const end = new Date(poll.endDate * 1000);
  return { start, end };
};

export default (polls: IPoll[], startDate?: Date) => {
  let cutOff: Date | undefined = undefined;
  let prevEnd: Date | undefined = undefined;
  polls.sort((a, b) => {
    return a.endDate - b.endDate;
  });
  if (startDate) {
    for (let poll of polls) {
      const { start, end } = parsePollDate(poll);
      if (prevEnd) {
        if (
          (isBefore(startDate, start) && isAfter(startDate, prevEnd)) ||
          isEqual(startDate, prevEnd)
        ) {
          cutOff = start;
          break;
        }
      }
      prevEnd = end;
    }
  }

  return (type: 'start' | 'end') => (date: Date) => {
    if (cutOff && type === 'end') {
      if (isAfter(date, cutOff) || isEqual(date, cutOff)) {
        return true;
      }
    }
    for (let poll of polls) {
      const { start, end } = parsePollDate(poll);
      if (
        (isBefore(date, end) && isAfter(date, start)) ||
        isEqual(date, start)
      ) {
        return true;
      }
    }
    return false;
  };
};
