import Fab from '@material-ui/core/Fab';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import image from '../../assets/img/Tick.png';
import Subtitle from '../../components/Subtitle/Subtitle';
import Title from '../../components/Title/Title';
import theme from '../../theme';

const styles = (theme: Theme) =>
  createStyles({
    white: {
      color: 'white'
    },
    light: {
      opacity: 0.8,
      fontSize: 14
    },
    cta: {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      textTransform: 'initial',
      fontWeight: 400,
      marginTop: 30,
      '&$hover': {
        backgroundColor: 'white'
      }
    },
    ctaDisabled: {
      opacity: 0.3,
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: 'white !important'
    }
  });

type Props = WithStyles<typeof styles> & RouteComponentProps<{ id: string }>;

const GroupCreated: React.FC<Props> = props => {
  return (
    <Container>
      <Inner>
        <Image src={image} />
        <PageTitle className={props.classes.white}>Group Created</PageTitle>
        <Line />
        <PageSubtitle className={props.classes.white}>
          Your volunteer group has been created! You can add members or start
          creating right away.
        </PageSubtitle>
        <Fab
          style={{ width: 220 }}
          variant="extended"
          aria-label="Add"
          onClick={() =>
            props.history.push(
              `/app/admin/groups/${props.match.params.id}/users`
            )
          }
          classes={{
            root: props.classes.cta,
            disabled: props.classes.ctaDisabled
          }}
        >
          Add Members
        </Fab>
      </Inner>
    </Container>
  );
};

const Line = styled.div`
  width: 50px;
  background: white;
  height: 2px;
  margin: 16px auto;
`;

const Image = styled.img`
  width: 300px;
  height: 300px;
  margin-top: 20px;
`;

const Container = styled.div`
  flex: 1;
  background: ${p => theme.palette.primary.main};
`;

const PageTitle = styled(Title)`
  && {
    text-align: center;
    font-weight: 400;
  }
`;

const PageSubtitle = styled(Subtitle)`
  && {
    text-align: center;
    margin-top: 8px;
    font-weight: 100;
    font-size: 16px;
    opacity: 0.8;
  }
`;

const Inner = styled.div`
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export default withRouter(withStyles(styles)(GroupCreated));
