import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withBrandState, { BrandProps } from '../../enhancers/withBrandState';
import { AuthState } from '../../types/auth';

type Props = BrandProps & { children: React.ReactNode } & AuthProps;

const BrandChecker: React.FC<Props> = props => {
  const brandLoaded = props.brand.loaded && props.brand.brand;
  const authLoaded = ![AuthState.LOADING].includes(props.auth.state);

  if (brandLoaded && authLoaded) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withAuthState(withBrandState(BrandChecker));
