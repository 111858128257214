import { createStandardAction } from 'typesafe-actions';

import { GroupCreationState, IGroup, IGroupUpdate } from '../../../types/groups';
import { Invitee } from '../../../types/users';

const CREATE_GROUP = 'groups/CREATE_GROUP';
const SET_GROUP_DESCRIPTION = 'groups/SET_GROUP_DESCRIPTION';
const SET_GROUP_CREATION_STATE = 'groups/SET_GROUP_CREATION_STATE';
const WATCH_GROUPS = 'groups/WATCH_GROUPS';
const SET_GROUPS = 'groups/SET_GROUPS';
const SET_GROUP = 'groups/SET_GROUP';
const ADD_GROUP = 'groups/ADD_GROUP';
const REMOVE_GROUP = 'groups/REMOVE_GROUP';
const STOP_WATCHING_GROUPS = 'groups/STOP_WATCHING_GROUPS';
const SET_GROUPS_LOADED = 'groups/SET_GROUPS_LOADED';
const UPDATE_GROUP_DETAILS = 'groups/UPDATE_GROUP_DETAILS';
const SET_GROUP_ACTIVE_STATE = 'groups/SET_GROUP_ACTIVE_STATE';
const DELETE_GROUP = 'groups/DELETE_GROUP';

const INVITE_GROUP_USER = 'groups/INVITE_GROUP_USER';
const DELETE_GROUP_USER = 'groups/DELETE_GROUP_USER';

const WATCH_GROUP_INVITEES = 'groups/WATCH_GROUP_INVITEES';
const SET_GROUP_INVITEES_LOADED = 'groups/SET_GROUP_INVITEES_LOADED';
const STOP_WATCHING_GROUP_INVITEES = 'groups/STOP_WATCHING_GROUP_INVITEES';
const SET_GROUP_INVITEES = 'groups/SET_GROUP_INVITEES';
const SET_GROUP_INVITEE = 'groups/SET_GROUP_INVITEE';
const ADD_GROUP_INVITEE = 'groups/ADD_GROUP_INVITEE';
const REMOVE_GROUP_INVITEE = 'groups/REMOVE_GROUP_INVITEE';
const DELETE_GROUP_INVITEE = 'groups/DELETE_GROUP_INVITEE';

export interface ICreateGroupPayload {
  details: {
    name: string;
    location: string;
  };
}

export const createGroup = createStandardAction(CREATE_GROUP)<
  ICreateGroupPayload
>();

export interface ISetGroupDescriptionPayload {
  description: string;
}

export const setGroupDescription = createStandardAction(SET_GROUP_DESCRIPTION)<
  ISetGroupDescriptionPayload
>();

export interface ISetGroupCreationStatePayload {
  state: GroupCreationState;
}

export const setGroupCreationState = createStandardAction(
  SET_GROUP_CREATION_STATE
)<ISetGroupCreationStatePayload>();

export const watchGroups = createStandardAction(WATCH_GROUPS)();
export const stopWatchingGroups = createStandardAction(STOP_WATCHING_GROUPS)();

export interface ISetGroupsPayload {
  groups: IGroup[];
}

export const setGroups = createStandardAction(SET_GROUPS)<ISetGroupsPayload>();

export interface IAddGroupPayload {
  group: IGroup;
}

export const addGroup = createStandardAction(ADD_GROUP)<IAddGroupPayload>();

export interface ISetGroupPayload {
  group: IGroup;
}

export const setGroup = createStandardAction(SET_GROUP)<ISetGroupPayload>();

export interface IRemoveGroupPayload {
  id: string;
}

export const removeGroup = createStandardAction(REMOVE_GROUP)<
  IRemoveGroupPayload
>();

export interface IDeleteGroupUserPayload {
  id: string;
  groupId: string;
}

export const deleteGroupUser = createStandardAction(DELETE_GROUP_USER)<
  IDeleteGroupUserPayload
>();

export interface IInviteGroupUserPayload {
  name: string;
  email: string;
  isTier3: boolean;
  groupId: string;
}

export const inviteGroupUser = createStandardAction(INVITE_GROUP_USER)<
  IInviteGroupUserPayload
>();

export const watchGroupInvitees = createStandardAction(WATCH_GROUP_INVITEES)();
export const stopWatchingGroupInvitees = createStandardAction(
  STOP_WATCHING_GROUP_INVITEES
)();

export interface ISetGroupInviteesPayload {
  invitees: Invitee[];
}

export const setGroupInvitees = createStandardAction(SET_GROUP_INVITEES)<
  ISetGroupInviteesPayload
>();

export interface IAddGroupInviteePayload {
  invitee: Invitee;
}

export const addGroupInvitee = createStandardAction(ADD_GROUP_INVITEE)<
  IAddGroupInviteePayload
>();

export interface ISetGroupInviteePayload {
  invitee: Invitee;
}

export const setGroupInvitee = createStandardAction(SET_GROUP_INVITEE)<
  ISetGroupInviteePayload
>();

export interface IRemoveGroupInviteePayload {
  id: string;
}

export const removeGroupInvitee = createStandardAction(REMOVE_GROUP_INVITEE)<
  IRemoveGroupInviteePayload
>();

export interface IDeleteGroupInviteePayload {
  id: string;
}

export const deleteGroupInvitee = createStandardAction(DELETE_GROUP_INVITEE)<
  IDeleteGroupInviteePayload
>();

export interface ISetGroupInviteesLoadedPayload {
  loaded: boolean;
}

export const setGroupInviteesLoaded = createStandardAction(
  SET_GROUP_INVITEES_LOADED
)<ISetGroupInviteesLoadedPayload>();

export interface ISetGroupLoadedPayload {
  loaded: boolean;
}

export const setGroupsLoaded = createStandardAction(SET_GROUPS_LOADED)<
  ISetGroupLoadedPayload
>();

export interface IUpdateGroupDetailsPayload {
  group: IGroupUpdate;
}

export const updateGroupDetails = createStandardAction(UPDATE_GROUP_DETAILS)<
  IUpdateGroupDetailsPayload
>();

export interface ISetGroupActiveStatePayload {
  id: string;
  active: boolean;
}

export const setGroupActiveState = createStandardAction(SET_GROUP_ACTIVE_STATE)<
  ISetGroupActiveStatePayload
>();

export interface IDeleteGroupPayload {
  id: string;
}

export const deleteGroup = createStandardAction(DELETE_GROUP)<
  IDeleteGroupPayload
>();
