import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import EditPollForm from '../../components/EditPollForm/EditPollForm';
import NotFound from '../../components/NotFound/NotFound';
import withPollsState, { PollsProps } from '../../enhancers/withPollsState';
import BackScreen from '../../layouts/BackScreen/BackScreen';
import CenterForm from '../../layouts/CenterForm/CenterForm';

type Props = RouteComponentProps<{ groupId: string; id: string }> & PollsProps;

const NewPoll: React.FC<Props> = props => {
  const getContent = () => {
    const { polls } = props.polls;
    if (!polls.polls || !polls.loaded) {
      return <CircularProgress />;
    }
    const poll = polls.polls.find(
      thePoll => thePoll.id === props.match.params.id
    );
    if (!poll) {
      return <NotFound />;
    }

    return <EditPollForm groupId={props.match.params.groupId} poll={poll} />;
  };
  return (
    <BackScreen title="Edit Poll">
      <CenterForm>{getContent()}</CenterForm>
    </BackScreen>
  );
};

export default withPollsState(NewPoll);
