import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { IPost } from '../../../types/post';
import { actions } from './';

interface IPosts {
  loaded: boolean;
  posts: IPost[] | null;
  error: string;
}

export interface IPostsState {
  readonly posts: IPosts;
}

type PostsAction = ActionType<typeof actions>;

const initialState: IPostsState = {
  posts: {
    loaded: false,
    error: '',
    posts: null
  }
};

export default combineReducers<IPostsState, PostsAction>({
  posts: combineReducers<IPosts, PostsAction>({
    loaded: (state = initialState.posts.loaded, action) => {
      if (action.type === getType(actions.setPosts)) {
        return true;
      }
      return state;
    },
    error: (state = initialState.posts.error, action) => {
      return state;
    },
    posts: (state = initialState.posts.posts, action) => {
      switch (action.type) {
        case getType(actions.setPosts): {
          return action.payload.posts;
        }
        case getType(actions.addPost): {
          if (state) {
            return [...state, action.payload.post];
          }
          return [action.payload.post];
        }
        case getType(actions.removePost): {
          if (state) {
            return state.filter(post => post.id !== action.payload.id);
          }
          return state;
        }
        case getType(actions.setPost): {
          if (state) {
            const index = state.findIndex(post => {
              if (action.payload.post.clientId) {
                return (
                  post.clientId === action.payload.post.clientId ||
                  post.id === action.payload.post.id
                );
              }
              return post.id === action.payload.post.id;
            });
            if (index > -1) {
              const newState = [...state];
              newState[index] = {
                ...action.payload.post
              };
              return newState;
            }
          }
          return state;
        }
        case getType(actions.clearPosts): {
          return [];
        }
        default: {
          return state;
        }
      }
    }
  })
});
