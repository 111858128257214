export enum JoinRequestState {
  CREATING,
  UPDATING,
  DELETING
}

export interface IJoinRequest {
  id: string;
  brandId: string;
  groupId: string;
  userUid: string;
  createdAt: number;
  state?: JoinRequestState;
}
