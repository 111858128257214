import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { IDialog } from '../../../types/ui';
import * as actions from './actions';

interface IDialogState {
  dialog: IDialog | null;
  open: boolean;
}
export interface IUiState {
  readonly dialog: IDialogState;
}

const initialState: IUiState = {
  dialog: {
    open: false,
    dialog: null
  }
};

type UiAction = ActionType<typeof actions>;

export default combineReducers<IUiState, UiAction>({
  dialog: combineReducers<IDialogState, UiAction>({
    dialog: (state = initialState.dialog.dialog, action) => {
      switch (action.type) {
        case getType(actions.setDialog): {
          return action.payload.dialog;
        }
        default: {
          return state;
        }
      }
    },
    open: (state = initialState.dialog.open, action) => {
      switch (action.type) {
        case getType(actions.setDialogOpen): {
          return action.payload.open;
        }
        default: {
          return state;
        }
      }
    }
  })
});
