import { debounce } from 'lodash';
import { eventChannel } from 'redux-saga';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as actions from './actions';

type WindowChannel = { width: number; height: number };

const windowSizeChange = () =>
  eventChannel<WindowChannel>(emitter => {
    const listener = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      emitter({ height, width });
    };

    const debounced = debounce(listener, 200);
    window.addEventListener('resize', debounced);
    return () => window.removeEventListener('resize', debounced);
  });

function* handleInput(payload: WindowChannel) {
  yield put(actions.updateSize(payload));
}

function* watchWindow() {
  yield takeEvery(windowSizeChange(), handleInput);
}

export default function*() {
  yield all([watchWindow()]);
}
