import { all, delay, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'typesafe-actions';

import * as actions from './actions';

function* handleSetDialogOpen(
  action: Action & { payload: actions.ISetDialogOpenPayload }
) {
  if (!action.payload.open) {
    yield delay(100);
    yield put(actions.setDialog({ dialog: null }));
  }
}

function* watchSetDialogOpen() {
  yield takeEvery(actions.setDialogOpen, handleSetDialogOpen);
}

function* handleSetDialog(
  action: Action & { payload: actions.ISetDialogPayload }
) {
  if (action.payload.dialog) {
    yield put(actions.setDialogOpen({ open: true }));
  }
}

function* watchSetDialog() {
  yield takeEvery(actions.setDialog, handleSetDialog);
}

export default function*() {
  yield all([watchSetDialogOpen(), watchSetDialog()]);
}
