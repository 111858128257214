import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import GroupDescriptionForm from '../../components/GroupDescriptionForm/GroupDescriptionForm';
import HeaderScreen from '../../containers/HeaderScreen/HeaderScreen';
import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';
import { GroupCreationState } from '../../types/groups';

type Props = GroupsProps & RouteComponentProps;

const GroupDescription: React.FC<Props> = props => {
  if (props.groups.creation.state !== GroupCreationState.CREATING) {
    props.history.replace('/app/request');
  }
  return (
    <HeaderScreen title="Create a Group" progress={66}>
      <GroupDescriptionForm />
    </HeaderScreen>
  );
};

export default withGroupsState(GroupDescription);
