import { InjectedFormikProps, withFormik } from 'formik';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';

import { PostsProps } from '../../enhancers/withPostsState';
import { UiProps } from '../../enhancers/withUiState';
import { IPost } from '../../types/post';

type FormValues = {
  title: string;
  content: string;
  image: string;
  video: string;
};

interface IFormProps {
  groupId: string;
  post: IPost;
}

type FormProps = IFormProps & PostsProps & RouteComponentProps & UiProps;

export type EditPostFormProps = InjectedFormikProps<FormProps, FormValues> &
  Partial<FormValues>;

const initialValues = {
  title: '',
  content: '',
  image: '',
  video: ''
};

export default withFormik<FormProps, FormValues>({
  isInitialValid: true,
  handleSubmit: (values, { props, setSubmitting, setError, resetForm }) => {
    setSubmitting(true);
    const { title, content, image, video } = values;
    const { groupId } = props;
    try {
      props.confirmAction({
        title: 'Update this post?',
        action: () => {
          props.updatePostContent({
            post: {
              id: props.post.id,
              title,
              content,
              image,
              video
            }
          });
          // props.history.replace(`/app/admin/groups/${groupId}/posts`);
        }
      });
    } catch (e) {
      console.log({ e });
    }
  },
  mapPropsToValues: ({ post }) => ({
    title: post.title,
    content: post.content,
    image: post.image,
    video: post.video || ''
  }),
  validationSchema: Yup.object().shape({
    title: Yup.string().required(),
    content: Yup.string().required(),
    image: Yup.string().required(),
    video: Yup.string()
  })
});
