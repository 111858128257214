import { createStandardAction } from 'typesafe-actions';
import { Subtract } from 'utility-types';

import { GroupRequestError, IGroupRequest } from '../../../types/groupRequests';

const CREATE_GROUP_REQUEST = 'groups/CREATE_GROUP_REQUEST';
const SET_SUBMITTED = 'groups/SET_SUBMITTED';
const SET_ERROR = 'groups/SET_ERROR';

export interface ICreateGroupRequestPayload {
  groupRequest: Subtract<IGroupRequest, { id: string }>;
}

export const createGroupRequest = createStandardAction(CREATE_GROUP_REQUEST)<
  ICreateGroupRequestPayload
>();

export interface ISetGroupRequestSubmittedPayload {
  submitted: boolean;
}

export const setSubmitted = createStandardAction(SET_SUBMITTED)<
  ISetGroupRequestSubmittedPayload
>();

export interface ISetGroupRequestErrorPayload {
  error: GroupRequestError | null;
}

export const setGroupRequestError = createStandardAction(SET_ERROR)<
  ISetGroupRequestErrorPayload
>();
