import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  acceptInvitation,
  declineInvite,
  emailLogin,
  IAcceptInvitationPayload,
  IDeclineInvitePayload,
  IEmailLoginPayload,
  ILoggedInAcceptInvitePayload,
  ILoginPayload,
  ISetRecaptchaPayload,
  ITokenLoginPayload,
  IVerifyCodePayload,
  loggedInAcceptInvite,
  login,
  requestLogout,
  setRecaptcha,
  tokenLogin,
  verifyCode,
  verifyRecaptcha,
} from '../store/modules/auth/actions';
import { IAuthState as IAuthReduxState } from '../store/modules/auth/reducer';
import { RootState } from '../types/store';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setRecaptcha,
      verifyRecaptcha,
      verifyCode,
      login,
      requestLogout,
      acceptInvitation,
      tokenLogin,
      emailLogin,
      declineInvite,
      loggedInAcceptInvite
    },
    dispatch
  );

interface IAuthState {
  auth: IAuthReduxState;
}

interface IAuthDispatch {
  setRecaptcha: (payload: ISetRecaptchaPayload) => void;
  verifyRecaptcha: () => void;
  login: (payload: ILoginPayload) => void;
  verifyCode: (payload: IVerifyCodePayload) => void;
  requestLogout: () => void;
  acceptInvitation: (payload: IAcceptInvitationPayload) => void;
  tokenLogin: (payload: ITokenLoginPayload) => void;
  emailLogin: (payload: IEmailLoginPayload) => void;
  loggedInAcceptInvite: (payload: ILoggedInAcceptInvitePayload) => void;
  declineInvite: (payload: IDeclineInvitePayload) => void;
}

export type AuthProps = IAuthState & IAuthDispatch;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
