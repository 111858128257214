export enum GroupCreationState {
  IDLE,
  CREATING
}

export enum GroupState {
  CREATING,
  UPDATING,
  DELETING
}

export interface IGroup {
  id: string;
  name: string;
  description?: string;
  public: boolean;
  active: boolean;
  tags: string[];
  tier3Ids: string[];
  tier4Ids: string[];
  brandId: string;
  publicJoin?: boolean;
  state: GroupState;
  tier3PostOnly?: boolean;
  isDefaultGroup?: boolean;
}

export interface IGroupUpdate {
  id: string;
  name: string;
  description: string;
  publicJoin?: boolean;
  public: boolean;
  tier3PostOnly?: boolean;
}
