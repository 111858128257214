import { createStandardAction } from 'typesafe-actions';

import { IPoll, IPollCreationPayload, IPollUpdatePayload, PollStatus } from '../../../types/poll';

const WATCH_POLLS = 'polls/WATCH_POLLS';
const STOP_WATCHING_POLLS = 'polls/STOP_WATCHING_POLLS';
const SET_POLLS = 'polls/SET_POLLS';
const SET_POLL = 'polls/SET_POLL';
const REMOVE_POLL = 'polls/REMOVE_POLL';
const ADD_POLL = 'polls/ADD_POLL';
const SET_POLLS_LOADED = 'polls/SET_LOADED';
const CLEAR_POLLS = 'polls/CLEAR_POLLS';
const CREATE_POLL = 'polls/CREATE_POLL';
const UPDATE_POLL = 'polls/UPDATE_POLL';
const DELETE_POLL = 'polls/DELETE_POLL';
const SET_POLL_STATUS = 'polls/SET_POLL_STATUS';
const UPDATE_POLL_DATES = 'polls/UPDATE_DATES';

export const watchPolls = createStandardAction(WATCH_POLLS)();
export const stopWatchingPolls = createStandardAction(STOP_WATCHING_POLLS)();

export interface ISetPollsPayload {
  polls: IPoll[];
}

export const setPolls = createStandardAction(SET_POLLS)<ISetPollsPayload>();

export interface ISetPollPayload {
  poll: IPoll;
}

export const setPoll = createStandardAction(SET_POLL)<ISetPollPayload>();

export interface IRemovePollPayload {
  id: string;
}

export const removePoll = createStandardAction(REMOVE_POLL)<
  IRemovePollPayload
>();

export interface IAddPollPayload {
  poll: IPoll;
}

export const addPoll = createStandardAction(ADD_POLL)<IAddPollPayload>();

export interface ISetPollsLoadedPayload {
  loaded: boolean;
}

export const setPollsLoaded = createStandardAction(SET_POLLS_LOADED)<
  ISetPollsLoadedPayload
>();

export const clearPolls = createStandardAction(CLEAR_POLLS)();

export interface ICreatePollPayload {
  poll: IPollCreationPayload;
}

export const createPoll = createStandardAction(CREATE_POLL)<
  ICreatePollPayload
>();

export interface IUpdatePollContentPayload {
  poll: IPollUpdatePayload;
}

export const updatePollContent = createStandardAction(UPDATE_POLL)<
  IUpdatePollContentPayload
>();

export interface IDeletePollPayload {
  id: string;
}

export const deletePoll = createStandardAction(DELETE_POLL)<
  IDeletePollPayload
>();

export interface ISetPollStatusPayload {
  id: string;
  status: PollStatus;
}

export const setPollStatus = createStandardAction(SET_POLL_STATUS)<
  ISetPollStatusPayload
>();

export interface IUpdatePollDatePayload {
  id: string;
  startDate: number;
  endDate: number;
}

export const updatePollDate = createStandardAction(UPDATE_POLL_DATES)<
  IUpdatePollDatePayload
>();
