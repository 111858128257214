import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import Markdown from 'react-markdown';

import mdCheatsheet from '../../utils/mdCheatsheet';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
}

type Props = IProps;

const MarkdownEditor: React.FC<Props> = props => {
  const [tab, setTab] = React.useState<number>(0);

  return (
    <div style={{ marginTop: 10 }}>
      <Typography variant="h6">{props.label}</Typography>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, width: '50%' }}>
          <AppBar
            style={{ marginTop: 10, marginBottom: 0 }}
            position="static"
            color="default"
          >
            <Tabs value={tab} onChange={(e, value) => setTab(value)}>
              <Tab label="Edit" />
              <Tab label="Preview" />
              <Tab label="Cheatsheet" />
            </Tabs>
          </AppBar>
          {tab === 0 && (
            <TextField
              value={props.value}
              onChange={e => props.onChange(e.target.value)}
              fullWidth={true}
              margin="none"
              variant="outlined"
              multiline={true}
              rows={10}
            />
          )}
          {tab === 1 && (
            <div style={{ height: 227, overflowY: 'auto' }}>
              <Markdown className="preview-markdown" source={props.value} />
            </div>
          )}
          {tab === 2 && (
            <div style={{ height: 800, overflowY: 'auto' }}>
              <Markdown className="preview-markdown" source={mdCheatsheet} />
            </div>
          )}
        </div>
      </div>

      <style
        dangerouslySetInnerHTML={{
          __html: `.preview-markdown pre {
  background: #eee;
}`
        }}
      />
    </div>
  );
};

export default MarkdownEditor;
