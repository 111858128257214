import { createStandardAction } from 'typesafe-actions';

import { BrandError, IBrand } from '../../../types/brand';

const FETCH_BRAND = 'brand/FETCH_BRAND';
const SET_BRAND = 'brand/SET_BRAND';
const SET_ERROR = 'brand/SET_ERROR';

export interface ISetBrandPayload {
  brand: IBrand;
}

export const setBrand = createStandardAction(SET_BRAND)<ISetBrandPayload>();

export interface ISetBrandErrorPayload {
  error: BrandError | null;
}

export const setBrandError = createStandardAction(SET_ERROR)<
  ISetBrandErrorPayload
>();

export const fetchBrand = createStandardAction(FETCH_BRAND)();
