import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { clearRedirect, redirect, setRedirect } from '../store/modules/routing/actions';
import { RootState } from '../types/store';

const mapStateToProps = (state: RootState) => ({
  redirectTo: state.routing.to
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearRedirect,
      redirect,
      setRedirect
    },
    dispatch
  );

export interface IRedirectState {
  redirectTo: string;
}

export interface IRedirectDispatchProps {
  clearRedirect: () => void;
  redirect: () => void;
  setRedirect: (payload: { to: string }) => void;
}

export type RoutingProps = IRedirectState & IRedirectDispatchProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
