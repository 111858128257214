import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { GroupRequestError } from '../../../types/groupRequests';
import * as actions from './actions';

export interface IGroupRequestsState {
  readonly submitted: boolean;
  readonly error: GroupRequestError | null;
}

type GroupRequestsAction = ActionType<typeof actions>;

const initialState: IGroupRequestsState = {
  submitted: false,
  error: null
};

export default combineReducers<IGroupRequestsState, GroupRequestsAction>({
  error: (state = initialState.error, action) => {
    switch (action.type) {
      case getType(actions.setGroupRequestError): {
        return action.payload.error;
      }
      default: {
        return state;
      }
    }
  },
  submitted: (state = initialState.submitted, action) => {
    if (action.type === getType(actions.setSubmitted)) {
      return action.payload.submitted;
    }
    return state;
  }
});
