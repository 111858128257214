import {
  createStyles,
  Divider,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import removeMd from 'remove-markdown';
import styled from 'styled-components';

import withPollsState, { PollsProps } from '../../enhancers/withPollsState';
import withUiState, { UiProps } from '../../enhancers/withUiState';
import theme from '../../theme';
import { IPoll } from '../../types/poll';
import elipsify from '../../utils/elipsify';

interface IProps {
  poll: IPoll;
  groupId: string;
}

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      paddingBottom: 8,
      paddingTop: 3
    },
    listItemText: {
      fontSize: '0.875rem'
    }
  });

type Props = IProps &
  PollsProps &
  UiProps &
  WithStyles<typeof styles> &
  RouteComponentProps;

const Poll: React.FC<Props> = props => {
  const { poll } = props;

  const [anchorEl, setAnchorEl] = React.useState<
    EventTarget & HTMLElement | null
  >(null);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const t = event.currentTarget;
    setAnchorEl(t);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <ListItem classes={{ root: props.classes.listItem }}>
        <ListItemText
          primary={poll.title}
          secondary={elipsify(removeMd(poll.description), 88)}
          classes={{
            primary: props.classes.listItemText,
            secondary: props.classes.listItemText
          }}
        />
        <ListItemSecondaryAction>
          <IconButton
            disabled={typeof props.poll.state === 'number'}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                props.history.push(
                  `/app/admin/groups/${props.groupId}/polls/${poll.id}/view`
                );
                handleClose();
              }}
            >
              View
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.history.push(
                  `/app/admin/groups/${props.groupId}/polls/${poll.id}/edit`
                );
                handleClose();
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.confirmAction({
                  title: 'Are you sure?',
                  action: () => {
                    props.deletePoll({ id: poll.id });
                    handleClose();
                  }
                });
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </Container>
  );
};

const Container = styled.div`
  margin: ${p => theme.spacing.unit}px 0;
`;

export default withRouter(
  withStyles(styles)(withUiState(withPollsState(Poll)))
);
