import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { toggle as toggleAction } from '../store/modules/drawer/actions';
import { RootState } from '../types/store';

const mapStateToProps = (state: RootState) => ({
  open: state.drawer.open
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggle: toggleAction
    },
    dispatch
  );
interface IDrawerState {
  open: boolean;
}
interface IDrawerDispatchProps {
  toggle: (open: boolean) => void;
}

export type DrawerProps = IDrawerState & IDrawerDispatchProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
