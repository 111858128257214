import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import withAuthState from '../../enhancers/withAuthState';
import withWindowState, { WindowProps } from '../../enhancers/withWindowState';
import { AuthError } from '../../types/auth';
import withEmailLoginForm, { EmailLoginFormProps } from './withEmailLoginForm';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing.unit * 2
    },
    button: {
      marginTop: theme.spacing.unit * 2
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    cta: {
      textTransform: 'initial',
      width: '100%',
      fontWeight: 400,
      fontSize: 18,
      marginTop: 26
    }
  });

type Props = WithStyles<typeof styles> & WindowProps & EmailLoginFormProps;

const EmailLoginForm: React.FC<Props> = props => {
  React.useEffect(() => {
    console.log('auth error', props.auth.error, AuthError.INVALID_CREDENTIALS);
    if (props.auth.error === AuthError.INVALID_CREDENTIALS) {
      props.setSubmitting(false);
      props.setFieldError('email', 'Invalid credentials');
    }
  });
  console.log(props.auth.error);
  const requestForm = (
    <form onSubmit={props.handleSubmit}>
      <Typography variant="subtitle1">Login with your email address</Typography>
      <TextField
        label="Email"
        value={props.values.email}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        name="email"
        fullWidth
        type="email"
        variant="outlined"
        error={props.touched.email && !!props.errors.email}
        helperText={props.touched.email ? props.errors.email : ''}
        margin="normal"
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        value={props.values.password}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        fullWidth
        variant="outlined"
        error={props.touched.password && !!props.errors.password}
        helperText={props.touched.password ? props.errors.password : ''}
        margin="normal"
      />
      <Fab
        disabled={props.isSubmitting}
        onClick={props.submitForm}
        variant="extended"
        color="primary"
        aria-label="Add"
        classes={{
          root: props.classes.cta
        }}
      >
        Login
      </Fab>
      <br />
      <br />
      <Button
        fullWidth
        component={props => (
          <Link {...props} to={`/app/admin/login`}>
            {props.children}
          </Link>
        )}
      >
        Login with Phone Number
      </Button>
    </form>
  );

  return requestForm;
};

export default withRouter(
  withAuthState(
    withEmailLoginForm(withWindowState(withStyles(styles)(EmailLoginForm)))
  )
);
