export interface IGroupRequest {
  id: string;
  brandId: string;
  email: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  name: string;
  description: string;
}

export enum GroupRequestError {
  UNKOWN_ERROR
}
