import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import * as React from 'react';

import withBrandState, { BrandProps } from './enhancers/withBrandState';
import defaultTheme from './theme';

interface IProps {
  children: React.ReactNode;
}

type Props = IProps & BrandProps;

const secondary = '#E12845';

const ThemeProvider: React.FC<Props> = props => {
  let theme: Theme = defaultTheme;
  if (props.brand.brand) {
    const { brand } = props.brand;
    theme = createMuiTheme({
      palette: {
        primary: {
          main: brand.primaryColor
        },
        secondary: {
          main: brand.accentColor
        }
      },
      overrides: {
        MuiFab: {
          primary: {
            backgroundColor: brand.primaryColor,
            color: 'white'
          }
        },
        MuiButton: {
          containedPrimary: {
            backgroundColor: brand.primaryColor,
            color: 'white'
          },
          flatPrimary: {
            backgroundColor: brand.primaryColor,
            color: 'white'
          },
          outlinedPrimary: {
            backgroundColor: brand.primaryColor,
            color: 'white'
          },
          raisedPrimary: {
            backgroundColor: brand.primaryColor,
            color: 'white'
          },
          containedSecondary: {
            backgroundColor: secondary,
            color: 'white'
          },
          flatSecondary: {
            backgroundColor: secondary,
            color: 'white'
          },
          outlinedSecondary: {
            backgroundColor: secondary,
            color: 'white'
          },
          raisedSecondary: {
            backgroundColor: secondary,
            color: 'white'
          }
        }
      }
    });
  }
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {props.children}
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default withBrandState(ThemeProvider);
