import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';

import Centerer from '../../components/Centerer/Centerer';

const Unauthorized: React.FC = props => {
  return (
    <Centerer>
      <Typography>Unauthorized</Typography>
      <Button
        component={props => (
          <Link {...props} to="/app/admin/login">
            {props.children}
          </Link>
        )}
      >
        Return to login
      </Button>
    </Centerer>
  );
};

export default Unauthorized;
