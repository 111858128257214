import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyBziW6CEoB_fOAKusbO1f2k_fjjZP5UL7I',
  authDomain: 'look-at-you-party-cms.firebaseapp.com',
  databaseURL: 'https://look-at-you-party-cms.firebaseio.com',
  projectId: 'look-at-you-party-cms',
  storageBucket: 'look-at-you-party-cms.appspot.com',
  messagingSenderId: '658763504313'
};

initializeApp(firebaseConfig);
