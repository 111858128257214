import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import PollsList from '../../components/PollsList/PollsList';
import SearchField from '../../components/SearchField/SearchField';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import theme from '../../theme';
import { PollDateFilter } from '../../types/poll';

interface IProps {
  scrollRef: React.RefObject<HTMLDivElement>;
  filter: PollDateFilter;
}

type Props = RouteComponentProps<{ id: string }> & IProps;

const GroupPolls: React.FC<Props> = props => {
  const id = props.match.params.id;
  const [count, incrementCount, resetCount] = useInfiniteScroll();
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  return (
    <React.Fragment>
      <SearchContainer>
        <SearchField
          value={searchTerm}
          onChange={value => {
            setSearchTerm(value);
            resetCount();
          }}
          placeholder="Search Polls"
        />
      </SearchContainer>
      <PollsList
        filter={props.filter}
        groupId={id}
        filterText={searchTerm}
        scrollRef={props.scrollRef}
        count={count}
        incrementCount={incrementCount}
      />
      <FabContainer>
        <Fab
          component={props => (
            <Link {...props} to={`/app/admin/groups/${id}/polls/new`}>
              {props.children}
            </Link>
          )}
          color="secondary"
          aria-label="Add"
        >
          <AddIcon />
        </Fab>
      </FabContainer>
    </React.Fragment>
  );
};

const FabContainer = styled.div`
  position: fixed;
  bottom: ${p => theme.spacing.unit * 2 + 56}px;
  right: ${p => theme.spacing.unit * 2}px;
`;

const SearchContainer = styled.div`
  padding: 15px;
  background: rgba(0, 0, 0, 0.05);
`;

export default GroupPolls;
