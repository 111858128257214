import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import noGroups from '../../assets/img/group-blank.png';
import withUsersState, { UsersProps } from '../../enhancers/withUsersState';
import { IUser } from '../../types/users';
import EmptyState from '../EmptyState/EmptyState';
import GroupUserListItem from './GroupUserListItem';

interface IProps {
  groupId: string;
  scrollRef: React.RefObject<HTMLDivElement>;
  count: number;
  incrementCount: () => void;
  filterText?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    subheader: {
      fontSize: '1rem'
    }
  });

type Props = UsersProps & IProps & WithStyles<typeof styles>;

const GroupUsersList: React.FC<Props> = props => {
  const users = props.users.users;

  if (!users.users || !users.loaded) {
    return (
      <Centerer>
        <CircularProgress />
      </Centerer>
    );
  }

  type UserFilter = (user: IUser) => boolean;
  const filters: UserFilter[] = [];
  filters.push(
    user =>
      user.tier3Ids.includes(props.groupId) ||
      user.tier4Ids.includes(props.groupId)
  );
  filters.push(user => !user.isTier1);
  if (props.filterText) {
    filters.push(
      user =>
        user.firstName
          .toLowerCase()
          .includes(props.filterText!.toLowerCase()) ||
        user.lastName.toLowerCase().includes(props.filterText!.toLowerCase())
    );
  }
  const filteredUsers = filters.reduce<IUser[]>((acc, curr) => {
    return acc.filter(curr);
  }, users.users);

  const slicedUsers = filteredUsers.slice(0, props.count);

  return (
    <React.Fragment>
      {slicedUsers.length > 0 ? (
        <React.Fragment>
          <List
            subheader={
              <ListSubheader
                classes={{ root: props.classes.subheader }}
                component="div"
                style={{ position: 'static' }}
              >
                Users
              </ListSubheader>
            }
          >
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={props.incrementCount}
              hasMore={true}
              getScrollParent={() => props.scrollRef.current || null}
              useWindow={false}
            >
              {slicedUsers.map((user, i) => (
                <GroupUserListItem
                  groupId={props.groupId}
                  key={user.uid + '' + i}
                  item={user}
                />
              ))}
            </InfiniteScroll>
          </List>
        </React.Fragment>
      ) : (
        <EmptyState
          image={noGroups}
          text={
            <React.Fragment>
              There are no group members.
              <br />
              Add some members below.
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  );
};

const Centerer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withStyles(styles)(withUsersState(GroupUsersList));
