import { addDays, endOfDay, startOfDay } from 'date-fns';
import { InjectedFormikProps, withFormik } from 'formik';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';

import { PollsProps } from '../../enhancers/withPollsState';
import getDateInvalidator from '../../utils/getDateInvalidator';

export const MAX_POLL_OPTIONS = 4;

type FormValues = {
  startDate: Date;
  endDate: Date;
  title: string;
  description: string;
  options: string[];
};

interface IFormProps {
  groupId: string;
}

type FormProps = IFormProps & PollsProps & RouteComponentProps;

export type NewPollFormProps = InjectedFormikProps<FormProps, FormValues> &
  Partial<FormValues>;

const getInitialValues = () => {
  const today = startOfDay(Date.now());
  const tomorrow = addDays(today, 1);

  return {
    startDate: today,
    endDate: tomorrow,
    title: '',
    description: '',
    options: ['', '']
  };
};

export default withFormik<FormProps, FormValues>({
  handleSubmit: (
    values,
    { props, setSubmitting, setError, resetForm, setFieldError }
  ) => {
    setSubmitting(true);
    const { title, description, options, startDate, endDate } = values;
    const { groupId } = props;

    const errors: { [key: string]: string } = {};

    const polls = props.polls.polls.polls!.filter(
      poll => poll.groupId === props.groupId
    );
    const invalidator = getDateInvalidator(polls, startDate);
    const isStartInvalid = invalidator('start')(startDate);
    const isEndInvalid = invalidator('end')(endDate);

    if (isStartInvalid) {
      errors.startDate = 'Invalid Start Date';
    }
    if (isEndInvalid) {
      errors.endDate = 'Invalid End Date';
    }

    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(key => setFieldError(key, errors[key]));
      setSubmitting(false);
      return;
    }

    const format = (date: Date) => Math.floor(date.getTime() / 1000);
    try {
      console.log({ values });
      props.createPoll({
        poll: {
          title,
          description,
          options,
          startDate: format(startDate),
          endDate: format(endOfDay(endDate)),
          groupId
        }
      });
      props.history.replace(`/app/admin/groups/${groupId}/polls`);
    } catch (e) {
      console.log({ e });
    }
  },
  mapPropsToValues: () => ({ ...getInitialValues() }),
  validationSchema: Yup.object().shape({
    startDate: Yup.date().required(),
    endDate: Yup.date().required(),
    title: Yup.string().required(),
    description: Yup.string().required(),
    options: Yup.array(Yup.string().required())
      .required()
      .max(MAX_POLL_OPTIONS)
  })
});
