import firebase from 'firebase/app';
import { all, put, takeLatest } from 'redux-saga/effects';
import { Subtract } from 'utility-types';

import { actions } from '.';
import { Collection } from '../../../constants/collections';
import { BrandError, IBrand } from '../../../types/brand';
import getSubdomain from '../../../utils/getSubdomain';

function* fetchBrand() {
  const subdomain = getSubdomain();
  if (subdomain) {
    const db = firebase.firestore();
    const result: firebase.firestore.QuerySnapshot = yield db
      .collection(Collection.BRANDS)
      .where('subdomain', '==', subdomain)
      .get();
    if (result.empty) {
      return yield put(actions.setBrandError({ error: BrandError.NOT_FOUND }));
    }
    const brand = result.docs[0];
    const brandData = brand.data() as Subtract<IBrand, { id: string }>;
    const brandWithId: IBrand = {
      ...brandData,
      id: brand.id
    };
    yield put(actions.setBrand({ brand: brandWithId }));
  } else {
    yield put(actions.setBrandError({ error: BrandError.NO_SUBDOMAIN }));
  }
}

function* watchFetchBrand() {
  yield takeLatest(actions.fetchBrand, fetchBrand);
}

export default function*() {
  yield all([fetchBrand(), watchFetchBrand()]);
}
