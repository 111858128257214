import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import withDrawerState, { DrawerProps } from '../../enhancers/withDrawerState';

const style = (theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing.unit * 4
    },
    root: {
      textDecoration: 'none'
    },
    text: {
      opacity: 0.8
    }
  });

interface IProps extends WithStyles<typeof style> {
  to: string;
  icon: React.ReactElement<any>;
  nested?: boolean;
}

type Props = IProps & DrawerProps;

const DrawerItemPure: React.SFC<Props> = props => {
  const handleClick = () => props.toggle(false);
  return (
    <NavLink className={props.classes.root} onClick={handleClick} to={props.to}>
      <ListItem
        className={props.nested ? props.classes.nested : undefined}
        button={true}
      >
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText
          classes={{ root: props.classes.text }}
          primary={props.children}
        />
      </ListItem>
    </NavLink>
  );
};

export const DrawerItem = withStyles(style)(withDrawerState(DrawerItemPure));
