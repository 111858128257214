import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import LoginForm from '../../components/LoginForm/LoginForm';
import VerifyCodeForm from '../../components/VerifyCode/VerifyCodeForm';
import AuthGuard from '../../containers/AuthGuard/AuthGuard';
import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withBrandState, { BrandProps } from '../../enhancers/withBrandState';
import withBrandSubdomain, { BrandSubdomainProps } from '../../enhancers/withBrandSubdomain';
import withLoadedState, { LoadedProps } from '../../enhancers/withLoadedState';
import CenterForm from '../../layouts/CenterForm/CenterForm';
import { AuthState } from '../../types/auth';
import TryAgain from './TryAgain';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing.unit * 2
    },
    title: {
      marginBottom: theme.spacing.unit * 2
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    }
  });

type Props = BrandSubdomainProps &
  LoadedProps &
  BrandProps &
  WithStyles<typeof styles> &
  AuthProps;

const Login: React.FC<Props> = props => {
  const getComponent = () => {
    switch (props.auth.state) {
      case AuthState.AWAITING_CODE_INPUT: {
        return (
          <React.Fragment>
            <VerifyCodeForm />
            <Line />
            <Button fullWidth onClick={() => window.location.reload()}>
              Use a different number
            </Button>
          </React.Fragment>
        );
      }
      case AuthState.UNAUTHORISED: {
        return <TryAgain />;
      }
      case AuthState.FETCHING_USER_DATA: {
        return (
          <Container>
            <CircularProgress />
          </Container>
        );
      }
      default: {
        return <LoginForm />;
      }
    }
  };
  if (!props.loaded.brand) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }
  return (
    <AuthGuard
      allowedStates={[
        AuthState.LOADED,
        AuthState.LOGGED_OUT,
        AuthState.LOGGING_IN,
        AuthState.AWAITING_CODE_INPUT,
        AuthState.VERIFYING_CODE,
        AuthState.FETCHING_USER_DATA,
        AuthState.UNAUTHORISED
      ]}
      onFail={{ action: 'redirect', to: '/app/admin/groups' }}
    >
      <CenterForm>
        <Typography variant="h5" className={props.classes.title}>
          Log in to {props.brand.brand ? props.brand.brand.alias : ''} CMS
        </Typography>
        {getComponent()}
        <Button
          fullWidth
          component={props => (
            <Link {...props} to={`/app/admin/email-login`}>
              {props.children}
            </Link>
          )}
        >
          Login with Email
        </Button>
      </CenterForm>
    </AuthGuard>
  );
};

const Line = styled.div`
  width: 100%;
  background: #707070;
  height: 1px;
  margin: 16px auto;
  margin-bottom: 3px;
  opacity: 0.17;
`;

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withAuthState(
  withBrandState(withLoadedState(withBrandSubdomain(withStyles(styles)(Login))))
);
