import { Typography } from '@material-ui/core';
import * as React from 'react';

interface IProps {
  className?: string;
}

type Props = IProps;

const Title: React.FC<Props> = props => {
  return (
    <Typography classes={{ root: props.className }} variant="h6">
      {props.children}
    </Typography>
  );
};

export default Title;
