import Fab from '@material-ui/core/Fab';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LocationIcon from '@material-ui/icons/LocationOn';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import withBrand, { BrandProps } from '../../enhancers/withBrandState';
import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';
import withWindowState, { WindowProps } from '../../enhancers/withWindowState';
import withGroupRequestForm, { GroupRequestFormProps } from './withGroupRequestForm';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing.unit * 2
    },
    button: {
      marginTop: theme.spacing.unit * 2
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    cta: {
      textTransform: 'initial',
      width: '100%',
      fontWeight: 400,
      fontSize: 18,
      marginTop: 26
    }
  });

type Props = WithStyles<typeof styles> &
  BrandProps &
  WindowProps &
  GroupRequestFormProps &
  GroupsProps &
  RouteComponentProps;

const getAddressUrl = (pos: Position) =>
  `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
    pos.coords.latitude
  },${pos.coords.longitude}&key=${process.env.REACT_APP_MAPS_API_KEY}`;

interface IMapResponse {
  status: string;
  results: Array<{
    address_components: Array<{
      short_name: string;
      long_name: string;
      types: Array<
        | 'sublocality'
        | 'administrative_area_level_2'
        | 'administrative_area_level_1'
        | 'postal_code'
      >;
    }>;
  }>;
}

interface IState {
  fetching: boolean;
}

class GroupRequestForm extends React.Component<Props, IState> {
  state = {
    fetching: true
  };
  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          fetch(getAddressUrl(pos))
            .then(res => {
              if (res.status !== 200) {
                throw new Error('Unknown Error');
              }
              return res;
            })
            .then(res => res.json())
            .then((res: IMapResponse) => {
              if (res) {
                if (res.status === 'OK') {
                  if (res.results) {
                    const closest = res.results[0];
                    if (closest) {
                      const locationArr: string[] = [];
                      const suburbResult = closest.address_components.find(
                        comp => comp.types.includes('sublocality')
                      );
                      if (suburbResult) {
                        locationArr.push(suburbResult.short_name);
                      }

                      const cityResult = closest.address_components.find(comp =>
                        comp.types.includes('administrative_area_level_2')
                      );
                      if (cityResult) {
                        locationArr.push(cityResult.short_name);
                      }

                      const provinceResult = closest.address_components.find(
                        comp =>
                          comp.types.includes('administrative_area_level_1')
                      );
                      if (provinceResult) {
                        locationArr.push(provinceResult.long_name);
                      }

                      const postalCodeResult = closest.address_components.find(
                        comp => comp.types.includes('postal_code')
                      );
                      if (postalCodeResult) {
                        locationArr.push(postalCodeResult.short_name);
                      }

                      const locationString = locationArr.join(', ');
                      this.props.setFieldValue(
                        'locationString',
                        locationString
                      );
                      this.setState({ fetching: false });
                    }
                  }
                }
              }
            })
            .catch(e => {
              console.log(e);
              this.setState({ fetching: false });
            });
        },
        err => {
          this.setState({ fetching: false });
        }
      );
    } else {
      this.setState({ fetching: false });
    }
  }
  render() {
    const { props } = this;
    const requestForm = (
      <form onSubmit={props.handleSubmit}>
        <Typography variant="subtitle1">Group Details</Typography>
        <TextField
          label="Group Name"
          value={props.values.name}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          name="name"
          fullWidth
          variant="outlined"
          error={props.touched.name && !!props.errors.name}
          helperText={props.touched.name ? props.errors.name : ''}
          margin="normal"
        />
        <TextField
          label="Group Location"
          name="locationString"
          value={props.values.locationString}
          disabled={this.state.fetching}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          fullWidth
          variant="outlined"
          error={props.touched.locationString && !!props.errors.locationString}
          helperText={
            props.touched.locationString ? props.errors.locationString : ''
          }
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LocationIcon />
              </InputAdornment>
            )
          }}
        />
        <Fab
          disabled={this.props.isSubmitting || !this.props.isValid}
          onClick={props.submitForm}
          variant="extended"
          color="primary"
          aria-label="Add"
          classes={{
            root: this.props.classes.cta
          }}
        >
          Group Description
        </Fab>
      </form>
    );

    return requestForm;
  }
}

export default withRouter(
  withBrand(
    withGroupsState(
      withGroupRequestForm(
        withWindowState(withStyles(styles)(GroupRequestForm))
      )
    )
  )
);
