import { InjectedFormikProps, withFormik } from 'formik';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';

import { BrandProps } from '../../enhancers/withBrandState';
import { GroupsProps } from '../../enhancers/withGroupsState';

type FormValues = {
  description: string;
  terms: string;
};

interface IFormProps {
  onSubmit?: (values: FormValues) => void;
}

type FormProps = IFormProps & GroupsProps & BrandProps & RouteComponentProps;

export type GroupDescriptionFormProps = InjectedFormikProps<
  FormProps,
  FormValues
> &
  Partial<FormValues>;

const initialValues = {
  description: '',
  terms: ''
};

export default withFormik<FormProps, FormValues>({
  handleSubmit: (values, { props, setSubmitting, setError, resetForm }) => {
    setSubmitting(true);
    try {
      props.setGroupDescription({ description: values.description });
    } catch (e) {
      console.log({ e });
    }
  },
  mapPropsToValues: () => ({ ...initialValues }),
  validationSchema: Yup.object().shape({
    description: Yup.string().required(),
    terms: Yup.boolean()
      .required()
      .oneOf([true], 'Please accept Terms')
  })
});
