import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import GroupsList from '../../components/GroupsList/GroupsList';
import SearchField from '../../components/SearchField/SearchField';
import AuthGuard from '../../containers/AuthGuard/AuthGuard';
import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import DefaultPage from '../../layouts/DefaultPage/DefaultPage';
import theme from '../../theme';
import { loggedInStates } from '../../types/auth';

type Props = GroupsProps;

const Groups: React.SFC<Props> = props => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [count, incrementCount, resetCount] = useInfiniteScroll();
  const contentRef = React.useRef<HTMLDivElement>(null);

  return (
    <AuthGuard allowedStates={loggedInStates}>
      <DefaultPage>
        <div
          style={{ height: 'calc(100vh - 124px)', overflowY: 'auto' }}
          ref={contentRef}
        >
          <SearchContainer>
            <SearchField
              value={searchTerm}
              onChange={value => {
                setSearchTerm(value);
                resetCount();
              }}
              placeholder="Search Groups"
            />
          </SearchContainer>
          <GroupsList
            count={count}
            incrementCount={incrementCount}
            scrollRef={contentRef}
            filterText={searchTerm}
          />
          <FabContainer>
            <Fab
              component={props => (
                <Link {...props} to="/app/request">
                  {props.children}
                </Link>
              )}
              color="secondary"
              aria-label="Add"
            >
              <AddIcon />
            </Fab>
          </FabContainer>
        </div>
      </DefaultPage>
    </AuthGuard>
  );
};

const SearchContainer = styled.div`
  padding: 15px;
  background: rgba(0, 0, 0, 0.05);
`;

const FabContainer = styled.div`
  position: fixed;
  bottom: ${p => theme.spacing.unit * 2}px;
  right: ${p => theme.spacing.unit * 2}px;
`;

export default withGroupsState(Groups);
