import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import styled from 'styled-components';

const NotFound: React.FC = props => {
  return (
    <Container>
      <Inner>
        <Typography variant="h1">404</Typography>
        <Typography variant="subtitle1">not found</Typography>
      </Inner>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Inner = styled.div`
  text-align: center;
`;

export default NotFound;
