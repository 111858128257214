import { RefObject } from 'react';
import { createStandardAction } from 'typesafe-actions';

import { AuthError, AuthState, AuthTier } from '../../../types/auth';
import { IUser } from '../../../types/users';

const SET_RECAPTCHA = 'auth/SET_RECAPTCHA';
const VERIFY_RECAPTCHA = 'auth/VERIFY_RECAPTCHA';
const LOGIN = 'auth/LOGIN';
const LOGOUT = 'auth/LOGOUT';
const REQUEST_LOGOUT = 'auth/REQUEST_LOGOUT';
const SET_AUTH_STATE = 'auth/SET_STATE';
const SET_USER = 'auth/SET_USER';
const SET_AUTH_ERROR = 'auth/SET_AUTH_ERROR';
const VERIFY_CODE = 'auth/VERIFY_CODE';
const SET_USER_DATA = 'auth/SET_USER_DATA';
const ACCEPT_INVITATION = 'auth/ACCEPT_INVITATION';
const TOKEN_LOGIN = 'auth/TOKEN_LOGIN';
const EMAIL_LOGIN = 'auth/EMAIL_LOGIN';
const WATCH_USER_DATA = 'auth/WATCH_USER_DATA';
const STOP_WATCHING_USER_DATA = 'auth/STOP_WATCHING_USER_DATA';

const LOGGED_IN_ACCEPT_INVITE = 'auth/LOGGED_IN_ACCEPT_INVITE';
const DECLINE_INVITE = 'auth/DECLINE_INVITE';

export const watchUserData = createStandardAction(WATCH_USER_DATA)();
export const stopWatchingUserData = createStandardAction(
  STOP_WATCHING_USER_DATA
)();

export interface ISetRecaptchaPayload {
  recaptcha: firebase.auth.RecaptchaVerifier | null;
}

export const setRecaptcha = createStandardAction(SET_RECAPTCHA)<
  ISetRecaptchaPayload
>();

export const verifyRecaptcha = createStandardAction(VERIFY_RECAPTCHA)();

export interface ILoginPayload {
  mobileNumber: string;
}

export const login = createStandardAction(LOGIN)<ILoginPayload>();

export interface ISetAuthStatePayload {
  state: AuthState;
}

export const setAuthState = createStandardAction(SET_AUTH_STATE)<
  ISetAuthStatePayload
>();

export interface ISetUserPayload {
  user: firebase.User | null;
}

export const setUser = createStandardAction(SET_USER)<ISetUserPayload>();

export interface ISetAuthErrorPayload {
  error: AuthError | null;
}

export const setAuthError = createStandardAction(SET_AUTH_ERROR)<
  ISetAuthErrorPayload
>();

export interface IVerifyCodePayload {
  code: string;
}

export const verifyCode = createStandardAction(VERIFY_CODE)<
  IVerifyCodePayload
>();

export interface ISetUserDataPayload {
  tier?: AuthTier;
  data?: IUser;
}

export const setUserData = createStandardAction(SET_USER_DATA)<
  ISetUserDataPayload
>();

export const logout = createStandardAction(LOGOUT)();
export const requestLogout = createStandardAction(REQUEST_LOGOUT)();

export interface IAcceptInvitationPayload {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  inviteId: string;
  email: string;
  password: string;
}

export const acceptInvitation = createStandardAction(ACCEPT_INVITATION)<
  IAcceptInvitationPayload
>();

export interface ITokenLoginPayload {
  token: string;
  redirectTo?: string;
}

export const tokenLogin = createStandardAction(TOKEN_LOGIN)<
  ITokenLoginPayload
>();

export interface IEmailLoginPayload {
  email: string;
  password: string;
}

export const emailLogin = createStandardAction(EMAIL_LOGIN)<
  IEmailLoginPayload
>();

export interface ILoggedInAcceptInvitePayload {
  id: string;
}

export const loggedInAcceptInvite = createStandardAction(
  LOGGED_IN_ACCEPT_INVITE
)<ILoggedInAcceptInvitePayload>();

export interface IDeclineInvitePayload {
  id: string;
}

export const declineInvite = createStandardAction(DECLINE_INVITE)<
  IDeclineInvitePayload
>();
