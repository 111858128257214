import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import styled from 'styled-components';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withUiState, { UiProps } from '../../enhancers/withUiState';
import withUsersState, { UsersProps } from '../../enhancers/withUsersState';
import theme from '../../theme';
import { IUser, UserState } from '../../types/users';

interface IProps {
  item: IUser;
}

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      paddingBottom: 8,
      paddingTop: 3
    },
    listItemText: {
      fontSize: '0.875rem'
    }
  });

type Props = IProps &
  UsersProps &
  AuthProps &
  UiProps &
  WithStyles<typeof styles>;

const InviteeListItem: React.FC<Props> = props => {
  const getSecondary = () => {
    switch (props.item.state) {
      case UserState.DELETING: {
        return (
          <ListItemSecondaryAction>
            <CircularProgress />
          </ListItemSecondaryAction>
        );
      }
      default: {
        return (
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Delete"
              disabled={props.item.uid === props.auth.user!.uid}
              onClick={() => {
                props.confirmAction({
                  title: 'Are you sure you want to delete this user?',
                  description: 'Their admin rights will be revoked.',
                  color: 'secondary',
                  confirmText: 'Delete',
                  action: () => props.deleteUser({ id: props.item.uid })
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        );
      }
    }
  };

  return (
    <Container>
      <ListItem
        disabled={props.item.state === UserState.DELETING}
        classes={{
          root: props.classes.listItem
        }}
      >
        <ListItemText
          classes={{
            primary: props.classes.listItemText,
            secondary: props.classes.listItemText
          }}
          primary={props.item.firstName + ' ' + props.item.lastName}
          {...(props.item.uid === props.auth.user!.uid
            ? { secondary: 'You' }
            : {})}
        />
        {getSecondary()}
      </ListItem>
      <Divider />
    </Container>
  );
};

const Container = styled.div`
  margin: ${p => theme.spacing.unit}px 0;
`;

export default withStyles(styles)(
  withUiState(withAuthState(withUsersState(InviteeListItem)))
);
