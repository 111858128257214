import Button from '@material-ui/core/Button';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/app';
import MuiPhoneNumber from 'material-ui-phone-number';
import * as React from 'react';
import styled from 'styled-components';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withBrand, { BrandProps } from '../../enhancers/withBrandState';
import withGroupRequestsState from '../../enhancers/withGroupRequestsState';
import withWindowState from '../../enhancers/withWindowState';
import theme from '../../theme';
import withAcceptInvitationForm, { AcceptInvitationFormProps } from './withAcceptInvitationForm';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing.unit * 2
    },
    button: {
      marginTop: theme.spacing.unit * 2
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  });

type Props = BrandProps &
  AcceptInvitationFormProps &
  WithStyles<typeof styles> &
  AuthProps;

const AcceptInvitationForm: React.FC<Props> = props => {
  const [mounted, setMounted] = React.useState<boolean>(false);

  const recaptchaEl = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
      const recaptcha = new firebase.auth.RecaptchaVerifier(
        recaptchaEl.current,
        {
          size: 'normal',
          callback: () => {
            props.setFieldValue('verifiedCaptcha', true);
          }
        }
      );
      props.setRecaptcha({ recaptcha });
      props.verifyRecaptcha();
    }
  }, [mounted]);

  const requestForm = (
    <form onSubmit={props.handleSubmit}>
      <Typography variant="subtitle1">Your Details</Typography>
      <TextField
        label="First Name"
        name="firstName"
        value={props.values.firstName}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        fullWidth
        variant="outlined"
        error={props.touched.firstName && !!props.errors.firstName}
        helperText={props.touched.firstName ? props.errors.firstName : ''}
        margin="normal"
      />
      <TextField
        label="Last Name"
        name="lastName"
        value={props.values.lastName}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        error={props.touched.lastName && !!props.errors.lastName}
        helperText={props.touched.lastName ? props.errors.lastName : ''}
        fullWidth
        variant="outlined"
        margin="normal"
      />
      <MuiPhoneNumber
        defaultCountry="za"
        label="Mobile Number"
        type="tel"
        value={props.values.mobileNumber}
        onChange={props.handleChange('mobileNumber')}
        onBlur={props.handleBlur('mobileNumber')}
        disabled={props.isSubmitting}
        name="mobileNumber"
        countryCodeEditable={false}
        fullWidth
        error={props.touched.mobileNumber && !!props.errors.mobileNumber}
        helperText={props.touched.mobileNumber ? props.errors.mobileNumber : ''}
        margin="normal"
      />
      <TextField
        label="Email"
        value={props.values.email}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        name="email"
        fullWidth
        type="email"
        variant="outlined"
        error={props.touched.email && !!props.errors.email}
        helperText={props.touched.email ? props.errors.email : ''}
        margin="normal"
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        value={props.values.password}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        fullWidth
        variant="outlined"
        error={props.touched.password && !!props.errors.password}
        helperText={props.touched.password ? props.errors.password : ''}
        margin="normal"
      />
      <RecaptchaContainer>
        <div id="recaptcha" ref={recaptchaEl} />
      </RecaptchaContainer>

      {props.touched.verifiedCaptcha && props.errors.verifiedCaptcha && (
        <Typography color="error">{props.errors.verifiedCaptcha}</Typography>
      )}

      <Button
        variant="contained"
        className={props.classes.button}
        color="primary"
        fullWidth
        onClick={() => props.submitForm()}
        type="submit"
      >
        Submit
      </Button>
    </form>
  );

  return requestForm;
};

const RecaptchaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${p => theme.spacing.unit * 2}px;
`;

export default withBrand(
  withAuthState(
    withGroupRequestsState(
      withAcceptInvitationForm(
        withWindowState(withStyles(styles)(AcceptInvitationForm))
      )
    )
  )
);
