import * as React from 'react';
import { Subtract } from 'utility-types';

import getSubdomain from '../utils/getSubdomain';

export type BrandSubdomainProps = {
  brandSubdomain: string | undefined;
};

const withBrandSubdomain = <P extends BrandSubdomainProps>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, BrandSubdomainProps>> => props => {
  let brandSubdomain = getSubdomain();

  return <Component {...props as P} brandSubdomain={brandSubdomain} />;
};

export default withBrandSubdomain;
