import { AuthTier } from './auth';

export enum InviteState {
  CREATING,
  CREATED,
  DELETING
}

export enum InviteStatus {
  PENDING,
  ACCEPTED,
  EXPIRED,
  DELETED
}

export interface ITier12InviteInput {
  email: string;
}

export type Invitee = ITier12InviteInput & {
  id: string;
  state?: InviteState;
  brandId: string;
  groupId?: string;
  groupName?: string;
  status: InviteStatus;
  tier: AuthTier;
  lastSent?: number;
  timesSent?: number;
  createdAt: number;
};

export enum UserState {
  DELETING
}

export interface IUser {
  id?: string;
  uid: string;
  firstName: string;
  lastName: string;
  state?: UserState;
  brandId?: string;
  tier3Ids: string[];
  tier4Ids: string[];
  isTier1: boolean;
}
