import { CircularProgress } from '@material-ui/core';
import queryString from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import NewPostForm from '../../components/NewPostForm/NewPostForm';
import NotFound from '../../components/NotFound/NotFound';
import withPostsState, { PostsProps } from '../../enhancers/withPostsState';
import BackScreen from '../../layouts/BackScreen/BackScreen';
import CenterForm from '../../layouts/CenterForm/CenterForm';
import { IPost } from '../../types/post';

type Props = RouteComponentProps<{ id: string; postId?: string }> & PostsProps;

const NewPost: React.FC<Props> = props => {
  const postId = props.match.params.postId;
  let post: IPost | undefined;
  const { posts } = props.posts;
  if (!posts.loaded || !posts.posts) {
    return <CircularProgress />;
  }
  if (postId) {
    post = posts.posts!.find(thePost => thePost.id === postId);
    if (!post) {
      return <NotFound />;
    }
  }

  const value = queryString.parse(window.location.search);
  const isApp = !!value.app;
  return (
    <BackScreen
      title="New Post"
      backTo={isApp ? `lookatyouparty://group/${props.match.params.id}` : ''}
    >
      <CenterForm>
        <NewPostForm
          groupId={props.match.params.id}
          post={post}
          isApp={isApp}
        />
      </CenterForm>
    </BackScreen>
  );
};

export default withPostsState(NewPost);
