import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#0085eb'
    }
  },
  overrides: {
    MuiFab: {
      label: {
        fontSize: 16
      }
    }
  }
});
