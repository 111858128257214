import MaterialAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import * as React from 'react';

import withBrandState, { BrandProps } from '../../enhancers/withBrandState';
import withDrawerState, { DrawerProps } from '../../enhancers/withDrawerState';
import LogoutButton from '../LogoutButton/LogoutButton';

const styles = createStyles({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  root: {
    flexGrow: 1
  }
});

interface IProps {
  tabs?: React.ReactNode;
  title?: string;
}

type Props = WithStyles<typeof styles> & DrawerProps & IProps & BrandProps;

const AppBar: React.SFC<Props> = props => {
  const toggle = () => props.toggle(true);
  return (
    <MaterialAppBar>
      <Toolbar>
        <IconButton
          className={props.classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={toggle}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={props.classes.grow}>
          {props.title || (props.brand.brand && props.brand.brand.alias)}
        </Typography>
        <LogoutButton />
      </Toolbar>
      {props.tabs}
    </MaterialAppBar>
  );
};

export default withBrandState(withStyles(styles)(withDrawerState(AppBar)));
