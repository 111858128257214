import Fab from '@material-ui/core/Fab';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from 'material-ui-pickers';
import * as React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import withPollsState, { PollsProps } from '../../enhancers/withPollsState';
import { IPoll } from '../../types/poll';
import getDateInvalidator from '../../utils/getDateInvalidator';
import withEditPollForm, { EditPollFormProps } from './withEditPollForm';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing.unit * 2
    },
    button: {
      marginTop: theme.spacing.unit * 2
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    cta: {
      textTransform: 'initial',
      width: '100%',
      fontWeight: 400,
      fontSize: 18,
      marginTop: 26
    }
  });

type Props = WithStyles<typeof styles> &
  EditPollFormProps &
  PollsProps & { groupId: string; poll: IPoll };

const EditPollForm: React.FC<Props> = props => {
  let now = new Date();
  const { polls } = props.polls;

  let pollsArray: IPoll[] = [];

  if (polls.loaded && polls.polls) {
    pollsArray = [...polls.polls].filter(
      poll => poll.groupId === props.groupId
    );
  }

  const invalidator = getDateInvalidator(
    pollsArray.filter(poll => poll.id !== props.poll.id),
    props.values.startDate
  );

  const theForm = (
    <form onSubmit={props.handleSubmit}>
      <Typography gutterBottom variant="h5" component="h4">
        {props.poll.title}
      </Typography>
      <DateSection>
        <DatePicker
          label="Start Date"
          value={props.values.startDate}
          minDate={new Date()}
          onChange={props.handleChange('startDate')}
          animateYearScrolling
          shouldDisableDate={invalidator('start')}
          name="startDate"
          onBlur={props.handleBlur('startDate')}
          error={props.touched.startDate && !!props.errors.startDate}
          helperText={props.touched.startDate ? props.errors.startDate : ''}
        />
        <DatePicker
          label="End Date"
          value={props.values.endDate}
          minDate={props.values.startDate ? props.values.startDate : now}
          onChange={props.handleChange('endDate')}
          animateYearScrolling
          shouldDisableDate={invalidator('end')}
          name="endDate"
          onBlur={props.handleBlur('endDate')}
          error={props.touched.endDate && !!props.errors.endDate}
          helperText={props.touched.endDate ? props.errors.endDate : ''}
        />
      </DateSection>
      <Fab
        disabled={props.isSubmitting || !props.isValid}
        onClick={props.submitForm}
        variant="extended"
        color="primary"
        aria-label="Add"
        classes={{
          root: props.classes.cta
        }}
      >
        Update
      </Fab>
    </form>
  );

  return theForm;
};

const DateSection = styled.div`
  display: flex;
`;

export default withRouter(
  withPollsState(withEditPollForm(withStyles(styles)(EditPollForm)))
);
