import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';
import withUiState from '../../enhancers/withUiState';
import withWindowState, { WindowProps } from '../../enhancers/withWindowState';
import withGroupSettingsForm, { GroupSettingsFormProps, IFormProps } from './withGroupSettingsForm';

const styles = (theme: Theme) =>
  createStyles({
    light: {
      opacity: 0.8,
      fontSize: 14
    },
    title: {
      marginBottom: theme.spacing.unit * 2
    },
    button: {
      marginTop: theme.spacing.unit * 2
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    cta: {
      textTransform: 'initial',
      width: '100%',
      fontWeight: 400,
      fontSize: 18,
      marginTop: 26
    }
  });

type Props = WithStyles<typeof styles> &
  WindowProps &
  GroupSettingsFormProps &
  GroupsProps &
  RouteComponentProps &
  IFormProps;

class GroupSettingsForm extends React.Component<Props> {
  render() {
    const { props } = this;
    const { group } = props;
    console.log({ props });
    const requestForm = (
      <form onSubmit={props.handleSubmit}>
        <TextField
          label="Group Name"
          value={props.values.name}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          name="name"
          fullWidth
          variant="outlined"
          error={props.touched.name && !!props.errors.name}
          helperText={props.touched.name ? props.errors.name : ''}
          margin="normal"
        />
        <TextField
          multiline
          name="description"
          label="Description"
          value={props.values.description}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          fullWidth
          rows={8}
          variant="outlined"
          error={props.touched.description && !!props.errors.description}
          helperText={props.touched.description ? props.errors.description : ''}
          margin="normal"
        />
        <FormControlLabel
          control={
            <Switch
              checked={props.values.tier3PostOnly}
              onChange={props.handleChange('tier3PostOnly')}
              color="default"
            />
          }
          label="Only allow organisers to post"
        />
        <FormControlLabel
          control={
            <Switch
              checked={props.values.public}
              onChange={props.handleChange('public')}
              color="default"
            />
          }
          label="Make this group public"
        />
        <FormControlLabel
          disabled={!props.values.public}
          control={
            <Switch
              checked={props.values.public ? props.values.publicJoin : false}
              onChange={props.handleChange('publicJoin')}
              color="default"
            />
          }
          label="Allow anybody to join this group"
        />
        <FormControlLabel
          control={
            <Switch
              checked={group.active}
              disabled={typeof props.group.state === 'number'}
              onClick={() => {
                props.confirmAction({
                  title: 'Are you sure?',
                  confirmText: group.active ? 'Disable' : 'Enable',
                  color: group.active ? 'secondary' : 'primary',
                  action: () =>
                    props.setGroupActiveState({
                      id: group.id,
                      active: !group.active
                    })
                });
              }}
              color="default"
            />
          }
          label={`Enable Group`}
        />
        <Divider style={{ marginTop: 16 }} />
        <Fab
          disabled={!this.props.isValid}
          onClick={props.submitForm}
          variant="extended"
          color="primary"
          aria-label="Add"
          classes={{
            root: this.props.classes.cta
          }}
        >
          Update Group
        </Fab>
      </form>
    );

    return requestForm;
  }
}

const TermsLink = styled(Link)`
  color: black;
`;

export default withRouter(
  withUiState(
    withGroupsState(
      withGroupSettingsForm(
        withWindowState(withStyles(styles)(GroupSettingsForm))
      )
    )
  )
);
