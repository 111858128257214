import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  createGroup,
  deleteGroupInvitee,
  deleteGroupUser,
  ICreateGroupPayload,
  IDeleteGroupInviteePayload,
  IDeleteGroupUserPayload,
  IInviteGroupUserPayload,
  inviteGroupUser,
  ISetGroupDescriptionPayload,
  setGroupDescription,
  watchGroups,
  updateGroupDetails,
  IUpdateGroupDetailsPayload,
  setGroupActiveState,
  ISetGroupActiveStatePayload,
  deleteGroup,
  IDeleteGroupPayload
} from '../store/modules/groups/actions';
import { IGroupsState } from '../store/modules/groups/reducer';
import { RootState } from '../types/store';

const mapStateToProps = (state: RootState) => ({
  groups: state.groups
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createGroup,
      setGroupDescription,
      watchGroups,
      inviteGroupUser,
      deleteGroupInvitee,
      deleteGroupUser,
      updateGroupDetails,
      setGroupActiveState,
      deleteGroup
    },
    dispatch
  );

interface IGroupsDispatch {
  createGroup: (payload: ICreateGroupPayload) => void;
  setGroupDescription: (payload: ISetGroupDescriptionPayload) => void;
  watchGroups: () => void;
  inviteGroupUser: (payload: IInviteGroupUserPayload) => void;
  deleteGroupInvitee: (payload: IDeleteGroupInviteePayload) => void;
  deleteGroupUser: (payload: IDeleteGroupUserPayload) => void;
  updateGroupDetails: (payload: IUpdateGroupDetailsPayload) => void;
  setGroupActiveState: (payload: ISetGroupActiveStatePayload) => void;
  deleteGroup: (payload: IDeleteGroupPayload) => void;
}

interface IGroupsReduxState {
  groups: IGroupsState;
}

export type GroupsProps = IGroupsDispatch & IGroupsReduxState;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
