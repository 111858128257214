import firebase from 'firebase/app';
import { NotificationManager } from 'react-notifications';
import { Action } from 'redux';
import { END, EventChannel, eventChannel } from 'redux-saga';
import { all, cancel, cancelled, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { Subtract } from 'utility-types';

import { actions } from '.';
import { Collection } from '../../../constants/collections';
import { AuthTier } from '../../../types/auth';
import { IPoll, PollState, PollStatus } from '../../../types/poll';
import { RootState } from '../../../types/store';
import { actions as authActions } from '../auth';
import { ICreatePollPayload, IDeletePollPayload, ISetPollStatusPayload, IUpdatePollDatePayload } from './actions';

const functionsEndpoint = process.env.REACT_APP_FUNCTIONS_ENDPOINT!;

function* handlePollDocChanges(
  change: firebase.firestore.DocumentChange | END | { type: 'START' }
) {
  if (change.type === 'START') {
    yield put(actions.setPollsLoaded({ loaded: true }));
    yield put(actions.setPolls({ polls: [] }));
    return;
  }
  if (change.type === '@@redux-saga/CHANNEL_END') {
    throw new Error('closed');
  }
  const store: RootState = yield select();
  const polls: IPoll[] = store.polls.polls.polls || [];
  const poll: IPoll = {
    ...(change.doc.data() as Subtract<IPoll, { id: string }>),
    id: change.doc.id!
  };

  const existingPoll = polls.find(thePoll => {
    if (poll.clientId) {
      return thePoll.clientId === poll.clientId || thePoll.id === poll.id;
    }
    return thePoll.id === poll.id;
  });

  console.log({ change, existingPoll });

  if (change.type === 'added') {
    if (existingPoll) {
      yield put(actions.setPoll({ poll }));
    } else {
      yield put(actions.addPoll({ poll }));
    }
  }
  if (change.type === 'modified') {
    yield put(actions.setPoll({ poll }));
  }
  if (change.type === 'removed') {
    if (existingPoll) {
      // TODO: User feedback
    }
    yield put(actions.removePoll({ id: poll.id }));
  }
}

function* fetchPolls() {
  let store: RootState = yield select();
  const userData = store.auth.userData!;

  const result: firebase.firestore.QuerySnapshot = yield firebase
    .firestore()
    .collection(Collection.POLLS)
    .where('brandId', '==', userData.brandId)
    .get();

  const polls = result.docs.map<IPoll>(doc => ({
    id: doc.id,
    ...(doc.data() as Subtract<IPoll, { id: string }>)
  }));

  yield put(actions.setPolls({ polls }));
}

function* handleWatchPolls() {
  let changesChannel!: EventChannel<
    firebase.firestore.DocumentChange | { type: 'START' }
  >;
  try {
    const db = firebase.firestore();

    let store: RootState = yield select();
    if (!store.auth.userData) {
      yield take(authActions.setUserData);
    }

    store = yield select();

    const userDetails = store.auth.userData!;

    yield fetchPolls();

    const queryBase = db
      .collection(Collection.POLLS)
      .where('brandId', '==', userDetails.brandId);

    const pollsChanges = () =>
      eventChannel<firebase.firestore.DocumentChange | { type: 'START' }>(
        emit => {
          let init = false;
          const handleSnapshot = (
            snapshot: firebase.firestore.QuerySnapshot
          ) => {
            if (!init) {
              init = true;
              emit({ type: 'START' });
            }
            snapshot.docChanges().map(emit);
          };
          if (store.auth.tier !== AuthTier.TIER1) {
            const tier3Listeners = userDetails.tier3Ids.map(id =>
              queryBase.where('groupId', '==', id).onSnapshot(handleSnapshot)
            );
            const tier4Listeners = userDetails.tier4Ids.map(id =>
              queryBase.where('groupId', '==', id).onSnapshot(handleSnapshot)
            );
            return () => {
              tier3Listeners.map(unsub => unsub());
              tier4Listeners.map(unsub => unsub());
            };
          } else {
            return queryBase.onSnapshot(handleSnapshot);
          }
        }
      );
    changesChannel = pollsChanges();

    while (true) {
      const doc = yield take(changesChannel);
      yield handlePollDocChanges(doc);
    }
  } finally {
    if (yield cancelled()) {
      if (changesChannel) {
        yield changesChannel.close();
      }
    }
  }
}

function* watchPollsRequest() {
  while (yield take(actions.watchPolls)) {
    const pollsWatcher = yield fork(handleWatchPolls);
    yield take(actions.stopWatchingPolls);
    yield put(actions.clearPolls());
    yield cancel(pollsWatcher);
  }
}

function* handleCreatePoll(action: Action & { payload: ICreatePollPayload }) {
  const state: RootState = yield select();
  const brand = state.brand.brand!;
  const date = +new Date();
  const tempId = '' + date + state.auth.user!.uid;
  const newPoll: IPoll = {
    ...action.payload.poll,
    clientId: tempId,
    id: tempId,
    createdAt: Math.floor(date / 1000),
    updatedAt: Math.floor(date / 1000),
    brandId: brand.id,
    votes: [],
    status: PollStatus.OPEN
  };
  yield put(
    actions.addPoll({ poll: { ...newPoll, state: PollState.CREATING } })
  );
  try {
    const token = yield state.auth.user!.getIdToken();
    const brandId = state.brand.brand!.id;

    const result: Response = yield fetch(functionsEndpoint + '/createPoll', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        brand: brandId,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...newPoll
      })
    });

    switch (result.status) {
      case 200: {
        NotificationManager.success(
          `Your poll titled '${newPoll.title}' has been published`,
          'Poll successfully published'
        );
        break;
      }
      default: {
        throw new Error('Request Failed');
      }
    }
  } catch (e) {
    console.log({ e });
    yield put(actions.removePoll({ id: tempId }));
    // user feedback
  }
}

function* watchCreatePoll() {
  yield takeEvery(actions.createPoll, handleCreatePoll);
}

function* handleUpdatePoll(poll: IPoll) {
  const state: RootState = yield select();
  const polls = state.polls.polls.polls!;
  const oldPoll = polls.find(thePoll => thePoll.id === poll.id)!;

  const newPoll: IPoll = {
    ...poll,
    state: PollState.UPDATING
  };

  yield put(actions.setPoll({ poll: newPoll }));
  try {
    const token = yield state.auth.user!.getIdToken();
    const brandId = state.brand.brand!.id;

    const result: Response = yield fetch(functionsEndpoint + '/updatePoll', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        brand: brandId,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...poll
      })
    });

    switch (result.status) {
      case 200: {
        break;
      }
      default: {
        throw new Error('Request Failed');
      }
    }
  } catch (e) {
    console.log({ e });
    yield put(actions.setPoll({ poll: oldPoll }));
    throw new Error('Failed to update poll');
  }
}

function* handleUpdatePollDates(
  action: Action & { payload: IUpdatePollDatePayload }
) {
  const state: RootState = yield select();
  const { id, startDate, endDate } = action.payload;
  const polls = state.polls.polls.polls!;
  const poll = polls.find(thePoll => thePoll.id === id);
  if (poll) {
    const newPoll: IPoll = {
      ...poll,
      startDate,
      endDate,
      updatedAt: Math.floor(+new Date() / 1000)
    };
    try {
      yield handleUpdatePoll(newPoll);
      NotificationManager.success('Successfully updated poll');
    } catch (e) {
      NotificationManager.error('Failed to update poll');
    }
  }
}

function* watchUpdatePollDates() {
  yield takeEvery(actions.updatePollDate, handleUpdatePollDates);
}

function* handleSetPollActiveState(
  action: Action & { payload: ISetPollStatusPayload }
) {
  const state: RootState = yield select();
  const { id, status } = action.payload;
  const polls = state.polls.polls.polls!;
  const poll = polls.find(thePoll => thePoll.id === id);
  if (poll) {
    const newPoll: IPoll = {
      ...poll,
      status
    };
    try {
      yield handleUpdatePoll(newPoll);
      NotificationManager.success(
        `Successfully ${status === PollStatus.OPEN ? 'opened' : 'closed'} poll`
      );
    } catch (e) {
      console.log({ e });
      NotificationManager.error(
        `Failed to ${status === PollStatus.OPEN ? 'open' : 'close'} poll`
      );
    }
  }
}

function* watchSetPollActiveState() {
  yield takeEvery(actions.setPollStatus, handleSetPollActiveState);
}

function* handleDeletePoll(action: Action & { payload: IDeletePollPayload }) {
  const state: RootState = yield select();
  const { id } = action.payload;
  const polls = state.polls.polls.polls!;
  const poll = polls.find(thePoll => thePoll.id === id);
  if (poll) {
    const newPoll: IPoll = {
      ...poll,
      state: PollState.DELETING
    };
    yield put(actions.setPoll({ poll: newPoll }));
    try {
      const token = yield state.auth.user!.getIdToken();
      const brandId = state.brand.brand!.id;

      const result: Response = yield fetch(functionsEndpoint + '/deletePoll', {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
          brand: brandId,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id,
          groupId: poll.groupId
        })
      });

      switch (result.status) {
        case 200: {
          break;
        }
        default: {
          throw new Error('Request Failed');
        }
      }
      NotificationManager.success('Successfully deleted poll');
    } catch (e) {
      console.log({ e });
      NotificationManager.error('Failed to delete poll');
      yield put(actions.setPoll({ poll }));
    }
  }
}

function* watchDeletePolls() {
  yield takeEvery(actions.deletePoll, handleDeletePoll);
}

export default function*() {
  yield all([
    watchPollsRequest(),
    watchCreatePoll(),
    watchSetPollActiveState(),
    watchDeletePolls(),
    watchUpdatePollDates()
  ]);
}
