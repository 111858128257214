import * as React from 'react';

import UserInviteForm from '../../components/UserInviteForm/UserInviteForm';
import AuthGuard from '../../containers/AuthGuard/AuthGuard';
import BackScreen from '../../layouts/BackScreen/BackScreen';
import Section from '../../layouts/Section/Section';
import { AuthTier } from '../../types/auth';

const InviteUser: React.FC = props => {
  return (
    <AuthGuard allowedTiers={[AuthTier.TIER1]}>
      <BackScreen title="Invite an admin">
        <Section>
          <UserInviteForm />
        </Section>
      </BackScreen>
    </AuthGuard>
  );
};

export default InviteUser;
