import * as React from 'react';

import GroupRequestForm from '../../components/GroupRequestForm/GroupRequestForm';
import HeaderScreen from '../../containers/HeaderScreen/HeaderScreen';

const CreateGroup: React.FC = props => {
  return (
    <HeaderScreen title="Create a Group" progress={33}>
      <GroupRequestForm />
    </HeaderScreen>
  );
};

export default CreateGroup;
