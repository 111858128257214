import { InjectedFormikProps, withFormik } from 'formik';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';

import { BrandProps } from '../../enhancers/withBrandState';
import { GroupsProps } from '../../enhancers/withGroupsState';

type FormValues = {
  name: string;
  locationString: string;
};

interface IFormProps {
  onSubmit?: (values: FormValues) => void;
}

type FormProps = IFormProps & GroupsProps & BrandProps & RouteComponentProps;

export type GroupRequestFormProps = InjectedFormikProps<FormProps, FormValues> &
  Partial<FormValues>;

const initialValues = {
  name: '',
  locationString: ''
};

export default withFormik<FormProps, FormValues>({
  handleSubmit: (values, { props, setSubmitting, setError, resetForm }) => {
    setSubmitting(true);
    try {
      props.createGroup({
        details: {
          name: values.name,
          location: values.locationString
        }
      });
      props.history.replace('/app/request/description');
    } catch (e) {
      console.log({ e });
    }
  },
  mapPropsToValues: () => ({ ...initialValues }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    locationString: Yup.string().required()
  })
});
