import * as React from 'react';
import styled from 'styled-components';

import logo from '../../assets/img/Look@MyParty_White.png';

const VolunteerPreHeader = () => (
  <Head>
    <a href="/app">
      <Logo src={logo} />
    </a>
  </Head>
);

const Logo = styled.img`
  padding: 50px;
  margin-left: 0px;
  width: 280px;
  /* margin-left: 481px; */
  @media (max-width: 750px) {
    margin-left: 0;
  }
  @media screen and (min-width: 1400px) {
    /* margin-left: 800px; */
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

const Head = styled.div`
  background-color: #0085eb;
  text-align: center;
  width: 100%;
`;

export default VolunteerPreHeader;
