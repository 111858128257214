import { all } from 'redux-saga/effects';

import * as auth from './modules/auth';
import * as brand from './modules/brand';
import * as groupRequests from './modules/groupRequests';
import * as groups from './modules/groups';
import * as joinRequests from './modules/joinRequests';
import * as polls from './modules/polls';
import * as posts from './modules/posts';
import * as ui from './modules/ui';
import * as users from './modules/users';
import * as windowModule from './modules/window';

export default function*() {
  yield all([
    windowModule.saga(),
    brand.saga(),
    groupRequests.saga(),
    groups.saga(),
    auth.saga(),
    users.saga(),
    posts.saga(),
    polls.saga(),
    ui.saga(),
    joinRequests.saga()
  ]);
}
