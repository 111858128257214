import { createStandardAction } from 'typesafe-actions';

import { IJoinRequest } from '../../../types/joinRequest';

const WATCH_JOIN_REQUESTS = 'joinRequests/WATCH_JOIN_REQUESTS';
const STOP_WATCHING_JOIN_REQUESTS = 'joinRequests/STOP_WATCHING_JOIN_REQUESTS';
const SET_JOIN_REQUESTS = 'joinRequests/SET_JOIN_REQUESTS';
const SET_JOIN_REQUEST = 'joinRequests/SET_JOIN_REQUEST';
const REMOVE_JOIN_REQUEST = 'joinRequests/REMOVE_JOIN_REQUEST';
const ADD_JOIN_REQUEST = 'joinRequests/ADD_JOIN_REQUEST';
const SET_JOIN_REQUESTS_LOADED = 'joinRequests/SET_LOADED';
const CLEAR_JOIN_REQUESTS = 'joinRequests/CLEAR_JOIN_REQUESTS';
const DELETE_JOIN_REQUEST = 'joinRequests/DELETE_JOIN_REQUEST';
const ACCEPT_JOIN_REQUEST = 'joinRequest/ACCEPT_JOIN_REQUEST';

export const watchJoinRequests = createStandardAction(WATCH_JOIN_REQUESTS)();
export const stopWatchingJoinRequests = createStandardAction(
  STOP_WATCHING_JOIN_REQUESTS
)();

export interface ISetJoinRequestsPayload {
  joinRequests: IJoinRequest[];
}

export const setJoinRequests = createStandardAction(SET_JOIN_REQUESTS)<
  ISetJoinRequestsPayload
>();

export interface ISetJoinRequestPayload {
  joinRequest: IJoinRequest;
}

export const setJoinRequest = createStandardAction(SET_JOIN_REQUEST)<
  ISetJoinRequestPayload
>();

export interface IRemoveJoinRequestPayload {
  id: string;
}

export const removeJoinRequest = createStandardAction(REMOVE_JOIN_REQUEST)<
  IRemoveJoinRequestPayload
>();

export interface IAddJoinRequestPayload {
  joinRequest: IJoinRequest;
}

export const addJoinRequest = createStandardAction(ADD_JOIN_REQUEST)<
  IAddJoinRequestPayload
>();

export interface ISetJoinRequestsLoadedPayload {
  loaded: boolean;
}

export const setJoinRequestsLoaded = createStandardAction(
  SET_JOIN_REQUESTS_LOADED
)<ISetJoinRequestsLoadedPayload>();

export const clearJoinRequests = createStandardAction(CLEAR_JOIN_REQUESTS)();

export interface ISetJoinRequestActiveStatePayload {
  id: string;
  active: boolean;
}

export interface IDeleteJoinRequestPayload {
  id: string;
}

export const deleteJoinRequest = createStandardAction(DELETE_JOIN_REQUEST)<
  IDeleteJoinRequestPayload
>();

export interface IAcceptJoinRequestPayload {
  id: string;
}

export const acceptJoinRequest = createStandardAction(ACCEPT_JOIN_REQUEST)<
  IAcceptJoinRequestPayload
>();
