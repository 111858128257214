import firebase from 'firebase/app';
import { NotificationManager } from 'react-notifications';
import { Action } from 'redux';
import { END, EventChannel, eventChannel } from 'redux-saga';
import { all, cancel, cancelled, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { Subtract } from 'utility-types';

import { actions } from '.';
import { Collection } from '../../../constants/collections';
import { IJoinRequest, JoinRequestState } from '../../../types/joinRequest';
import { RootState } from '../../../types/store';
import { actions as authActions } from '../auth';
import { IDeleteJoinRequestPayload } from './actions';

const functionsEndpoint = process.env.REACT_APP_FUNCTIONS_ENDPOINT!;

function* handleJoinRequestDocChanges(
  change: firebase.firestore.DocumentChange | END | { type: 'START' }
) {
  if (change.type === 'START') {
    yield put(actions.setJoinRequestsLoaded({ loaded: true }));
    yield put(actions.setJoinRequests({ joinRequests: [] }));
    return;
  }
  if (change.type === '@@redux-saga/CHANNEL_END') {
    throw new Error('closed');
  }
  const store: RootState = yield select();
  const joinRequests: IJoinRequest[] =
    store.joinRequests.joinRequests.joinRequests || [];
  const joinRequest: IJoinRequest = {
    ...(change.doc.data() as Subtract<IJoinRequest, { id: string }>),
    id: change.doc.id!
  };

  const existingJoinRequest = joinRequests.find(theJoinRequest => {
    return theJoinRequest.id === joinRequest.id;
  });

  console.log({ change, existingJoinRequest });

  if (change.type === 'added') {
    if (existingJoinRequest) {
      yield put(actions.setJoinRequest({ joinRequest }));
    } else {
      yield put(actions.addJoinRequest({ joinRequest }));
    }
  }
  if (change.type === 'modified') {
    yield put(actions.setJoinRequest({ joinRequest }));
  }
  if (change.type === 'removed') {
    if (existingJoinRequest) {
      // TODO: User feedback
    }
    yield put(actions.removeJoinRequest({ id: joinRequest.id }));
  }
}

function* handleWatchJoinRequests() {
  let changesChannel!: EventChannel<
    firebase.firestore.DocumentChange | { type: 'START' }
  >;
  try {
    const db = firebase.firestore();

    let store: RootState = yield select();
    if (!store.auth.userData) {
      yield take(authActions.setUserData);
    }

    store = yield select();

    const userDetails = store.auth.userData!;
    const queryBase = db.collection(Collection.JOIN_REQUESTS);

    const joinRequestsChanges = () =>
      eventChannel<firebase.firestore.DocumentChange | { type: 'START' }>(
        emit => {
          let init = false;
          const handleSnapshot = (
            snapshot: firebase.firestore.QuerySnapshot
          ) => {
            if (!init) {
              init = true;
              emit({ type: 'START' });
            }
            snapshot.docChanges().map(emit);
          };
          return queryBase
            .where('brandId', '==', userDetails.brandId)
            .onSnapshot(handleSnapshot);
        }
      );
    changesChannel = joinRequestsChanges();

    while (true) {
      const doc = yield take(changesChannel);
      yield handleJoinRequestDocChanges(doc);
    }
  } finally {
    if (yield cancelled()) {
      if (changesChannel) {
        yield changesChannel.close();
      }
    }
  }
}

function* watchJoinRequestsRequest() {
  while (yield take(actions.watchJoinRequests)) {
    const joinRequestsWatcher = yield fork(handleWatchJoinRequests);
    yield take(actions.stopWatchingJoinRequests);
    yield put(actions.clearJoinRequests());
    yield cancel(joinRequestsWatcher);
  }
}

function* handleDeleteJoinRequest(
  action: Action & { payload: IDeleteJoinRequestPayload }
) {
  const state: RootState = yield select();
  const { id } = action.payload;
  const joinRequests = state.joinRequests.joinRequests.joinRequests!;
  const joinRequest = joinRequests.find(
    theJoinRequest => theJoinRequest.id === id
  );
  if (joinRequest) {
    const newJoinRequest: IJoinRequest = {
      ...joinRequest,
      state: JoinRequestState.DELETING
    };
    yield put(actions.setJoinRequest({ joinRequest: newJoinRequest }));
    try {
      let state: RootState = yield select();
      const token = yield state.auth.user!.getIdToken();
      const brandId = state.brand.brand!.id;
      yield fetch(functionsEndpoint + '/respondToJoin', {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
          brand: brandId,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: action.payload.id,
          accept: false
        })
      });
      NotificationManager.success('Successfully deleted request');
    } catch (e) {
      console.log({ e });
      NotificationManager.error('Failed to delete request');
      yield put(actions.setJoinRequest({ joinRequest }));
    }
  }
}

function* watchDeleteJoinRequests() {
  yield takeEvery(actions.deleteJoinRequest, handleDeleteJoinRequest);
}

function* handleAcceptJoinRequest(
  action: Action & { payload: IDeleteJoinRequestPayload }
) {
  const state: RootState = yield select();
  const { id } = action.payload;
  const joinRequests = state.joinRequests.joinRequests.joinRequests!;
  const joinRequest = joinRequests.find(
    theJoinRequest => theJoinRequest.id === id
  );
  if (joinRequest) {
    const newJoinRequest: IJoinRequest = {
      ...joinRequest,
      state: JoinRequestState.DELETING
    };
    yield put(actions.setJoinRequest({ joinRequest: newJoinRequest }));
    try {
      let state: RootState = yield select();
      const token = yield state.auth.user!.getIdToken();
      const brandId = state.brand.brand!.id;
      yield fetch(functionsEndpoint + '/respondToJoin', {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
          brand: brandId,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: action.payload.id,
          accept: true
        })
      });
      NotificationManager.success('Successfully accepted request');
    } catch (e) {
      console.log({ e });
      NotificationManager.error('Failed to accept request');
      yield put(actions.setJoinRequest({ joinRequest }));
    }
  }
}

function* watchAcceptJoinRequests() {
  yield takeEvery(actions.acceptJoinRequest, handleAcceptJoinRequest);
}

export default function*() {
  yield all([
    watchJoinRequestsRequest(),
    watchDeleteJoinRequests(),
    watchAcceptJoinRequests()
  ]);
}
