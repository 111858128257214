import { Typography } from '@material-ui/core';
import * as React from 'react';

interface IProps {
  className?: string;
}

type Props = IProps;

const Subtitle: React.FC<Props> = props => {
  return (
    <Typography classes={{ root: props.className }}>
      {props.children}
    </Typography>
  );
};

export default Subtitle;
