import { ActionType, getType } from 'typesafe-actions';
import * as windowActions from './actions';

export interface IWindowState {
  readonly width: number;
  readonly height: number;
}

export type WindowActions = ActionType<typeof windowActions>;

const initialState: IWindowState = {
  height: window.innerHeight,
  width: window.innerWidth
};

export default (
  state: IWindowState = initialState,
  action: WindowActions
): IWindowState => {
  switch (action.type) {
    case getType(windowActions.updateSize): {
      return {
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
};
