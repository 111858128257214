import Button from '@material-ui/core/Button';
import * as React from 'react';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';

type Props = AuthProps;

export const LogoutButton: React.SFC<Props> = props => (
  <Button color="inherit" onClick={props.requestLogout}>
    Logout
  </Button>
);

export default withAuthState(LogoutButton);
