import { InjectedFormikProps, withFormik } from 'formik';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';

import { PollsProps } from '../../enhancers/withPollsState';
import { IPoll } from '../../types/poll';
import getDateInvalidator, { parsePollDate } from '../../utils/getDateInvalidator';

type FormValues = {
  startDate: Date;
  endDate: Date;
};

interface IFormProps {
  groupId: string;
  poll: IPoll;
}

type FormProps = IFormProps & PollsProps & RouteComponentProps;

export type EditPollFormProps = InjectedFormikProps<FormProps, FormValues> &
  Partial<FormValues>;

export default withFormik<FormProps, FormValues>({
  handleSubmit: (values, { props, setSubmitting, setFieldError }) => {
    setSubmitting(true);
    const { startDate, endDate } = values;
    const { groupId } = props;

    const errors: { [key: string]: string } = {};

    const polls = props.polls.polls.polls!.filter(
      poll => poll.groupId === props.groupId
    );
    const invalidator = getDateInvalidator(
      polls.filter(poll => poll.id !== props.poll.id),
      startDate
    );
    const isStartInvalid = invalidator('start')(startDate);
    const isEndInvalid = invalidator('end')(endDate);

    if (isStartInvalid) {
      errors.startDate = 'Invalid Start Date';
    }
    if (isEndInvalid) {
      errors.endDate = 'Invalid End Date';
    }

    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(key => setFieldError(key, errors[key]));
      setSubmitting(false);
      return;
    }

    const format = (date: Date) => Math.floor(date.getTime() / 1000);
    try {
      props.updatePollDate({
        id: props.poll.id,
        startDate: format(startDate),
        endDate: format(endDate)
      });
      props.history.replace(`/app/admin/groups/${groupId}/polls`);
    } catch (e) {
      console.log({ e });
    }
  },
  mapPropsToValues: props => {
    const { start, end } = parsePollDate(props.poll);
    return {
      startDate: start,
      endDate: end
    };
  },
  isInitialValid: true,
  validationSchema: Yup.object().shape({
    startDate: Yup.date().required(),
    endDate: Yup.date().required()
  })
});
