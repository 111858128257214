import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import styled from 'styled-components';

import withUiState, { UiProps } from '../../enhancers/withUiState';
import withUsersState, { UsersProps } from '../../enhancers/withUsersState';
import theme from '../../theme';
import { Invitee, InviteState } from '../../types/users';

interface IProps {
  item: Invitee;
}

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      paddingBottom: 8,
      paddingTop: 3
    },
    listItemText: {
      fontSize: '0.875rem'
    }
  });

type Props = IProps & UsersProps & UiProps & WithStyles<typeof styles>;

const InviteeListItem: React.FC<Props> = props => {
  const getSecondary = () => {
    switch (props.item.state) {
      case InviteState.DELETING:
      case InviteState.CREATING: {
        return (
          <ListItemSecondaryAction>
            <CircularProgress />
          </ListItemSecondaryAction>
        );
      }
      default: {
        return (
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Delete"
              onClick={() => {
                props.confirmAction({
                  title: 'Are you sure you want to delete this invite?',
                  confirmText: 'Delete',
                  color: 'secondary',
                  action: () => props.deleteInvitee({ id: props.item.id })
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        );
      }
    }
  };
  return (
    <Container>
      <ListItem disabled={props.item.state === InviteState.CREATING}>
        <ListItemText
          classes={{
            primary: props.classes.listItemText
          }}
          primary={props.item.email}
        />
        {getSecondary()}
      </ListItem>
      <Divider />
    </Container>
  );
};

const Container = styled.div`
  margin: ${p => theme.spacing.unit}px 0;
`;

export default withStyles(styles)(withUiState(withUsersState(InviteeListItem)));
