import { connect } from 'react-redux';

import { RootState } from '../types/store';

const mapStateToProps = (state: RootState) => ({
  loaded: {
    brand: state.brand.loaded
  }
});

export type LoadedProps = {
  loaded: {
    brand: boolean;
  };
};

export default connect(
  mapStateToProps,
  null
);
