import { createStandardAction } from 'typesafe-actions';

const UPDATE_SIZE = 'window/UPDATE_SIZE';
const REQUEST_UPDATE_SIZE = 'window/REQUEST_UPDATE_SIZE';

export const updateSize = createStandardAction(UPDATE_SIZE)<{
  width: number;
  height: number;
}>();

export const requestUpdateSize = createStandardAction(REQUEST_UPDATE_SIZE)<{
  width: number;
  height: number;
}>();
