import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import withBrandState, { BrandProps } from '../../enhancers/withBrandState';
import VolunteerPreWrapper from './VolunteerPreWrapper';
import withVolunteerPreForm, { VolunteerPreFormProps } from './withVolunteerPreForm';

const styles = (theme: Theme) =>
  createStyles({
    labels: {
      color: 'grey'
    },
    button: {
      backgroundColor: '#0085EB',
      color: 'white',
      padding: '8px 15px !important',
      marginTop: 10,
      textAlign: 'left'
    },
    align: {
      paddingTop: 30
    },
    cta: {
      textTransform: 'capitalize',
      marginTop: 15
    }
  });

type Props = VolunteerPreFormProps & BrandProps & WithStyles<typeof styles>;

class Volunteer extends React.Component<Props> {
  public state = {
    email: ' ',
    isChecked: false,
    lname: ' ',
    multiline: 'Controlled'
  };

  public handleChange = (name: string) => (event: any) => {
    this.setState({
      [name]: event.target.value
    });
  };

  public handleCheck = (e: any, checked: boolean) =>
    this.props.setFieldValue('test', checked);

  public render() {
    const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
      // if (Object.keys(this.props.errors).length) {
      //   NotificationManager.error('There');
      // }
      this.props.handleSubmit();
    };

    const submitForm = () => {
      this.props.submitForm();
      // setTimeout(() => {
      //   if (this.props.errors.checkbox) {
      //     NotificationManager.error(this.props.errors.checkbox);
      //   }
      // }, 100);
    };

    return (
      <VolunteerPreWrapper>
        <Bg
          style={{
            backgroundImage: `url(${this.props.brand.brand!.formFlag})`,
            backgroundPosition: 'top',
            backgroundSize: 'cover'
          }}
        >
          <FormLogo src={this.props.brand.brand!.formLogo} />
        </Bg>
        <Form style={{}} autoComplete="off" onSubmit={handleSubmit}>
          <MobileLogoContainer>
            <img
              src={this.props.brand.brand!.formLogoMobile}
              alt=""
              width="90px"
            />
          </MobileLogoContainer>
          <h4>{this.props.brand.brand!.alias} Volunteer Enrollment</h4>
          <p>
            Sign up today for exclusive {this.props.brand.brand!.alias} content
            straight to your device, anytime day or night, you're connected.
          </p>
          <TextField
            required={true}
            name="firstname"
            onChange={this.props.handleChange}
            value={this.props.values.firstname}
            disabled={this.props.isSubmitting}
            error={
              this.props.touched.firstname && !!this.props.errors.firstname
            }
            helperText={
              this.props.touched.firstname ? this.props.errors.firstname : ''
            }
            id="outlined-required"
            label="First Name"
            margin="normal"
            variant="outlined"
            fullWidth={true}
            classes={{
              root: 'border'
            }}
          />
          <TextField
            required={true}
            name="lastname"
            onChange={this.props.handleChange}
            value={this.props.values.lastname}
            disabled={this.props.isSubmitting}
            error={this.props.touched.lastname && !!this.props.errors.lastname}
            helperText={
              this.props.touched.lastname ? this.props.errors.lastname : ''
            }
            id="outlined-name"
            label="Last Name"
            margin="normal"
            variant="outlined"
            fullWidth={true}
            classes={{
              root: 'border'
            }}
          />
          <TextField
            required={true}
            label="Mobile Number"
            type="tel"
            value={this.props.values.number}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            disabled={this.props.isSubmitting}
            name="number"
            fullWidth
            error={this.props.touched.number && !!this.props.errors.number}
            helperText={
              this.props.touched.number ? this.props.errors.number : ''
            }
            variant="outlined"
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+27</InputAdornment>
              )
            }}
          />
          <TextField
            required={true}
            name="email"
            className="checkbox-padding"
            onChange={this.props.handleChange}
            value={this.props.values.email}
            disabled={this.props.isSubmitting}
            error={this.props.touched.email && !!this.props.errors.email}
            helperText={this.props.touched.email ? this.props.errors.email : ''}
            id="outlined-required"
            label="Email"
            margin="normal"
            variant="outlined"
            fullWidth={true}
            classes={{
              root: 'border'
            }}
          />

          {/* checkbox that should be added */}
          <div>
            <FormControlLabel
              disabled={this.props.isSubmitting}
              control={
                // checkbox
                <Checkbox
                  checked={this.props.values.test}
                  onChange={this.handleCheck}
                  color="default"
                  classes={
                    {
                      // root: this.props.classes.align
                    }
                  }
                />
              }
              label="Sign up to Look @ You news & product info"
              classes={{
                label: this.props.classes.labels
              }}
            />
          </div>
          <div>
            <FormControlLabel
              disabled={this.props.isSubmitting}
              control={
                <Checkbox
                  onChange={this.props.handleChange}
                  checked={this.props.values.checkbox}
                  name="checkbox"
                  required={true}
                  color="default"
                  className="checkbox-padding"
                  value="checkbox"
                />
              }
              label="I agree to the Terms & Conditions"
              classes={{
                label: this.props.classes.labels
              }}
            />
            <br />
            <ErrorText className="text-red">
              {this.props.touched.checkbox ? this.props.errors.checkbox : ''}
            </ErrorText>
          </div>

          <div>
            <Fab
              style={{ width: 150 }}
              disabled={this.props.isSubmitting}
              onClick={submitForm}
              variant="extended"
              color="primary"
              aria-label="Add"
              classes={{
                root: this.props.classes.cta
              }}
            >
              Sign up now
            </Fab>
          </div>
        </Form>
      </VolunteerPreWrapper>
    );
  }
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ErrorText = styled.span`
  color: #f44336;
  font-size: 12px;
  margin: 8px 12px 0;
  @media (max-width: 667px) {
    font-size: 15px;
    margin: 0;
  }
`;

const Bg = styled.div`
  width: 100%;
  @media (max-width: 667px) {
    display: none;
  }
`;

const FormLogo = styled.img`
  width: 70%;
  margin-top: 155px;
  margin-left: 25px;
  @media (max-width: 750px) {
    width: 65%;
    margin-top: 10px;
    margin-left: 16px;
  }
  @media screen and (min-width: 1400px) {
    width: 40%;
    margin-top: 164px;
    margin-left: 115px;
  }
`;

const MobileLogoContainer = styled.div`
  display: block;
  /* text-align: center; */
  @media (min-width: 667px) {
    display: none;
    justify-content: center;
  }
`;

const Form = styled.form`
  color: #c9c8df;
  background-color: #fff;
  padding: 20px 40px 40px 40px;
  width: 200%;
`;

export default withRouter(
  withBrandState(withVolunteerPreForm(withStyles(styles)(Volunteer)))
);
