import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as drawer from './actions';

export interface IDrawerState {
  readonly open: boolean;
}

export type DrawerAction = ActionType<typeof drawer>;

const initialState: IDrawerState = {
  open: false
};

export default combineReducers<IDrawerState, DrawerAction>({
  open: (state: boolean = initialState.open, action) => {
    switch (action.type) {
      case getType(drawer.toggle): {
        return action.payload;
      }
      default: {
        return state;
      }
    }
  }
});
