import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import CenterForm from '../../layouts/CenterForm/CenterForm';
import { AuthError } from '../../types/auth';

type Props = AuthProps & RouteComponentProps<{ id: string }>;

const FailedInvitation: React.FC<Props> = props => {
  const getError = () => {
    switch (props.auth.error) {
      case AuthError.USER_EXISTS: {
        return 'Phone number already exists';
      }
      default: {
        return 'Unknown error';
      }
    }
  };
  return (
    <CenterForm>
      <Typography>{getError()}</Typography>
      <Button
        onClick={() =>
          props.history.replace(`/app/invite/${props.match.params.id}`)
        }
      >
        Try Again
      </Button>
    </CenterForm>
  );
};

export default withAuthState(FailedInvitation);
