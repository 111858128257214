import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import styled from 'styled-components';

import EmailLoginForm from '../../components/EmailLoginForm/EmailLoginForm';
import AuthGuard from '../../containers/AuthGuard/AuthGuard';
import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withBrandState, { BrandProps } from '../../enhancers/withBrandState';
import withBrandSubdomain, { BrandSubdomainProps } from '../../enhancers/withBrandSubdomain';
import withLoadedState, { LoadedProps } from '../../enhancers/withLoadedState';
import CenterForm from '../../layouts/CenterForm/CenterForm';
import { AuthState } from '../../types/auth';
import TryAgain from '../Login/TryAgain';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing.unit * 2
    },
    title: {
      // marginBottom: theme.spacing.unit * 2
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    },
    appBar: {
      display: 'flex',
      justifyContent: 'center'
    }
  });

type Props = BrandSubdomainProps &
  LoadedProps &
  BrandProps &
  WithStyles<typeof styles> &
  AuthProps;

const EmailLogin: React.FC<Props> = props => {
  const getComponent = () => {
    switch (props.auth.state) {
      case AuthState.FETCHING_USER_DATA: {
        return (
          <Container>
            <CircularProgress />
          </Container>
        );
      }
      case AuthState.UNAUTHORISED: {
        return <TryAgain />;
      }
      default: {
        return <EmailLoginForm />;
      }
    }
  };
  if (!props.loaded.brand) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }
  return (
    <AuthGuard
      allowedStates={[
        AuthState.LOADED,
        AuthState.LOGGED_OUT,
        AuthState.LOGGING_IN,
        AuthState.AWAITING_CODE_INPUT,
        AuthState.VERIFYING_CODE,
        AuthState.FETCHING_USER_DATA
      ]}
      onFail={{ action: 'redirect', to: '/app/admin/groups' }}
    >
      <CenterForm padding={0}>
        <AppBar
          className={props.classes.appBar}
          color="secondary"
          position="static"
        >
          <Toolbar>
            <Typography variant="h5" className={props.classes.title}>
              Log in to {props.brand.brand ? props.brand.brand.alias : ''} CMS
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={props.classes.container}>{getComponent()}</div>
      </CenterForm>
    </AuthGuard>
  );
};

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withAuthState(
  withBrandState(
    withLoadedState(withBrandSubdomain(withStyles(styles)(EmailLogin)))
  )
);
