import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  acceptJoinRequest,
  deleteJoinRequest,
  IAcceptJoinRequestPayload,
  IDeleteJoinRequestPayload,
} from '../store/modules/joinRequests/actions';
import { IJoinRequestsState } from '../store/modules/joinRequests/reducer';
import { RootState } from '../types/store';

const mapStateToProps = (state: RootState) => ({
  joinRequests: state.joinRequests
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteJoinRequest,
      acceptJoinRequest
    },
    dispatch
  );

interface IJoinRequestsReduxState {
  joinRequests: IJoinRequestsState;
}

interface IJoinRequestDispatch {
  deleteJoinRequest: (payload: IDeleteJoinRequestPayload) => void;
  acceptJoinRequest: (payload: IAcceptJoinRequestPayload) => void;
}

export type JoinRequestsProps = IJoinRequestsReduxState & IJoinRequestDispatch;

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
