import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { GroupCreationState, IGroup } from '../../../types/groups';
import { Invitee } from '../../../types/users';
import * as actions from './actions';

interface IGroupCreation {
  state: GroupCreationState;
}

interface IGroups {
  loaded: boolean;
  groups: IGroup[] | null;
  error: string;
}

interface IInvitees {
  loaded: boolean;
  invitees: Invitee[] | null;
  error: string;
}

export interface IGroupsState {
  readonly creation: IGroupCreation;
  readonly groups: IGroups;
  readonly invitees: IInvitees;
}

type GroupActions = ActionType<typeof actions>;

const initialState: IGroupsState = {
  creation: {
    state: GroupCreationState.IDLE
  },
  groups: {
    loaded: false,
    groups: null,
    error: ''
  },
  invitees: {
    loaded: false,
    invitees: null,
    error: ''
  }
};

export default combineReducers<IGroupsState, GroupActions>({
  creation: combineReducers<IGroupCreation, GroupActions>({
    state: (state = initialState.creation.state, action) => {
      switch (action.type) {
        case getType(actions.setGroupCreationState): {
          return action.payload.state;
        }
        case getType(actions.createGroup): {
          return GroupCreationState.CREATING;
        }
        default: {
          return state;
        }
      }
    }
  }),
  groups: combineReducers<IGroups, GroupActions>({
    loaded: (state = initialState.groups.loaded, action) => {
      switch (action.type) {
        case getType(actions.setGroup):
        case getType(actions.addGroup):
        case getType(actions.setGroups): {
          return true;
        }
        default: {
          return state;
        }
      }
    },
    error: (state = initialState.groups.error, action) => {
      return state;
    },
    groups: (state = initialState.groups.groups, action) => {
      switch (action.type) {
        case getType(actions.setGroups): {
          return action.payload.groups;
        }
        case getType(actions.removeGroup): {
          if (state) {
            return state.filter(group => group.id !== action.payload.id);
          }
          return state;
        }
        case getType(actions.addGroup): {
          if (state) {
            return [...state, action.payload.group];
          }
          return [action.payload.group];
        }
        case getType(actions.setGroup): {
          if (state) {
            const index = state.findIndex(
              group => group.id === action.payload.group.id
            );
            if (index > -1) {
              const newState = [...state];
              newState[index] = {
                ...action.payload.group
              };
              return newState;
            }
          }
          return state;
        }
        default: {
          return state;
        }
      }
    }
  }),
  invitees: combineReducers<IInvitees, GroupActions>({
    loaded: (state = initialState.invitees.loaded, action) => {
      switch (action.type) {
        case getType(actions.setGroupInviteesLoaded): {
          return action.payload.loaded;
        }
        case getType(actions.setGroupInvitee):
        case getType(actions.addGroupInvitee):
        case getType(actions.setGroupInvitees): {
          return true;
        }
        default: {
          return state;
        }
      }
    },
    error: (state = initialState.invitees.error, action) => {
      return state;
    },
    invitees: (state = initialState.invitees.invitees, action) => {
      switch (action.type) {
        case getType(actions.setGroupInvitees): {
          return action.payload.invitees;
        }
        case getType(actions.removeGroupInvitee): {
          if (state) {
            return state.filter(invite => invite.id !== action.payload.id);
          }
          return state;
        }
        case getType(actions.addGroupInvitee): {
          if (state) {
            return [...state, action.payload.invitee];
          }
          return [action.payload.invitee];
        }
        case getType(actions.setGroupInvitee): {
          if (state) {
            const index = state.findIndex(
              invitee =>
                invitee.id === action.payload.invitee.id ||
                (typeof invitee.groupId !== 'undefined' &&
                  invitee.email === action.payload.invitee.email &&
                  invitee.groupId === action.payload.invitee.groupId)
            );
            if (index > -1) {
              const newState = [...state];
              newState[index] = {
                ...action.payload.invitee
              };
              return newState;
            }
          }
          return state;
        }
        default: {
          return state;
        }
      }
    }
  })
});
