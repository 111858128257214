import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import firebase from 'firebase/app';
import * as React from 'react';
import styled from 'styled-components';
import MuiPhoneNumber from 'material-ui-phone-number';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import theme from '../../theme';
import withLoginForm, { LoginFormProps } from './withLoginForm';

const styles = (theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing.unit * 2
    }
  });

type Props = LoginFormProps & WithStyles<typeof styles> & AuthProps;

class LoginForm extends React.Component<Props> {
  button = React.createRef<HTMLButtonElement>();
  recaptcha = React.createRef<HTMLDivElement>();

  componentDidMount() {
    const recaptcha = new firebase.auth.RecaptchaVerifier(
      this.recaptcha.current,
      {
        size: 'normal',
        callback: () => {
          this.props.setFieldValue('verifiedCaptcha', true);
        }
      }
    );
    this.props.setRecaptcha({ recaptcha });
    this.props.verifyRecaptcha();
  }

  componentWillUnmount() {
    this.props.setRecaptcha({ recaptcha: null });
  }

  render() {
    const { props } = this;
    console.log({ props });
    return (
      <React.Fragment>
        <form onSubmit={props.handleSubmit}>
          <MuiPhoneNumber
            defaultCountry="za"
            label="Mobile Number"
            type="tel"
            value={props.values.mobileNumber}
            onChange={props.handleChange('mobileNumber')}
            onBlur={props.handleBlur('mobileNumber')}
            disabled={props.isSubmitting}
            name="mobileNumber"
            countryCodeEditable={false}
            fullWidth
            error={props.touched.mobileNumber && !!props.errors.mobileNumber}
            helperText={
              props.touched.mobileNumber ? props.errors.mobileNumber : ''
            }
            margin="normal"
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">+27</InputAdornment>
            //   )
            // }}
          />
          <RecaptchaContainer>
            <div id="recaptcha" ref={this.recaptcha} />
          </RecaptchaContainer>
          {props.touched.verifiedCaptcha && props.errors.verifiedCaptcha && (
            <Typography color="error">
              {props.errors.verifiedCaptcha}
            </Typography>
          )}
          <Button
            buttonRef={this.button}
            variant="contained"
            className={props.classes.button}
            color="primary"
            fullWidth
            type="submit"
            onClick={props.submitForm}
            disabled={props.isSubmitting}
          >
            Submit
          </Button>
        </form>
      </React.Fragment>
    );
  }
}

const RecaptchaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${p => theme.spacing.unit * 2}px;
`;
export default withAuthState(withLoginForm(withStyles(styles)(LoginForm)));
