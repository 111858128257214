import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import * as React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    input: {
      marginLeft: 8,
      flex: 1
    },
    iconButton: {
      padding: 10
    }
  });

interface IProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

type Props = WithStyles<typeof styles> & IProps;

const SearchField: React.FC<Props> = props => {
  const { classes } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Paper className={classes.root} elevation={1}>
      {props.value && (
        <IconButton
          onClick={() => {
            props.onChange('');
            if (inputRef.current) {
              inputRef.current.blur();
            }
          }}
          className={classes.iconButton}
          aria-label="Close"
        >
          <ChevronLeft />
        </IconButton>
      )}

      <InputBase
        inputRef={inputRef}
        className={classes.input}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        onKeyPress={e => {
          console.log(e.keyCode, e.key);
          if (e.key === 'Enter') {
            e.preventDefault();
            if (inputRef.current) {
              inputRef.current.blur();
            }
          }
        }}
        placeholder={props.placeholder || 'Search'}
      />

      {props.value ? (
        <IconButton
          onClick={() => {
            props.onChange('');
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
          className={classes.iconButton}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      ) : (
        <IconButton className={classes.iconButton} aria-label="Search">
          <SearchIcon />
        </IconButton>
      )}
    </Paper>
  );
};

export default withStyles(styles)(SearchField);
