import firebase from 'firebase/app';
import { Action } from 'redux';
import { eventChannel } from 'redux-saga';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';

import { actions } from '.';
import { Collection } from '../../../constants/collections';
import { AuthState, AuthTier } from '../../../types/auth';
import { RootState } from '../../../types/store';
import { Invitee, InviteState, InviteStatus, IUser, UserState } from '../../../types/users';
import { actions as authActions } from '../auth/';
import { actions as brandActions } from '../brand/';
import { IDeleteInviteePayload, IDeleteUserPayload, IInviteTier12UserPayload } from './actions';

const functionsEndpoint = process.env.REACT_APP_FUNCTIONS_ENDPOINT!;

function* inviteTier12User(
  action: Action & { payload: IInviteTier12UserPayload }
) {
  yield call(console.log, 'inviting');
  const store: RootState = yield select();
  const token = yield store.auth.user!.getIdToken();
  const brandId = store.brand.brand!.id;

  yield put(
    actions.addInvitee({
      invitee: {
        id: '',
        email: action.payload.invite.email,
        state: InviteState.CREATING,
        brandId,
        status: InviteStatus.PENDING,
        tier: action.payload.tier,
        createdAt: Math.floor(Date.now() / 1000)
      }
    })
  );
  try {
    yield fetch(functionsEndpoint + '/createInvite', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        brand: store.brand.brand!.id,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: action.payload.invite.email,
        tier: action.payload.tier
      })
    });
  } catch (e) {
    console.log({ e });
  }
}

function* fetchInvitees() {
  const store: RootState = yield select();
  const db = firebase.firestore();

  const result: firebase.firestore.QuerySnapshot = yield db
    .collection(Collection.INVITES)
    .where('brandId', '==', store.brand.brand!.id)
    .where('tier', '==', AuthTier.TIER1)
    .get();
  const invitees = result.docs.map(
    doc => ({ ...doc.data(), id: doc.id } as Invitee)
  );

  yield put(actions.setInvitees({ invitees }));
}

function* handleInviteDocChange(change: firebase.firestore.DocumentChange) {
  const store: RootState = yield select();
  const invitees = store.users.invitees.invitees!;
  const invitee = { ...change.doc.data(), id: change.doc.id } as Invitee;
  const existingInvitee = invitees.find(theInvitee => {
    return theInvitee.email === invitee.email;
  });

  if (change.type === 'added') {
    if (existingInvitee) {
      yield put(actions.setInvitee({ invitee }));
    } else {
      yield put(actions.addInvitee({ invitee }));
    }
  }
  if (change.type === 'modified') {
    yield put(actions.setInvitee({ invitee }));
  }
  if (change.type === 'removed') {
    if (existingInvitee) {
      // TODO: User feedback
    }
    yield put(actions.removeInvitee({ email: invitee.email }));
  }
}

function* handleUsersDocChanges(change: firebase.firestore.DocumentChange) {
  const store: RootState = yield select();
  const users = store.users.users.users!;
  const user = change.doc.data() as IUser;
  const existingUser = users.find(theUser => {
    return theUser.uid === user.uid;
  });

  if (change.type === 'added') {
    if (existingUser) {
      yield put(actions.setUser({ user }));
    } else {
      yield put(actions.addUser({ user }));
    }
  }
  if (change.type === 'modified') {
    yield put(actions.setUser({ user }));
  }
  if (change.type === 'removed') {
    if (existingUser) {
      // TODO: User feedback
    }
    yield put(actions.removeUser({ id: user.uid }));
  }
}

function* watchInvitees() {
  let store: RootState = yield select();

  console.log('get brand');
  if (!store.brand.brand) {
    yield take(brandActions.setBrand);
  }
  store = yield select();

  console.log('got brand');

  console.log('get auth');
  while (true) {
    store = yield select();
    if (store.auth.state === AuthState.LOGGED_IN) {
      break;
    }
    yield take(authActions.setAuthState);
  }

  console.log(`got auth ${AuthTier.TIER1}`);

  const db = firebase.firestore();

  yield fetchInvitees();

  const inviteeChanges = () =>
    eventChannel<firebase.firestore.DocumentChange>(emit => {
      let init = false;
      const listener = db
        .collection(Collection.INVITES)
        .where('brandId', '==', store.brand.brand!.id)
        .where('tier', '==', AuthTier.TIER1)
        .onSnapshot(snap => {
          if (init) {
            snap.docChanges().map(emit);
          } else {
            init = true;
          }
        });
      return listener;
    });

  yield takeEvery(inviteeChanges(), handleInviteDocChange);
}

function* watchInviteesRequest() {
  yield take(actions.watchInvitees);
  yield watchInvitees();
}

function* watchInviteTier12() {
  yield takeEvery(actions.inviteTier12User, inviteTier12User);
}

function* deleteInvitee(action: Action & { payload: IDeleteInviteePayload }) {
  const store: RootState = yield select();
  const invitees: Invitee[] = store.users.invitees.invitees!;

  const existingInvitee = invitees.find(
    invitee => invitee.id === action.payload.id
  );
  if (existingInvitee) {
    const newInvitee: Invitee = {
      ...existingInvitee,
      state: InviteState.DELETING
    };
    yield put(actions.setInvitee({ invitee: newInvitee }));
    const token = yield store.auth.user!.getIdToken();

    const response: Response = yield fetch(
      functionsEndpoint + '/deleteInvite',
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
          brand: store.brand.brand!.id,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          inviteId: existingInvitee.id
        })
      }
    );
    if (response.status !== 200) {
      yield put(actions.setInvitee({ invitee: existingInvitee }));
    }
  }
}

function* watchDeleteInvitee() {
  yield takeEvery(actions.deleteInvitee, deleteInvitee);
}

function* fetchUsers() {
  const store: RootState = yield select();
  const db = firebase.firestore();

  const brandId = store.brand.brand!.id;

  const results: firebase.firestore.QuerySnapshot = yield db
    .collection(Collection.USERS)
    .where('brandId', '==', brandId)
    .get();

  const users = results.docs.map(doc => ({ ...doc.data() } as IUser));

  yield put(actions.setUsers({ users }));
}

function* watchUsers() {
  let store: RootState = yield select();
  if (!store.brand.brand) {
    yield take(brandActions.setBrand);
  }
  store = yield select();
  while (true) {
    store = yield select();
    if (store.auth.state === AuthState.LOGGED_IN) {
      break;
    }
    yield take(authActions.setAuthState);
  }
  const db = firebase.firestore();

  yield fetchUsers();

  const usersChanges = () =>
    eventChannel<firebase.firestore.DocumentChange>(emit => {
      let init = false;
      const listener = db
        .collection(Collection.USERS)
        .where('brandId', '==', store.brand.brand!.id)
        .onSnapshot(snap => {
          if (init) {
            snap.docChanges().map(emit);
          } else {
            init = true;
          }
        });
      return listener;
    });

  yield takeEvery(usersChanges(), handleUsersDocChanges);
}

function* watchUsersRequest() {
  yield take(actions.watchUsers);
  yield watchUsers();
}

function* handleDeleteUser(action: Action & { payload: IDeleteUserPayload }) {
  const store: RootState = yield select();
  const users: IUser[] = store.users.users.users!;

  const existingUser = users.find(users => users.uid === action.payload.id);
  if (existingUser) {
    const newUser: IUser = {
      ...existingUser,
      state: UserState.DELETING
    };
    yield put(actions.setUser({ user: newUser }));
    const token = yield store.auth.user!.getIdToken();

    const response: Response = yield fetch(functionsEndpoint + '/revokeUser', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        brand: store.brand.brand!.id,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userUid: existingUser.uid
      })
    });
    if (response.status !== 200) {
      yield put(actions.setUser({ user: existingUser }));
    }
  }
}

function* watchDeleteUser() {
  yield takeEvery(actions.deleteUser, handleDeleteUser);
}

export default function*() {
  yield all([
    watchInviteTier12(),
    watchInviteesRequest(),
    watchDeleteInvitee(),
    watchUsersRequest(),
    watchDeleteUser()
  ]);
}
