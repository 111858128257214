import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withBrandState, { BrandProps } from '../../enhancers/withBrandState';

type Props = BrandProps & AuthProps;

const TryAgain: React.FC<Props> = props => {
  return (
    <div>
      <Typography>You do not have access.</Typography>
      <Button onClick={props.requestLogout}>Try again</Button>
    </div>
  );
};

export default withAuthState(withBrandState(TryAgain));
