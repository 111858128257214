import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import AcceptInvitationForm from '../../components/AcceptInvitationForm/AcceptInvitationForm';
import LoggedInInvitationResponse from '../../components/LoggedInInvitationResponse/LoggedInInvitationResponse';
import VerifyCodeForm from '../../components/VerifyCode/VerifyCodeForm';
import AuthGuard from '../../containers/AuthGuard/AuthGuard';
import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import withBrandState, { BrandProps } from '../../enhancers/withBrandState';
import CenterForm from '../../layouts/CenterForm/CenterForm';
import { AuthState } from '../../types/auth';
import { Invitee } from '../../types/users';

const functionsEndpoint = process.env.REACT_APP_FUNCTIONS_ENDPOINT!;

type Props = BrandProps & RouteComponentProps<{ id: string }> & AuthProps;

const AcceptInvitation: React.FC<Props> = props => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string>('');
  const [invite, setInvite] = React.useState<Invitee | null>(null);
  const [ready, setReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    fetch(functionsEndpoint + '/fetchInvite', {
      method: 'POST',
      headers: {
        brand: props.brand.brand!.id,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        inviteId: props.match.params.id
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error(res.statusText);
      })
      .then(res => {
        setInvite(res);
        setLoading(false);
      })
      .catch(err => {
        console.log({ err });
        setLoading(false);
        setError(err.message || 'Failed');
      });
  }, []);

  const user = props.auth.user;
  const state = props.auth.state;

  React.useEffect(() => {
    if ([AuthState.LOGGED_IN, AuthState.LOGGED_OUT].includes(state)) {
      if (invite && user) {
        if (invite.email !== user.email) {
          props.requestLogout();
        }
      }
      setReady(true);
    }
  }, [state]);

  if (loading || !ready) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }
  if (error || !invite) {
    return <Container>{error}</Container>;
  }

  const getComponent = () => {
    switch (props.auth.state) {
      case AuthState.AWAITING_CODE_INPUT: {
        return <VerifyCodeForm />;
      }
      case AuthState.FETCHING_USER_DATA: {
        return (
          <Container>
            <CircularProgress />
          </Container>
        );
      }
      case AuthState.SETTING_UP: {
        return (
          <Container>
            <div>
              <Typography>Setting up your account...</Typography>
              <CircularProgress />
            </div>
          </Container>
        );
      }
      default: {
        if (!props.auth.user) {
          return <AcceptInvitationForm invite={invite!} />;
        }
        return <LoggedInInvitationResponse invite={invite!} />;
      }
    }
  };

  const brandName =
    invite.groupName || (props.brand.brand ? props.brand.brand.alias : '');

  return (
    <AuthGuard
      allowedStates={[
        AuthState.LOADED,
        AuthState.LOGGED_OUT,
        AuthState.LOGGING_IN,
        AuthState.AWAITING_CODE_INPUT,
        AuthState.VERIFYING_CODE,
        AuthState.FETCHING_USER_DATA,
        AuthState.UNAUTHORISED,
        AuthState.SETTING_UP,
        AuthState.LOGGED_IN
      ]}
      onFail={{ action: 'redirect', to: '/app/admin/' }}
    >
      <CenterForm>
        <Typography variant="h5">
          Accept invitation to the {brandName}
        </Typography>
        {getComponent()}
      </CenterForm>
    </AuthGuard>
  );
};

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withAuthState(withBrandState(AcceptInvitation));
