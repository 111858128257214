import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import withBrand, { BrandProps } from '../../enhancers/withBrandState';
import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';
import withWindowState, { WindowProps } from '../../enhancers/withWindowState';
import withGroupDescriptionForm, { GroupDescriptionFormProps } from './withGroupDescriptionForm';

const styles = (theme: Theme) =>
  createStyles({
    light: {
      opacity: 0.8,
      fontSize: 14
    },
    title: {
      marginBottom: theme.spacing.unit * 2
    },
    button: {
      marginTop: theme.spacing.unit * 2
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    cta: {
      textTransform: 'initial',
      width: '100%',
      fontWeight: 400,
      fontSize: 18,
      marginTop: 26
    }
  });

type Props = WithStyles<typeof styles> &
  BrandProps &
  WindowProps &
  GroupDescriptionFormProps &
  GroupsProps &
  RouteComponentProps;

class GroupDescriptionForm extends React.Component<Props> {
  render() {
    const { props } = this;
    const requestForm = (
      <form onSubmit={props.handleSubmit}>
        <Typography variant="subtitle1">Group Description</Typography>
        <TextField
          multiline
          name="description"
          placeholder="e.g. Proud ANC supporters ready to volunteer!"
          value={props.values.description}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          fullWidth
          rows={8}
          variant="outlined"
          error={props.touched.description && !!props.errors.description}
          helperText={props.touched.description ? props.errors.description : ''}
          margin="normal"
        />
        <FormControlLabel
          control={
            <Switch
              checked={props.values.terms}
              onChange={props.handleChange('terms')}
              value="accepted"
              color="default"
            />
          }
          label={
            <Typography
              classes={{
                root: classnames(props.classes.light)
              }}
            >
              <TermsLink to="/app/static/terms">
                I agree to the terms & conditions
              </TermsLink>
            </Typography>
          }
        />
        <Fab
          disabled={this.props.isSubmitting || !this.props.isValid}
          onClick={props.submitForm}
          variant="extended"
          color="primary"
          aria-label="Add"
          classes={{
            root: this.props.classes.cta
          }}
        >
          Create Group
        </Fab>
      </form>
    );

    return requestForm;
  }
}

const TermsLink = styled(Link)`
  color: black;
`;

export default withRouter(
  withBrand(
    withGroupsState(
      withGroupDescriptionForm(
        withWindowState(withStyles(styles)(GroupDescriptionForm))
      )
    )
  )
);
