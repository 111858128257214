import { Paper } from '@material-ui/core';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import styled from 'styled-components';

import withWindowState, { WindowProps } from '../../enhancers/withWindowState';

interface IProps {
  padding?: number;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing.unit * 2
    }
  });

type Props = WindowProps & WithStyles<typeof styles> & IProps;

const CenterForm: React.FC<Props> = props => {
  return (
    <Container center={props.window.width >= 510}>
      <FormContainer>
        {props.window.width < 510 ? (
          <div
            className={props.classes.container}
            style={
              typeof props.padding === 'number'
                ? { padding: props.padding }
                : {}
            }
          >
            {props.children}
          </div>
        ) : (
          <Paper
            className={props.classes.container}
            style={
              typeof props.padding === 'number'
                ? { padding: props.padding }
                : {}
            }
          >
            {props.children}
          </Paper>
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.div<{ center?: boolean }>`
  width: 100%;
  flex: 1;
  display: flex;
  ${p =>
    p.center &&
    `align-items: center;
  justify-content: center;`}
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 500px;
`;

export default withStyles(styles)(withWindowState(CenterForm));
