import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import JoinRequestsList from '../../components/JoinRequestsList/JoinRequestsList';
import SearchField from '../../components/SearchField/SearchField';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';

interface IProps {
  scrollRef: React.RefObject<HTMLDivElement>;
}

type Props = RouteComponentProps<{ id: string }> & IProps;

const GroupJoinRequests: React.FC<Props> = props => {
  const id = props.match.params.id;
  const [count, incrementCount, resetCount] = useInfiniteScroll();
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  return (
    <React.Fragment>
      <SearchContainer>
        <SearchField
          value={searchTerm}
          onChange={value => {
            setSearchTerm(value);
            resetCount();
          }}
          placeholder="Search Requests"
        />
      </SearchContainer>
      <JoinRequestsList
        groupId={id}
        filterText={searchTerm}
        scrollRef={props.scrollRef}
        count={count}
        incrementCount={incrementCount}
      />
    </React.Fragment>
  );
};

const SearchContainer = styled.div`
  padding: 15px;
  background: rgba(0, 0, 0, 0.05);
`;

export default GroupJoinRequests;
