import { InjectedFormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { GroupsProps } from '../../enhancers/withGroupsState';

type FormValues = {
  email: string;
  name: string;
  isAdmin: boolean;
};

type FormProps = { groupId: string } & GroupsProps;

export type GroupUserInviteFormProps = InjectedFormikProps<
  FormProps,
  FormValues
> &
  Partial<FormValues>;

const initialValues = {
  email: '',
  isAdmin: false,
  name: ''
};

export default withFormik<FormProps, FormValues>({
  handleSubmit: (
    values,
    { props, setSubmitting, setError, resetForm, setStatus }
  ) => {
    const { email, name, isAdmin } = values;
    console.log({ values });
    props.inviteGroupUser({
      email,
      name,
      isTier3: isAdmin,
      groupId: props.groupId
    });
    setStatus('submitting');
  },
  mapPropsToValues: () => ({ ...initialValues }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string()
      .email('Must be a valid email')
      .required(),
    isAdmin: Yup.boolean().required()
  })
});
