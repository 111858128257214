import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import image from '../../assets/img/Tick.png';
import Subtitle from '../../components/Subtitle/Subtitle';
import Title from '../../components/Title/Title';
import theme from '../../theme';

const styles = (theme: Theme) =>
  createStyles({
    white: {
      color: 'white'
    },
    light: {
      opacity: 0.8,
      fontSize: 14
    },
    cta: {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      textTransform: 'initial',
      fontWeight: 400,
      marginTop: 30,
      '&$hover': {
        backgroundColor: 'white'
      }
    },
    ctaDisabled: {
      opacity: 0.3,
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: 'white !important'
    }
  });

type Props = WithStyles<typeof styles> & RouteComponentProps;

const CreateGroupSplash: React.FC<Props> = props => {
  const [accepted, setAccepted] = React.useState<boolean>(false);
  return (
    <Container>
      <Inner>
        <PageTitle className={props.classes.white}>Create a Group</PageTitle>
        <PageSubtitle className={props.classes.white}>
          We just need a couple of details from you, before you create your
          group.
        </PageSubtitle>
        <Image src={image} />
        <FormControlLabel
          control={
            <Switch
              checked={accepted}
              onChange={e => setAccepted(e.target.checked)}
              value="accepted"
              color="default"
            />
          }
          label={
            <Typography
              classes={{
                root: classnames(props.classes.white, props.classes.light)
              }}
            >
              I have read & agree to the{' '}
              <Link to="/app/static/terms" className={props.classes.white}>
                Terms of Use
              </Link>{' '}
              &{' '}
              <Link to="/app/static/guidelines" className={props.classes.white}>
                Group Governance Guidelines.
              </Link>
            </Typography>
          }
        />
        <Fab
          style={{ width: 220 }}
          variant="extended"
          aria-label="Add"
          onClick={() => props.history.push('/app/request')}
          disabled={!accepted}
          classes={{
            root: props.classes.cta,
            disabled: props.classes.ctaDisabled
          }}
        >
          Create Group
        </Fab>
      </Inner>
    </Container>
  );
};

const Image = styled.img`
  width: 300px;
  height: 300px;
  margin-top: 20px;
`;

const Container = styled.div`
  flex: 1;
  background: ${p => theme.palette.primary.main};
`;

const PageTitle = styled(Title)`
  && {
    text-align: center;
    margin-top: 30px;
    font-weight: 400;
  }
`;

const PageSubtitle = styled(Subtitle)`
  && {
    text-align: center;
    margin-top: 8px;
    font-weight: 100;
    font-size: 17px;
  }
`;

const Inner = styled.div`
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export default withRouter(withStyles(styles)(CreateGroupSplash));
