export enum Collection {
  BRANDS = 'brands',
  BRANDS_META = 'brandsMeta',
  GROUP_REQUESTS = 'groupRequests',
  GROUPS = 'groups',
  GROUPS_META = 'groupsMeta',
  INVITES = 'invites',
  USERS = 'users',
  USERS_META = 'usersMeta',
  PRESUBMISSION = 'presubmissions',
  POSTS = 'posts',
  POLLS = 'polls',
  JOIN_REQUESTS = 'joinRequests'
}
