import React, { Component } from 'react';
import { NotificationContainer } from 'react-notifications';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import ConfirmationDialog from './components/ConfirmationDialog/ConfirmationDialog';
import NotFound from './components/NotFound/NotFound';
import RedirectService from './components/RedirectService/RedirectService';
import AppLoader from './containers/AppLoader/AppLoader';
import AuthGuard from './containers/AuthGuard/AuthGuard';
import BrandChecker from './containers/BrandChecker/BrandChecker';
import AcceptInvitation from './pages/AcceptInvitation/AcceptInvitation';
import CreateGroup from './pages/CreateGroup/CreateGroup';
import CreateGroupSplash from './pages/CreateGroupSplash/CreateGroupSplash';
import EditPoll from './pages/EditPoll/EditPoll';
import EditPost from './pages/EditPost/EditPost';
import EmailLogin from './pages/EmailLogin/EmailLogin';
import FailedInvitation from './pages/FailedInvitation/FailedInvitation';
import Group from './pages/Group/Group';
import GroupCreated from './pages/GroupCreated/GroupCreated';
import GroupDescription from './pages/GroupDescription/GroupDescription';
import GroupInvite from './pages/GroupInvite/GroupInvite';
import Groups from './pages/Groups/Groups';
import InviteUser from './pages/InviteUser/InviteUser';
import Login from './pages/Login/Login';
import NewPoll from './pages/NewPoll/NewPoll';
import NewPost from './pages/NewPost/NewPost';
import TokenLogin from './pages/TokenLogin/TokenLogin';
import Unauthorized from './pages/Unauthorized/Unauthorized';
import Users from './pages/Users/Users';
import ViewPoll from './pages/ViewPoll/ViewPoll';
import VolunteerPre from './pages/VolunteerPre/VolunteerPre';
import VolunteerPreSuccess from './pages/VolunteerPreSuccess/VolunteerPreSuccess';
import { loggedInStates } from './types/auth';

type Props = RouteComponentProps;

const App: React.FC<Props> = props => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);
  return (
    <React.Fragment>
      <BrandChecker>
        <AppLoader>
          <Switch>
            {/* No Auth Routes */}
            <Route path="/app" exact component={VolunteerPre} />
            <Route path="/app/login/:token" exact component={TokenLogin} />
            <Route path="/app/success" exact component={VolunteerPreSuccess} />
            <Route path="/app/invite/:id" exact component={AcceptInvitation} />
            <Route
              path="/app/invite/:id/failed"
              exact
              component={FailedInvitation}
            />
            <Route path="/app/admin/login" component={Login} />
            <Route path="/app/admin/email-login" component={EmailLogin} />
            <Route path="/app/unauthorised" component={Unauthorized} />
            <Route
              path="/app"
              children={() => (
                <AuthGuard allowedStates={loggedInStates}>
                  <Switch>
                    <Route path="/app/request" exact component={CreateGroup} />
                    <Route
                      path="/app/group"
                      exact
                      component={CreateGroupSplash}
                    />
                    <Route
                      path="/app/request/description"
                      exact
                      component={GroupDescription}
                    />
                    <Route
                      path="/app/request/:id"
                      exact
                      component={GroupCreated}
                    />

                    <Route
                      path="/app/admin"
                      exact
                      component={() => <Redirect to="/app/admin/groups" />}
                    />
                    <Route
                      path="/app/admin/groups/:id/posts/new/:postId?"
                      component={NewPost}
                    />

                    <Route
                      path="/app/admin/groups/:id/invite"
                      component={GroupInvite}
                      exact
                    />
                    <Route
                      path="/app/admin/groups/:groupId/posts/:id"
                      component={EditPost}
                    />
                    <Route path="/app/admin/groups" component={Groups} exact />
                    <Route
                      exact
                      path="/app/admin/groups/:id/polls/new"
                      component={NewPoll}
                    />
                    <Route
                      path="/app/admin/groups/:groupId/polls/:id/view"
                      component={ViewPoll}
                    />
                    <Route
                      path="/app/admin/groups/:groupId/polls/:id/edit"
                      component={EditPoll}
                    />
                    <Route
                      path="/app/admin/groups/:id/:screen?/:nestedScreen?"
                      component={Group}
                    />

                    <Route
                      path="/app/admin/users"
                      exact
                      component={() => <Redirect to="/app/admin/users/users" />}
                    />
                    <Route
                      path="/app/admin/users/invite"
                      component={InviteUser}
                    />
                    <Route
                      path="/app/admin/users/:screen?"
                      exact
                      component={Users}
                    />
                    <Route component={NotFound} />
                  </Switch>
                </AuthGuard>
              )}
            />
            <Route component={NotFound} />
          </Switch>
        </AppLoader>
      </BrandChecker>
      <NotificationContainer />
      <ConfirmationDialog />
      <RedirectService />
    </React.Fragment>
  );
};

export default withRouter(App);
