import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import GroupUserInviteForm from '../../components/GroupUserInviteForm/GroupUserInviteForm';
import NotFound from '../../components/NotFound/NotFound';
import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';
import BackScreen from '../../layouts/BackScreen/BackScreen';
import theme from '../../theme';

type Props = GroupsProps & RouteComponentProps<{ id: string }>;

const GroupInvite: React.FC<Props> = props => {
  const [mounted, setMounted] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, [mounted]);

  const { groups } = props.groups;

  if (!groups.loaded || !groups.groups) {
    return <CircularProgress />;
  }

  const group = groups.groups.find(group => group.id === props.match.params.id);

  if (!group) {
    return <NotFound />;
  }

  return (
    <BackScreen title={`Invite user to ${group.name}`}>
      <Container>
        <GroupUserInviteForm groupId={group.id} />
      </Container>
    </BackScreen>
  );
};

const Container = styled.div`
  padding: 16px 36px;
`;

const FabContainer = styled.div`
  position: fixed;
  bottom: ${p => theme.spacing.unit * 2}px;
  right: ${p => theme.spacing.unit * 2}px;
`;

export default withGroupsState(GroupInvite);
