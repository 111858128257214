import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import NewPollForm from '../../components/NewPollForm/NewPollForm';
import BackScreen from '../../layouts/BackScreen/BackScreen';
import CenterForm from '../../layouts/CenterForm/CenterForm';

type Props = RouteComponentProps<{ id: string }>;

const NewPoll: React.FC<Props> = props => {
  return (
    <BackScreen title="New Poll">
      <CenterForm>
        <NewPollForm groupId={props.match.params.id} />
      </CenterForm>
    </BackScreen>
  );
};

export default NewPoll;
