import CircularProgress from '@material-ui/core/CircularProgress';
import queryString from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import { RoutingProps } from '../../enhancers/withRoutingState';

type Props = RouteComponentProps<{ token: string }> & AuthProps & RoutingProps;

const TokenLogin: React.FC<Props> = props => {
  React.useEffect(() => {
    const value = queryString.parse(window.location.search);
    const userRedirectTo = value.redirectTo;
    let redirectTo = '';
    if (typeof userRedirectTo === 'string') {
      redirectTo = userRedirectTo;
    }
    props.tokenLogin({ token: props.match.params.token, redirectTo });
  }, []);
  return (
    <Container>
      <div>
        <CircularProgress />
        Logging in...
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withAuthState(TokenLogin);
