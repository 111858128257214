import Button from '@material-ui/core/Button';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';

import withAuthState, { AuthProps } from '../../enhancers/withAuthState';
import { AuthError } from '../../types/auth';
import withVerifyCodeForm, { VerifyCodeFormProps } from './withVerifyCodeForm';

const styles = (theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing.unit * 2
    }
  });

type Props = AuthProps & VerifyCodeFormProps & WithStyles<typeof styles>;

const VerifyCode: React.FC<Props> = props => {
  React.useEffect(() => {
    if (props.auth.error === AuthError.CODE_VERIFICATION_FAILED) {
      props.setSubmitting(false);
      props.setFieldError('code', 'Invalid verification code');
    }
  }, [props.auth.error]);
  return (
    <form onSubmit={props.handleSubmit}>
      <TextField
        label="Code"
        type="tel"
        value={props.values.code}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        name="code"
        fullWidth
        error={props.touched.code && !!props.errors.code}
        helperText={props.touched.code ? props.errors.code : ''}
        variant="outlined"
        margin="normal"
        disabled={props.isSubmitting}
      />
      <Button
        variant="contained"
        className={props.classes.button}
        color="primary"
        fullWidth
        type="submit"
        onClick={props.submitForm}
        disabled={props.isSubmitting}
      >
        Verify Code
      </Button>
    </form>
  );
};

export default withAuthState(
  withVerifyCodeForm(withStyles(styles)(VerifyCode))
);
