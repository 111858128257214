import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { AuthError, AuthState, AuthTier } from '../../../types/auth';
import { IUser } from '../../../types/users';
import * as actions from './actions';

export interface IAuthState {
  readonly recaptcha: firebase.auth.RecaptchaVerifier | null;
  readonly confirmationResult: firebase.auth.ConfirmationResult | null;
  readonly state: AuthState;
  readonly user: firebase.User | null;
  readonly userData: IUser | null;
  readonly tier: AuthTier | null;
  readonly error: AuthError | null;
}

type AuthAction = ActionType<typeof actions>;

const initialState: IAuthState = {
  recaptcha: null,
  confirmationResult: null,
  state: AuthState.LOADING,
  user: null,
  userData: null,
  tier: null,
  error: null
};

export default combineReducers<IAuthState, AuthAction>({
  confirmationResult: (state = initialState.confirmationResult, action) => {
    return state;
  },
  error: (state = initialState.error, action) => {
    if (action.type === getType(actions.setAuthError)) {
      return action.payload.error;
    }
    return state;
  },
  recaptcha: (state = initialState.recaptcha, action) => {
    if (action.type === getType(actions.setRecaptcha)) {
      return action.payload.recaptcha;
    }
    return state;
  },
  state: (state = initialState.state, action) => {
    if (action.type === getType(actions.setAuthState)) {
      return action.payload.state;
    }
    return state;
  },
  tier: (state = initialState.tier, action) => {
    if (
      action.type === getType(actions.setUserData) &&
      typeof action.payload.tier !== 'undefined'
    ) {
      return action.payload.tier;
    }
    return state;
  },
  userData: (state = initialState.userData, action) => {
    if (
      action.type === getType(actions.setUserData) &&
      typeof action.payload.data !== 'undefined'
    ) {
      return action.payload.data;
    }
    return state;
  },
  user: (state = initialState.user, action) => {
    if (action.type === getType(actions.setUser)) {
      return action.payload.user;
    }
    return state;
  }
});
