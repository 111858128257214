import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { Invitee, IUser } from '../../../types/users';
import { actions } from './';

interface IInvitees {
  loaded: boolean;
  invitees: Invitee[] | null;
  error: string;
}

interface IUsers {
  loaded: boolean;
  users: IUser[] | null;
  error: string;
}

export interface IUsersState {
  readonly invitees: IInvitees;
  readonly users: IUsers;
}

type UsersAction = ActionType<typeof actions>;

const initialState: IUsersState = {
  invitees: {
    loaded: false,
    invitees: null,
    error: ''
  },
  users: {
    loaded: false,
    error: '',
    users: null
  }
};

export default combineReducers<IUsersState, UsersAction>({
  invitees: combineReducers<IInvitees, UsersAction>({
    loaded: (state = initialState.invitees.loaded, action) => {
      if (action.type === getType(actions.setInvitees)) {
        return true;
      }
      return state;
    },
    error: (state = initialState.invitees.error, action) => {
      return state;
    },
    invitees: (state = initialState.invitees.invitees, action) => {
      switch (action.type) {
        case getType(actions.setInvitees): {
          if (state) {
            return [...state, ...action.payload.invitees];
          }
          return action.payload.invitees;
        }
        case getType(actions.addInvitee): {
          if (state) {
            return [...state, action.payload.invitee];
          }
          return [action.payload.invitee];
        }
        case getType(actions.removeInvitee): {
          if (state) {
            return state.filter(
              invitee => invitee.email !== action.payload.email
            );
          }
          return state;
        }
        case getType(actions.setInvitee): {
          if (state) {
            const index = state.findIndex(
              invitee => invitee.email === action.payload.invitee.email
            );
            if (index > -1) {
              const newState = [...state];
              newState[index] = {
                ...action.payload.invitee
              };
              return newState;
            }
          }
          return state;
        }
        default: {
          return state;
        }
      }
    }
  }),
  users: combineReducers<IUsers, UsersAction>({
    loaded: (state = initialState.users.loaded, action) => {
      if (action.type === getType(actions.setUsers)) {
        return true;
      }
      return state;
    },
    error: (state = initialState.users.error, action) => {
      return state;
    },
    users: (state = initialState.users.users, action) => {
      switch (action.type) {
        case getType(actions.setUsers): {
          return action.payload.users;
        }
        case getType(actions.addUser): {
          if (state) {
            return [...state, action.payload.user];
          }
          return [action.payload.user];
        }
        case getType(actions.removeUser): {
          if (state) {
            return state.filter(user => user.uid !== action.payload.id);
          }
          return state;
        }
        case getType(actions.setUser): {
          if (state) {
            const index = state.findIndex(
              user => user.uid === action.payload.user.uid
            );
            if (index > -1) {
              const newState = [...state];
              newState[index] = {
                ...action.payload.user
              };
              return newState;
            }
          }
          return state;
        }
        default: {
          return state;
        }
      }
    }
  })
});
