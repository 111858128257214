import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import noGroups from '../../assets/img/group-blank.png';
import withGroupsState, { GroupsProps } from '../../enhancers/withGroupsState';
import { Invitee } from '../../types/users';
import EmptyState from '../EmptyState/EmptyState';
import GroupInviteeListItem from './GroupInviteeListItem';

interface IProps {
  groupId: string;
  scrollRef: React.RefObject<HTMLDivElement>;
  count: number;
  incrementCount: () => void;
  filterText: string;
}

const styles = (theme: Theme) =>
  createStyles({
    subheader: {
      fontSize: '1rem'
    }
  });

type Props = GroupsProps & IProps & WithStyles<typeof styles>;

const GroupInviteesList: React.FC<Props> = props => {
  const invitees = props.groups.invitees;

  if (!invitees.invitees || !invitees.loaded) {
    return (
      <Centerer>
        <CircularProgress />
      </Centerer>
    );
  }

  type InviteesFilter = (invitee: Invitee) => boolean;
  const filters: InviteesFilter[] = [];
  filters.push(invitee => invitee.groupId === props.groupId);
  if (props.filterText) {
    filters.push(invitee =>
      invitee.email.toLowerCase().includes(props.filterText.toLowerCase())
    );
  }
  const filteredInvitees = filters.reduce<Invitee[]>((acc, curr) => {
    return acc.filter(curr);
  }, invitees.invitees);

  const slicedInvitees = filteredInvitees.slice(0, props.count);

  return (
    <React.Fragment>
      {slicedInvitees.length > 0 ? (
        <React.Fragment>
          <List
            subheader={
              <ListSubheader
                classes={{ root: props.classes.subheader }}
                component="div"
                style={{ position: 'static' }}
              >
                Invites
              </ListSubheader>
            }
          >
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={props.incrementCount}
              hasMore={true}
              getScrollParent={() => props.scrollRef.current || null}
              useWindow={false}
            >
              {slicedInvitees.map((invitee, i) => (
                <GroupInviteeListItem
                  key={invitee.id + '' + i}
                  item={invitee}
                />
              ))}
            </InfiniteScroll>
          </List>
        </React.Fragment>
      ) : (
        <EmptyState
          image={noGroups}
          text={
            <React.Fragment>
              There are no group invites.
              <br />
              Invite some members below.
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  );
};

const Centerer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withStyles(styles)(withGroupsState(GroupInviteesList));
