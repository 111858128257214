import { InjectedFormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { AuthProps } from '../../enhancers/withAuthState';
import { UsersProps } from '../../enhancers/withUsersState';
import { AuthTier } from '../../types/auth';

type FormValues = {
  email: string;
  isAdmin: boolean;
};

type FormProps = AuthProps & UsersProps;

export type UserInviteFormProps = InjectedFormikProps<FormProps, FormValues> &
  Partial<FormValues>;

const initialValues = {
  email: '',
  isAdmin: false
};

export default withFormik<FormProps, FormValues>({
  handleSubmit: (
    values,
    { props, setSubmitting, setError, resetForm, setStatus }
  ) => {
    const { email, isAdmin } = values;
    console.log({ values });
    props.inviteTier12User({
      invite: {
        email
      },
      tier: isAdmin ? AuthTier.TIER1 : AuthTier.TIER2
    });
    setStatus('submitting');
  },
  mapPropsToValues: () => ({ ...initialValues }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .required(),
    isAdmin: Yup.boolean().required()
  })
});
