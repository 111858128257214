import { InjectedFormikProps, withFormik } from 'formik';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';

import { GroupsProps } from '../../enhancers/withGroupsState';
import { UiProps } from '../../enhancers/withUiState';
import { IGroup } from '../../types/groups';

type FormValues = {
  name: string;
  description: string;
  publicJoin: boolean;
  public: boolean;
  tier3PostOnly: boolean;
};

export interface IFormProps {
  group: IGroup;
}

type FormProps = IFormProps & GroupsProps & RouteComponentProps & UiProps;

export type GroupSettingsFormProps = InjectedFormikProps<
  FormProps,
  FormValues
> &
  Partial<FormValues>;

export default withFormik<FormProps, FormValues>({
  isInitialValid: true,
  handleSubmit: (values, { props, setSubmitting, setError, resetForm }) => {
    setSubmitting(true);
    try {
      const {
        name,
        description,
        publicJoin,
        public: isPublic,
        tier3PostOnly
      } = values;
      props.confirmAction({
        title: 'Are you sure you want to update this group?',
        action: () =>
          props.updateGroupDetails({
            group: {
              id: props.group.id,
              name,
              description,
              publicJoin,
              public: isPublic,
              tier3PostOnly
            }
          })
      });
    } catch (e) {
      console.log({ e });
    }
  },
  mapPropsToValues: ({ group }) => ({
    name: group.name,
    description: group.description || '',
    publicJoin: !!group.publicJoin,
    public: group.public,
    tier3PostOnly: !!group.tier3PostOnly
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
    publicJoin: Yup.boolean().required(),
    public: Yup.boolean().required()
  })
});
