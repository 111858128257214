import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import withUsersState, { UsersProps } from '../../enhancers/withUsersState';
import { IUser } from '../../types/users';
import UserListItem from './UserListItem';

interface IProps {
  filterText?: string;
  scrollRef: React.RefObject<HTMLDivElement>;
  count: number;
  incrementCount: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    subheader: {
      fontSize: '1rem'
    }
  });

type Props = UsersProps & IProps & WithStyles<typeof styles>;

const UsersList: React.FC<Props> = props => {
  const users = props.users.users;

  if (!users.loaded || !users.users) {
    return (
      <Centerer>
        <CircularProgress />
      </Centerer>
    );
  }

  type UserFilter = (user: IUser) => boolean;
  const filters: UserFilter[] = [];

  filters.push(user => user.isTier1);

  if (props.filterText) {
    filters.push(
      user =>
        user.firstName
          .toLowerCase()
          .includes(props.filterText!.toLowerCase()) ||
        user.lastName.toLowerCase().includes(props.filterText!.toLowerCase())
    );
  }

  const filteredGroups = filters.reduce<IUser[]>(
    (acc, curr) => {
      return acc.filter(curr);
    },

    users.users
  );

  const slicedGroups = filteredGroups.slice(0, props.count);

  return (
    <React.Fragment>
      {slicedGroups.length > 0 && (
        <List
          subheader={
            <ListSubheader
              classes={{ root: props.classes.subheader }}
              component="div"
              style={{ position: 'static' }}
            >
              Users
            </ListSubheader>
          }
        >
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={props.incrementCount}
            hasMore={true || false}
            getScrollParent={() => props.scrollRef.current || null}
            useWindow={false}
          >
            {slicedGroups.map((user, i) => (
              <UserListItem key={user.uid + '' + i} item={user} />
            ))}
          </InfiniteScroll>
        </List>
      )}
    </React.Fragment>
  );
};

const Centerer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withStyles(styles)(withUsersState(UsersList));
