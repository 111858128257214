import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/app';
import * as React from 'react';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import withPostsState from '../../enhancers/withPostsState';
import withUiState from '../../enhancers/withUiState';
import MarkdownEditor from '../MarkdownEditor/MarkdownEditor';
import PostPreview from '../PostPreview/PostPreview';
import withEditPostForm, { EditPostFormProps } from './withEditPostForm';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing.unit * 2
    },
    button: {
      marginTop: theme.spacing.unit * 2
    },
    center: {
      display: 'flex',
      justifyContent: 'center'
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    cta: {
      textTransform: 'initial',
      width: '100%',
      fontWeight: 400,
      fontSize: 18,
      marginTop: 26
    },
    ctaPreview: {
      textTransform: 'initial',
      width: '100%',
      fontWeight: 400,
      fontSize: 18,
      marginTop: 16
    },
    mediaTitle: {
      marginTop: 14
    }
  });

type Props = WithStyles<typeof styles> & EditPostFormProps;

const EditPostForm: React.FC<Props> = props => {
  const [isImageUploading, setIsImageUploading] = React.useState<boolean>(
    false
  );
  const [imageProgress, setImageProgress] = React.useState<number>(0);
  const handleImageUploadSuccess = (filename: string) => {
    try {
      firebase
        .storage()
        .ref('posts')
        .child(filename)
        .getDownloadURL()
        .then(url => {
          props.setFieldValue('image', url);
          setImageProgress(100);
          setTimeout(() => {
            setIsImageUploading(false);
          }, 500);
        });
    } catch (e) {
      console.log({ e });
    }
  };
  const [isVideoUploading, setIsVideoUploading] = React.useState<boolean>(
    false
  );
  const [videoProgress, setVideoProgress] = React.useState<number>(0);
  const handleVideoUploadSuccess = (filename: string) => {
    try {
      firebase
        .storage()
        .ref('posts')
        .child(filename)
        .getDownloadURL()
        .then(url => {
          props.setFieldValue('video', url);
          setVideoProgress(100);
          setTimeout(() => {
            setIsVideoUploading(false);
          }, 500);
        });
    } catch (e) {
      console.log({ e });
    }
  };
  const [preview, setPreview] = React.useState<boolean>(false);

  const theForm = (
    <form onSubmit={props.handleSubmit}>
      <TextField
        label="Title"
        value={props.values.title}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        name="title"
        fullWidth
        variant="outlined"
        error={props.touched.title && !!props.errors.title}
        helperText={props.touched.title ? props.errors.title : ''}
        margin="normal"
      />
      <MarkdownEditor
        label="Content"
        value={props.values.content}
        onChange={props.handleChange('content')}
      />
      <Typography variant="h6" classes={{ root: props.classes.mediaTitle }}>
        Image:
      </Typography>
      {props.values.image && <PreviewImage src={props.values.image} />}
      <CustomUploadButton
        accept="image/*"
        name="avatar"
        randomizeFilename
        storageRef={firebase.storage().ref('posts')}
        onUploadSuccess={handleImageUploadSuccess}
        onUploadError={() => setIsImageUploading(false)}
        onUploadStart={() => {
          props.setFieldValue('image', '');
          setImageProgress(0);
          setIsImageUploading(true);
        }}
        onProgress={p => setImageProgress(p * 0.8)}
      >
        Upload An Image
      </CustomUploadButton>
      {isImageUploading && (
        <LinearProgress variant="determinate" value={imageProgress} />
      )}
      <Typography variant="h6" classes={{ root: props.classes.mediaTitle }}>
        Video:
      </Typography>
      {props.values.video && (
        <video width="300" controls>
          <source src={props.values.video} />
        </video>
      )}
      <CustomUploadButton
        accept="video/*"
        name="avatar"
        randomizeFilename
        storageRef={firebase.storage().ref('posts')}
        onUploadSuccess={handleVideoUploadSuccess}
        onUploadError={() => setIsVideoUploading(false)}
        onUploadStart={() => {
          props.setFieldValue('video', '');
          setVideoProgress(0);
          setIsVideoUploading(true);
        }}
        onProgress={p => setImageProgress(p * 0.8)}
      >
        Upload A Video
      </CustomUploadButton>
      {isVideoUploading && (
        <LinearProgress variant="determinate" value={videoProgress} />
      )}
      <Fab
        disabled={
          props.isSubmitting ||
          !props.isValid ||
          isVideoUploading ||
          isImageUploading
        }
        onClick={props.submitForm}
        variant="extended"
        color="primary"
        aria-label="Add"
        classes={{
          root: props.classes.cta
        }}
      >
        Update
      </Fab>
      <Fab
        disabled={
          props.isSubmitting ||
          !props.isValid ||
          isVideoUploading ||
          isImageUploading
        }
        onClick={() => setPreview(true)}
        variant="extended"
        color="default"
        aria-label="Preview"
        classes={{
          root: props.classes.ctaPreview
        }}
      >
        Preview
      </Fab>
      <PostPreview post={props.values} open={preview} setOpen={setPreview} />
    </form>
  );

  return theForm;
};

const PreviewImage = styled.img`
  height: 150px;
  display: block;
`;

export default withRouter(
  withUiState(
    withPostsState(withEditPostForm(withStyles(styles)(EditPostForm)))
  )
);
