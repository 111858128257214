import Fab from '@material-ui/core/Fab';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import styled from 'styled-components';

import Tick from '../../assets/img/Tick.png';
import VolunteerPreFooter from '../VolunteerPre/VolunteerPreFooter';
import VolunteerPreHeader from '../VolunteerPre/VolunteerPreHeader';

const styles = (theme: Theme) =>
  createStyles({
    cta: {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      textTransform: 'initial'
    }
  });

type Props = WithStyles<typeof styles>;

// import logo from '../../assets/img/Logo.png';
const VolunteerPreSuccess: React.FC<Props> = props => (
  <Container>
    <VolunteerPreHeader />

    <Bg>
      <FirstCircle>
        <Img src={Tick} alt="" />
      </FirstCircle>

      <h1>You're All Set!</h1>
      <Line />
      <p>
        Thanks for registering as a volunteer!
        We'll keep you you informed and updated,
        as soon as we release this product.
        You'll be the first in line to add content
        on behalf of your party.
      </p>

      <ButtonContainer>
        <Fab
          style={{ width: 150 }}
          variant="extended"
          color="primary"
          aria-label="Add"
          classes={{
            root: props.classes.cta
          }}
          href="https://lookatyou-qa.firebaseapp.com/look-at-my-party"
        >
          Let's Go!
        </Fab>
      </ButtonContainer>
    </Bg>
    <VolunteerPreFooter />
  </Container>
);

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Line = styled.div`
  width: 50px;
  background: white;
  height: 2px;
  margin: 24px auto;
`;

const Container = styled.div`
  display: 'flex';
  align-items: center;
  min-height: 100vh;
  padding: 10px;
  background-color: #0085eb;
  font-size: 15px;
  color: grey;

  h1 {
    font-weight: 400;
    padding-top: 10px;
    margin-top: -15px;
    color: #fff;
    font-size: 26px;
  }

  p {
    padding-top: 5px;
    padding-bottom: 20px;
    opacity: 0.8;
    color: #fff;
  }

  @media (max-width: 750px) {
    padding: 20px;
  }

  /* @media (max-width: 750px) {
    h1 {
      font-size: 3em;
    }
    p {
      font-size: 20px;
    }
  } */

  @media screen and (min-width: 1400px) {
    height: 1060px;
    max-width: 1900px;
  }
`;

const Bg = styled.div`
  margin: 50px auto;
  margin-bottom: 38px;
  margin-top: 0;

  /* height: 340px;
  width: 300px; */
  text-align: center;
  width: 100%;
  max-width: 400px;
  border-radius: 1%;
  padding: 0 40px 50px;
  /* margin-bottom: 150px; */

  @media (max-width: 750px) {
    margin-top: 0;
    padding: 0 20px 40px;
    /* margin: 12px auto; */
    /* padding: 0px 15px; */
    /* height: 570px; */
  }
  /* @media screen and (min-width: 1400px) {
    margin: 130px 0 150px 747px;
  } */
`;

const FirstCircle = styled.div`
  /* margin-top: 1px; */
`;

// const Circle = styled.div`
//   height: 130px;
//   width: 130px;
//   background-color: #24c644;
//   border-radius: 50%;
//   display: inline-block;
//   margin-bottom: 20px;
//   /* box-shadow: 0 0 10px #085391; */
// `
// const BlueCircle = styled.div`
// height: 150px;
//   width: 150px;
//   background-color: #085391;
//   border-radius: 50%;
//   display: inline-block;
//   box-shadow: 0 0 10px #085391;
//   margin-top: 50px;

//   @media (max-width: 750px) {
//     margin-top: 80px;
// }
// `
const Img = styled.img`
  /* margin-top: 30px; */
  /* width: 50%; */
  width: 250px;
  @media (min-width: 350px) {
    width: 300px;
  }
`;

// const Footers = styled(Footer)`
//  padding-top: 100px !important;
//  `

export default withStyles(styles)(VolunteerPreSuccess);
