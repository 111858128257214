import { combineReducers, Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { RootState } from '../types/store';
import * as auth from './modules/auth';
import { logout } from './modules/auth/actions';
import * as brand from './modules/brand';
import * as drawer from './modules/drawer';
import * as groupRequests from './modules/groupRequests';
import * as groups from './modules/groups';
import * as joinRequests from './modules/joinRequests';
import * as polls from './modules/polls';
import * as posts from './modules/posts';
import * as routing from './modules/routing';
import * as ui from './modules/ui';
import * as users from './modules/users';
import * as windowModule from './modules/window';

export const appReducer = combineReducers({
  auth: auth.reducer,
  brand: brand.reducer,
  drawer: drawer.reducer,
  groupRequests: groupRequests.reducer,
  groups: groups.reducer,
  posts: posts.reducer,
  polls: polls.reducer,
  users: users.reducer,
  window: windowModule.reducer,
  routing: routing.reducer,
  ui: ui.reducer,
  joinRequests: joinRequests.reducer
});

const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === getType(logout)) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
