import { InjectedFormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { AuthProps } from '../../enhancers/withAuthState';
import { BrandProps } from '../../enhancers/withBrandState';
import { Invitee } from '../../types/users';

type FormValues = {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  verifiedCaptcha: boolean;
  email: string;
  password: string;
};

interface IFormProps {
  invite: Invitee;
}

type FormProps = BrandProps & IFormProps & AuthProps;

export type AcceptInvitationFormProps = InjectedFormikProps<
  FormProps,
  FormValues
> &
  Partial<FormValues>;

const initialValues = {
  firstName: '',
  lastName: '',
  mobileNumber: '',
  email: '',
  password: '',
  verifiedCaptcha: false
};

export default withFormik<FormProps, FormValues>({
  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true);
    const { firstName, lastName, mobileNumber, email, password } = values;
    try {
      props.acceptInvitation({
        firstName,
        lastName,
        mobileNumber,
        inviteId: props.invite.id || '',
        email,
        password
      });
    } catch (e) {
      console.log({ e });
    }
  },
  mapPropsToValues: () => ({ ...initialValues }),
  validationSchema: Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    mobileNumber: Yup.string()
      .matches(/[0-9]/i, 'Must be a number')
      .required(),
    verifiedCaptcha: Yup.boolean()
      .required()
      .oneOf([true], 'Please complete captcha'),
    email: Yup.string()
      .email()
      .required(),
    password: Yup.string()
      .min(6)
      .required()
  })
});
