import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { IJoinRequest } from '../../../types/joinRequest';
import { actions } from './';

interface IJoinRequests {
  loaded: boolean;
  joinRequests: IJoinRequest[] | null;
  error: string;
}

export interface IJoinRequestsState {
  readonly joinRequests: IJoinRequests;
}

type JoinRequestsAction = ActionType<typeof actions>;

const initialState: IJoinRequestsState = {
  joinRequests: {
    loaded: false,
    error: '',
    joinRequests: null
  }
};

export default combineReducers<IJoinRequestsState, JoinRequestsAction>({
  joinRequests: combineReducers<IJoinRequests, JoinRequestsAction>({
    loaded: (state = initialState.joinRequests.loaded, action) => {
      if (action.type === getType(actions.setJoinRequests)) {
        return true;
      }
      return state;
    },
    error: (state = initialState.joinRequests.error, action) => {
      return state;
    },
    joinRequests: (state = initialState.joinRequests.joinRequests, action) => {
      switch (action.type) {
        case getType(actions.setJoinRequests): {
          return action.payload.joinRequests;
        }
        case getType(actions.addJoinRequest): {
          if (state) {
            return [...state, action.payload.joinRequest];
          }
          return [action.payload.joinRequest];
        }
        case getType(actions.removeJoinRequest): {
          if (state) {
            return state.filter(
              joinRequest => joinRequest.id !== action.payload.id
            );
          }
          return state;
        }
        case getType(actions.setJoinRequest): {
          if (state) {
            const index = state.findIndex(joinRequest => {
              return joinRequest.id === action.payload.joinRequest.id;
            });
            if (index > -1) {
              const newState = [...state];
              newState[index] = {
                ...action.payload.joinRequest
              };
              return newState;
            }
          }
          return state;
        }
        case getType(actions.clearJoinRequests): {
          return [];
        }
        default: {
          return state;
        }
      }
    }
  })
});
